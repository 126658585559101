// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTd5fVSlPDH3IyfY3T4N0w_y_s_eGcwYA",
  authDomain: "grp-app-2546b.firebaseapp.com",
  projectId: "grp-app-2546b",
  storageBucket: "grp-app-2546b.appspot.com",
  messagingSenderId: "874439336407",
  appId: "1:874439336407:web:fd291e649aa5c95eabeb92"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCTd5fVSlPDH3IyfY3T4N0w_y_s_eGcwYA",
//   authDomain: "grp-app-2546b.firebaseapp.com",
//   projectId: "grp-app-2546b",
//   storageBucket: "grp-app-2546b.appspot.com",
//   messagingSenderId: "874439336407",
//   appId: "1:874439336407:web:fd291e649aa5c95eabeb92"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);