import { create } from 'zustand';

const useTrainerStore = create((set) => ({
  trainer: {},
  currentUser: {},
  userIDFire: '',

  setUserIDFire: (text) => set({ userIDFire: text }), // Corrected line
  setCurrentUser: (data) => set({ currentUser: data }),
  setTrainer: (data) => set({ trainer: data }),

  // Add other state or actions as needed
}));

export default useTrainerStore;
