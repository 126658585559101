import {create} from 'zustand';

const useAthleteStore = create((set) => ({

  athlete: {},

  setAthlete: (data) => set({ athlete: data }),
  

  // Add other state or actions as needed
}));

export default useAthleteStore;