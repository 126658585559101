import { StatusBar } from 'expo-status-bar';
import { Button, Pressable, SafeAreaView, StyleSheet, Text, View } from 'react-native';
// import LoginPage from './Login';
import { useState, useEffect } from 'react';
import AccountErrorModal from '../components/AccountErrorModal';

const HomePage = ({ navigation }) => {

const [data, setData] = useState(true);

const [visible, setVisible] = useState(false)
const [errorMessage, setErrorMessage] = useState('')


// const handleSearch = async () => {
//   const options = {
//     headers: {
//       // 'api-key': process.env.API_KEY,
//       'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
//     },
//   };

//   const baseUrl = 'https://gamereadyperformanceapp.com/api/sites?site_id=1';

//   try {
//       const response = await fetch(baseUrl, options);

//       if (response.ok) {
//         const data = await response.json();
//         // console.log(data);
//         setData(data.sites[0]);

//       } else {
//         setErrorMessage('No data found in response');
//         setVisible(true);
//         // console.log('No data found in response');
//       }
//   } catch (error) {
//     setErrorMessage('Error trying to find trainer profile');
//     setVisible(true);
//   }
// };
// useEffect(() => {

//   handleSearch();

//   return () => {
//     console.log(data);
//     // You can perform cleanup tasks here if needed
//   };
// }, []);
  return (
    <SafeAreaView style={styles.container}>
       <View style={styles.content}>
        {/* <Text>Insert Image Here and stuff</Text> */}
        <Pressable
          style={styles.buttonContainer}
          onPress={() => navigation.navigate('Login Test')}
        >
          <Text style={styles.buttonText}>Log In</Text>
        </Pressable>
        </View>
        <View style = {styles.footer}>
            <Text style={styles.label}>{data.address}</Text>
            <Text style={styles.label}>{data.phone}</Text>
        </View>
        <AccountErrorModal 
        text={errorMessage} 
        visible={visible} 
        onClose={() => setVisible(false)}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,

    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 8,
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "blue", 
    width: "100%"
  },
  footer: {
    flex: 1,
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent:"space-between",
    width: "100%",
    paddingHorizontal: 10
  },
  buttonContainer: {
    backgroundColor: '#729982',
    paddingVertical: 15,
    paddingHorizontal: 30,
    marginHorizontal: 10,
    borderRadius: 10,
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
  },
  label: {
    height:20,
    padding: 3,
    marginLeft: 10,
    fontSize: 16
  },
  inputText: {
      padding: 3,
  }
});

export default HomePage;