import React, { useState } from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView } from 'react-native';
import { DataTable, TextInput } from 'react-native-paper';
import EnergySystemDropdown from '../components/EnergySystemDropdown';
import PRETextInput from '../components/PRETextInput';
import SpeedTextInput from '../components/SpeedTextInput';
import { useData } from '../DataContext';

const EnergySystemDevelopmentForm = ({ navigation }) => {
  const [data, setData] = useState([]);
  const [initialSpeed, setInitialSpeed] = useState('');
  const [initialPRE, setInitialPRE] = useState('');
  const [rateOfRecovery, setRateofRecovery] = useState('');
  const [energySystem, setEnergySystem] = useState('')

  // Testing time frame to use in the table
  const timeFrames = [
    '0:00', '0:05', '0:10', '0:15', '0:20', '0:25', '0:30', '0:35', '0:40', '0:45',
    '0:50', '0:55', '1:00', '1:05', '1:10', '1:15', '1:20', '1:25', '1:30', '1:35',
    '1:40', '1:45', '1:50', '1:55', '2:00', '2:05', '2:10', '2:15', '2:20', '2:25',
    '2:30', '2:35', '2:40', '2:45', '2:50', '2:55', '3:00', '3:05', '3:10', '3:15', 
    '3:20', '3:25', '3:30', '3:35', '3:40', '3:45', '3:50', '3:55', '4:00'
  ];

  const hrates = Array.from({ length: 42 }, (_, index) => 95 + index);

  const speedData = Array.from({length: 42}, (_,index) => 0.2 + (index/13));

  const rowsPerClick = 1;

  // Const to generate the rows in the table
  const addRows = () => {
    const startIndex = data.length;
    const endIndex = Math.min(startIndex + rowsPerClick, timeFrames.length);
    const newRows = timeFrames.slice(startIndex, endIndex).map((time, index) => ({
      key: startIndex + index,
      time,
      heartRate: hrates[index],
      speed: speedData[startIndex + index],
      PRE: '', // Initialize PRE to an empty string
    }));

    setData([...data, ...newRows]);
  };

  const {dataAthleteProfile} = useData();

  const initialResults = {
    InitialSpeed: initialSpeed,
    PRE: initialPRE,
    RateofRecovery: rateOfRecovery,
    EnergySystem: energySystem
  }


  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.containerView}>
          <Text style={styles.title}>Athlete Name: {dataAthleteProfile.name} {dataAthleteProfile.middleini} {dataAthleteProfile.lastname}</Text>
          <Text style={styles.title}>Energy System Development Form</Text>

          <DataTable style={styles.table}>
            <DataTable.Header>
              <DataTable.Title style={{justifyContent:'center'}}>Previous Session Report</DataTable.Title>
            </DataTable.Header>
            <DataTable.Row>
              <DataTable.Cell  style={{justifyContent:'space-evenly'}}>Max Speed</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>0</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell  style={{justifyContent:'space-evenly'}}>Max Heart Rate</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>0</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell  style={{justifyContent:'space-evenly'}}>PRE</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>0</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>Rate of Recovery</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>0</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell  style={{justifyContent:'space-evenly'}}>Energy System</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}></DataTable.Cell>
            </DataTable.Row>
          </DataTable>

          {/*Parameters from previous sessions*/}
          {/* <View style={styles.rowContainerHeader}>
            <Text style={styles.textStyleFirstRow}>Previous Max Speed = </Text>
            <Text style={{fontSize:30}}>0</Text>
            <Text style={styles.textStyleFirstRow}>Previous Max Heart Rate = </Text>
            <Text style={{fontSize:30}}>0</Text>
            <Text style={styles.textStyleFirstRow}>Previous PRE = </Text>
            <Text style={{fontSize:30}}>0</Text>
          </View>
          <View style={styles.rowContainerHeader}>
            <Text style={styles.textStyleFirstRow}>Previous Rate of Recovery = </Text>
            <Text style={{fontSize:30}}>0</Text>
            <Text style={styles.textStyleFirstRow}>Previous Energy System</Text>
            <Text style={{fontSize:15}}>Aerobic Speed - Base</Text>
          </View> */}

          {/* Parameters for current session */}
          <View style={styles.rowContainerHeader}>
          <Text style={styles.textStyleFirstRow}>Initial Speed</Text>
            <TextInput
              label="Enter Initial Speed"
              style={styles.textInputStyle}
              value={initialSpeed}
              onChangeText={(text) => {
                // Use parseInt to convert the text to an integer
                const intValue = parseInt(text);

                // Check if the conversion was successful (not NaN)
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                  // Set the state with the integer value
                  setInitialSpeed(text === "" ? "" : intValue);

                } else {
                  // Handle the case where the input is not a valid integer
                  // You may show an error message or take other appropriate action
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            />
            <Text style={styles.textStyleFirstRow}>Enter PRE</Text>
            <TextInput
              label="Enter PRE"
              style={styles.textInputStyle}
              value={initialPRE}
              onChangeText={(text) => {
                // Use parseInt to convert the text to an integer
                const intValue = parseInt(text);

                // Check if the conversion was successful (not NaN)
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                  // Set the state with the integer value
                  setInitialPRE(text === "" ? "" : intValue);

                } else {
                  // Handle the case where the input is not a valid integer
                  // You may show an error message or take other appropriate action
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            />
            
          </View>
          <View style={styles.rowContainerHeader}>
            <Text style={styles.textStyleFirstRow}>Enter Rate of Recovery</Text>
            <TextInput
              label="Enter Rate of Recovery"
              style={styles.textInputStyle}
              value={rateOfRecovery}
              onChangeText={(text) => {
                // Use parseInt to convert the text to an integer
                const intValue = parseInt(text);

                // Check if the conversion was successful (not NaN)
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                  // Set the state with the integer value
                  setRateofRecovery(text === "" ? "" : intValue);

                } else {
                  // Handle the case where the input is not a valid integer
                  // You may show an error message or take other appropriate action
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            />
            <Text style={styles.textStyleFirstRow}>Select Energy System</Text>
            <EnergySystemDropdown 
              value={energySystem}
              onChange={(value) => setEnergySystem(value)}
            />
          </View>

          {/* Table to be generated during/after session */}
          {/* <View alignItems="center"> */}
            {/* <table style={styles.table}>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Heart Rate</th>
                  <th>Speed</th>
                  <th>PRE</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.key}>
                    <td>{item.time}</td>
                    <td>
                      {item.heartRate}
                    </td>
                    <td>
                      {item.speed}
                    </td>
                    <td>
                      <TextInput
                        value={item.PRE}
                        onChangeText={(text) => {
                          const newData = [...data];
                          const rowIndex = data.findIndex((row) => row.key === item.key);
                          newData[rowIndex].PRE = text;
                          setData(newData);
                        }}
                        style={styles.input}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
            <DataTable style={styles.table}>
              <DataTable.Header>
                <DataTable.Title>Time</DataTable.Title>
                <DataTable.Title>Heart Rate</DataTable.Title>
                <DataTable.Title>Speed</DataTable.Title>
                <DataTable.Title>PRE</DataTable.Title>
              </DataTable.Header>
              {data.map((item) => (
                <DataTable.Row key={item.key}>
                  <DataTable.Cell>{item.time}</DataTable.Cell>
                  <DataTable.Cell>
                    {item.heartRate}
                  </DataTable.Cell>
                  <DataTable.Cell>
                      {item.speed}
                  </DataTable.Cell>
                  <DataTable.Cell>
                  <TextInput
                    label="Enter PRE"
                    style={styles.textInputStyle}
                    value={item.PRE}
                    onChangeText={(text) => {
                      const updatedData = data.map((rowData) =>
                        rowData.key === item.key ? { ...rowData, PRE: text } : rowData
                      );
                      const intValue = parseInt(text)
                      if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                        setData(updatedData);
                      } else {
                        // Handle the case where the input is not a valid integer
                        // You may show an error message or take other appropriate action
                        console.log("Invalid input. Please enter a valid number within the range.");
                      }


                    }}
                  />
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
            </DataTable>
          {/* </View> */}
          
          {/* Buttons to move to another pages */}
          <View style={styles.rowContainerDownButtons}>
            {/* <Button
              title="Previous Page"
              color="#989170"
              onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Single Leg Eyes Closed')}
              style={styles.button}
            /> */}
            <Button
              title="Finish Page"
              color="#989170"
              onPress={() => {
                console.log(data)
                console.log(initialResults)
                navigation.navigate('Athlete Profile')}}
              style={styles.button}
            />
            <Button
              title="Generate Rows"
              color="#729982"
              onPress={addRows}
              style={styles.button}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerView: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius: 10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 30,
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // marginVertical: 20,
    // marginHorizontal: 70,
    alignItems: 'center',
    width:'100%'
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'center', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
  },
  table: {
    borderWidth: 1,
    borderColor: 'black',
    width: '100%',
  },
  textStyleFirstRow: {
    flex: 1,
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize: 20,
  },
  textInputStyle: {
    backgroundColor: "#e8e2d0",
    color: "black",
    borderBlockEndColor: 'black',
  },
});

export default EnergySystemDevelopmentForm;
