import React, { useState, useEffect } from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Alert, Platform, TouchableOpacity, Modal } from 'react-native';
import { DataTable, TextInput, Divider } from 'react-native-paper';
import SprintCapacityRun from './SprintCapacityRun';
import NervousSystemReportUpperBody from './NSReportUpperBody';
import { useData } from '../DataContext';
import OutOfRangeModal from '../components/OutofRangeModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import useTrainerStore from '../varHelpers/trainerStore';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';

const NervousSystemVERTJump = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();

  const [showOutOfRange, setShowOutOfRange] = useState(false);
  const [requestError, setRequestError] = useState(false);

  // Form variables and setters with initial values 
  const [leg_r, setleg_r] = useState('');
  const [leg_l, setleg_l] = useState('');
  const [both_legs, setboth_legs] = useState('');

  const { currentUser } = useTrainerStore();

  // Array object where the results will be saved (testing)
  const resultsVERTJump = {
    athlete_id: athlete.id,
    trainer_id: currentUser[0].id,
    site_id: 0,
    timestamp: "2023-11-29T22:29:55.807Z",
    leg_r: leg_r,
    leg_l: leg_l,
    both_legs: both_legs
  }

  // Used to save the array object and pass it to another screen
  const { dataAthleteProfile, dataVERTJump, setDataVERTJump } = useData();
  const [data, setData] = useState('');


  /**
   * The `submitData` function is an asynchronous function that makes a POST request to an API endpoint
   * with the provided data and handles the response and errors.
   * @param allResults - The `allResults` parameter is an object that contains the data to be submitted
   * to the API. It should be in the format required by the API endpoint you are using.
   */
  const submitData = async (allResults) => {
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/vertjump';

      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        // Add any other headers if needed
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Make the POST request using Axios
      const response = await axios.post(apiUrl, allResults, { headers });

      // Handle the response as needed
      console.log("Response code:", response.status);
      console.log('Response:', response.data);
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);

      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status !== 404) {
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /**
   * The function `getDataFromDb` makes a GET request to an API endpoint, retrieves data, and handles
   * the response accordingly.
   * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
   * you want to retrieve data from the database. This ID is used in the API request to fetch the
   * athlete's evaluation data.
   */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/vertjump';
      const parameters = `?athlete_id=${athlete_id}`;

      // const firstPageUrl = apiUrl + parameters;

      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page

      // Make the GET request using Axios
      const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}`, { headers });

      // Log the raw response for debugging
      // console.log("Raw Response:", response);

      // Determine content type manually
      const contentType = response.headers['content-type'];
      // console.log("Content-Type:", contentType);

      // Parse data based on content type
      let responseData;
      if (contentType && contentType.includes('application/json')) {
        responseData = response.data;
        // console.log("Response data in JSON:", responseData);
      } else {
        // Handle other content types if needed
        // For now, assume it's JSON
        responseData = JSON.parse(response.data);
        // console.log("Response data in JSON:", responseData);
      }


      // Access hasNextPage from the response
      hasNextPage = response.data.hasNextPage;


      // Handle the response as needed
      console.log(`Response for page ${currentPage}:`, response.data);

      let vertJumpEvals = response.data.vertJumpEvals;
      lastRecord = vertJumpEvals[vertJumpEvals.length - 1].id;
      console.log(`${lastRecord}: ${typeof lastRecord}`);

      // Increment the page for the next request
      currentPage++;

      // Introduce some delay if needed to avoid overwhelming the API
      // await new Promise(resolve => setTimeout(resolve, 1000));


      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });


      console.log(firstPageResponse.headers);

      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });

      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.post_r)

      // Assuming you have a structure in lastPageData for the "Previous Session" values
      vertJumpEvals = lastPageResponse.data?.vertJumpEvals;
      console.log("vertJumpEvals:", vertJumpEvals)

      if (vertJumpEvals && vertJumpEvals.length > 0) {
        const previousSessionValues = {
          leg_r: vertJumpEvals[0].leg_r,
          leg_l: vertJumpEvals[0].leg_l,
          both_legs: vertJumpEvals[0].both_legs,
        };
        setDataVERTJump(previousSessionValues);
        console.log("VERT Jump:", data);
        console.log("Previous session:", previousSessionValues);
      }

    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);

      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status !== 404) {
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /* The above code is a useEffect hook in JavaScript. It is used to fetch data from a database
  (presumably using the `getDataFromDb` function) when a component mounts. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `saveData` saves the `resultsVERTJump` data to AsyncStorage in JavaScript.
   */
  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('vert-jump', JSON.stringify(resultsVERTJump));
      setData(resultsVERTJump);
      // submitData(resultsVERTJump);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.containerView}>
          {/* Athlete's Name and Test Title */}
          <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
          <Button style={styles.button}
            onPress={() => navigation.navigate('Table Total Body')}
            title="Go to : Total Body Assessment Parts"
            color="#989170">Total Body Assessment Parts</Button>
          {/* Form table */}
          <DataTable style={styles.table}>
            <DataTable.Row>
              <DataTable.Cell>VERT Jump</DataTable.Cell>
              <DataTable.Cell>Right Side - 100 inches</DataTable.Cell>
              <DataTable.Cell>Left Side - 100 Inches</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell>Single Leg</DataTable.Cell>
              <DataTable.Cell>
                <TextInput
                  placeholder="Number of Inches"
                  style={styles.textInputStyle}
                  value={leg_r}
                  onChangeText={(text) => {
                    const intValue = parseInt(text);
                    if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 100)) {
                      setleg_r(text === "" ? "" : intValue);

                    } else {
                      if (Platform.OS === 'web') {
                        setShowOutOfRange(true)
                      } else {
                        Alert.alert("Missing values")
                      }
                      console.log("Invalid input. Please enter a valid number within the range.");
                    }
                  }}
                />
              </DataTable.Cell>
              <DataTable.Cell>
                <TextInput
                  placeholder="Number of Inches"
                  style={styles.textInputStyle}
                  value={leg_l}
                  onChangeText={(text) => {
                    const intValue = parseInt(text);
                    if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 100)) {
                      setleg_l(text === "" ? "" : intValue);

                    } else {
                      if (Platform.OS === 'web') {
                        setShowOutOfRange(true)
                      } else {
                        Alert.alert("Missing values")
                      }
                      console.log("Invalid input. Please enter a valid number within the range.");
                    }
                  }}
                />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell>Double Leg</DataTable.Cell>

              <DataTable.Cell>
                <TextInput
                  placeholder="Number of Inches"
                  style={styles.textInputStyle}
                  value={both_legs}
                  onChangeText={(text) => {
                    const intValue = parseInt(text);
                    if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 100)) {
                      setboth_legs(text === "" ? "" : intValue);

                    } else {
                      if (Platform.OS === 'web') {
                        setShowOutOfRange(true)
                      } else {
                        Alert.alert("Missing values")
                      }
                      console.log("Invalid input. Please enter a valid number within the range.");
                    }
                  }}
                />
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>

          <DataTable style={styles.table}>
            <DataTable.Row>
              <DataTable.Cell>VERT Jump</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:"center"}}>Right Side - 100 inches</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:"center"}}>Left Side - 100 Inches</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell>Single Leg</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:"center"}}>
                {dataVERTJump.leg_r}
              </DataTable.Cell>
              <DataTable.Cell style={{justifyContent:"center"}}>
                {dataVERTJump.leg_l}
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell>Double Leg</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:"center"}}>
                {dataVERTJump.both_legs}
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>

          <View style={styles.rowContainerDownButtons}>
            {/* Buttons to navigate among screens */}
            <Button
              title="Previous Page"
              color="#989170"
              onPress={() => navigation.navigate('Sprint Capacity - Run', SprintCapacityRun)}
              style={styles.button}
            />
            <Button
              title="Finish - Generate Report"
              color="#729982"
              onPress={() => {

                for (let key in resultsVERTJump) {
                  if (resultsVERTJump.hasOwnProperty(key)) {
                    console.log(`${key}: ${typeof resultsVERTJump[key]}`);
                  }
                }
                const flagForNullValues = Object.values(resultsVERTJump).every(value => value !== '');
                console.log(flagForNullValues)

                if (!flagForNullValues) {
                  console.log("There are missing values")
                }
                console.log(resultsVERTJump)
                setDataVERTJump(resultsVERTJump);
                saveData();
                submitData(resultsVERTJump);
                navigation.navigate('Report - Nervous Assessment System: Upper Body', NervousSystemReportUpperBody)

              }}
              style={styles.button}
            />
            <Button
              title="Look old report"
              color="#989170"
              onPress={() => navigation.navigate('Report - Nervous Assessment System: Upper Body', NervousSystemReportUpperBody)}
              style={styles.button}
            />
            {/* Custom modals for web */}
            {Platform.OS === 'web' && (
              <>
                <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
              </>
            )}
          </View>
        </View>

      </ScrollView>
    </SafeAreaView>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width: '100%',
  },
  containerView: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width: '100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius: 10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems: 'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems: 'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems: 'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize: 'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize: 'large',
  },
  textInputStyle: {
    backgroundColor: "#e8e2d0",
    color: "black",
    borderBlockEndColor: 'black',
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
    flex: 20,
    marginBottom: 20,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#e8e2d0',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: 300, // Adjust the width as needed
  },
  modalText: {
    fontSize: 18,
    marginBottom: 10,
    fontFamily: 'Roboto',
    justifyContent: 'center'
  },
  modalButton: {
    fontSize: 16,
    color: 'black',
    marginTop: 10,

  },
});

export default NervousSystemVERTJump;
