import React, { useState } from 'react';
import { View, Pressable, Text, SafeAreaView, StyleSheet } from 'react-native';
import useTrainerStore from '../varHelpers/trainerStore';

const LandingPage = ({ navigation }) => {
  const { currentUser } = useTrainerStore(); // Destructure currentUser from the store

  const [isVisible, setisVisible] = useState(currentUser[0].role !== 'trainer');

  const handlePress = (screen) => {
    navigation.navigate(screen);
  };

  return (
    <SafeAreaView style={styles.container}>
      {/* <Pressable
        style={styles.buttonContainer}
        onPress={() => console.log(currentUser[0].user_id)}
      >
        <Text style={styles.buttonText}>Console Log</Text>
      </Pressable> */}
      <Pressable
        style={styles.buttonContainer}
        onPress={() => handlePress('Athlete Search')}
      >
        <Text style={styles.buttonText}>Athlete Profiles</Text>
      </Pressable>
      {isVisible && (
        <>
          <Pressable
            style={styles.buttonContainer}
            onPress={() => handlePress('Trainer Search')}
          >
            <Text style={styles.buttonText}>Trainer Profiles</Text>
          </Pressable>
          <Pressable
            style={styles.buttonContainer}
            onPress={() => handlePress('Site Info')}
          >
            <Text style={styles.buttonText}>Site Info</Text>
          </Pressable>
        </>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
  buttonContainer: {
    backgroundColor: '#729982',
    paddingVertical: 15,
    paddingHorizontal: 30,
    marginHorizontal: 10,
    borderRadius: 10,
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
  },
});

export default LandingPage;
