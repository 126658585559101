// formatUtils.js

export function formatDOB(input) {
  const numericInput = input.replace(/\D/g, '');

  if (numericInput.length >= 8) {
    return (
      numericInput.slice(0, 4) + '-' +
      numericInput.slice(4, 6) + '-' +
      numericInput.slice(6, 8)
    );
  // } else if (numericInput.length >= 6) {
  //   return (
  //     numericInput.slice(0, 4) + '-' +
  //     numericInput.slice(4, 6) + '-' +
  //     numericInput.slice(6)
  //   );
  // } else if (numericInput.length >= 4) {
  //   return (
  //     numericInput.slice(0, 4) + '-' +
  //     numericInput.slice(4)
  //   );
  } else {
    return numericInput;
  }
}

export function formatISODate(input) {
  const numericInput = input.replace(/\D/g, '');

  if (numericInput.length <= 13) {
    return (
      numericInput.slice(0, 4) + '-' +
      numericInput.slice(4, 6) + '-' +
      numericInput.slice(6, 8) + 'T' +
      numericInput.slice(8, 10) + ':' +
      numericInput.slice(10, 12)
    );
  } else {
    return numericInput;
  }
}
