import React, {useEffect, useState} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform, Alert} from 'react-native';
import OutOfRangeModal from '../components/OutofRangeModal';
import { DataTable, TextInput} from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'
import useAthleteStore from '../varHelpers/athleteStore';
import useTrainerStore from '../varHelpers/trainerStore';
import RequestErrorModal from '../components/RequestErrorModal';
import { AuthManager } from '../AuthManager';

import { useData } from '../DataContext';

const NervousSystemUpperBody = ({ navigation }) => {

  const [showOutOfRange, setShowOutOfRange] = useState(false);
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const {athlete} = useAthleteStore();
  const {currentUser} = useTrainerStore();

  // Form variables and setters with initial values 
  const [post_r, setpost_r] = useState('');
  const [post_l, setpost_l] = useState('');
  const [ant_r, setant_r] = useState('');
  const [ant_l, setant_l] = useState('');
  const [int_rot_r, setint_rot_r] = useState('');
  const [int_rot_l, setint_rot_l] = useState('');
  const [ext_rot_r, setext_rot_r] = useState('');
  const [ext_rot_l, setext_rot_l] = useState('');
  
  // Array object where the results will be saved (testing)
  const resultsUpperBody = {
    athlete_id: athlete.id,
    trainer_id: currentUser[0].id,
    site_id:0,
    post_r: post_r,
    post_l: post_l,
    ant_r: ant_r,
    ant_l: ant_l,
    int_rot_r: int_rot_r,
    int_rot_l: int_rot_l,
    ext_rot_r: ext_rot_r,
    ext_rot_l: ext_rot_l
  }

  /**
   * The `submitData` function is an asynchronous function that makes a POST request to an API endpoint
   * with the provided `resultsUpperBody` data and handles the response and errors accordingly.
   * @param resultsUpperBody - The `resultsUpperBody` parameter is the data that you want to submit to
   * the API. It should be an object containing the necessary information for the upper body
   * evaluation.
   */
  const submitData = async (resultsUpperBody) => {
    try {
      
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/upperrom';
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        // Add any other headers if needed
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
  
      // Make the POST request using Axios
      const response = await axios.post(apiUrl, resultsUpperBody, { headers });
  
      // Handle the response as needed
      console.log("Response code:", response.status);
      console.log('Response:', response.data);
      setDataUpperBodyForm(response.data)
      getDataFromDb(athlete.id);
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        setRequestError(true)
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        setRequestError(true)
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /**
   * The function `getDataFromDb` makes a series of GET requests to retrieve data from a database,
   * handles the responses, and logs the results.
   * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
   * you want to retrieve data from the database. This ID is used in the API request to fetch the
   * athlete's evaluation data.
   */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/upperrom';
      const parameters = `?athlete_id=${athlete_id}`;

      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',      
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}`,{headers} );
        console.log("Headers:", headers)

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }

        // Access hasNextPage from the response
        hasNextPage = response.hasNextPage;

        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const upperROMEvals = response.data.upperROMEvals;
        lastRecord = upperROMEvals[upperROMEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);

  
      // Extract the total count and last page from the response headers
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const upperROMEvals = lastPageResponse.data?.upperROMEvals;
      console.log("upperROMEvals:", upperROMEvals)

      if (upperROMEvals && upperROMEvals.length > 0) {
        const previousSessionValues = {
          post_r: upperROMEvals[0].post_r,
          post_l: upperROMEvals[0].post_l,
          ant_r: upperROMEvals[0].ant_r,
          ant_l: upperROMEvals[0].ant_l,
          int_rot_r: upperROMEvals[0].int_rot_r,
          int_rot_l: upperROMEvals[0].int_rot_l,
          ext_rot_r: upperROMEvals[0].ext_rot_r,
          ext_rot_l: upperROMEvals[0].ext_rot_l,
        };
        setDataUpperBodyForm(previousSessionValues);
        console.log(previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

   // Used to save the array object and pass it to another screen (testing)
  const { dataUpperBodyForm, setDataUpperBodyForm } = useData(); 
  const [data, setData] = useState('');

  /* The above code is using the useEffect hook in React to fetch data from a database when the
  component mounts. It retrieves data from AsyncStorage and calls the getDataFromDb function with
  the athlete_id as a parameter. The fetchData function is an asynchronous function that handles the
  data fetching and error handling. The useEffect hook is triggered only once when the component
  mounts, as indicated by the empty dependency array. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;
        console.log("Current user:", currentUser);
        console.log("current user ID:", currentUser[0].id)
        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `saveData` saves the `resultsUpperBody` data to AsyncStorage in JSON format.
   */
  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('upper-body', JSON.stringify(resultsUpperBody));
      setData(resultsUpperBody);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };


  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            {/* Header of the page */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Form - Mobility Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>

                {/* Form table - parameters with inches */}
                <View style={{marginBottom: 50}}>
                  <DataTable style={styles.table}>
                    <DataTable.Header>
                      <DataTable.Title style={{justifyContent:'center', flex:2}} >Inches of Rotation Accomplished per side</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Header>
                      <DataTable.Title style={{flex: 1.8}}>Range of Motion - Upper Body</DataTable.Title>
                      <DataTable.Title style={{flex: 1.5}}>Right Side - 20 inches</DataTable.Title>
                      <DataTable.Title style={{flex: 1.5}}>Left Side - 20 inches</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>Post Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                          placeholder='Number of Inches'
                          style={styles.textInputStyle} 
                          value={post_r} 
                          onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 20)) {
                                  setpost_r(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                setShowOutOfRange(true)
                              }else{
                                Alert.alert("Missing values")
                              }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                        />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                          placeholder="Number of Inches"
                          style={styles.textInputStyle} 
                          value={post_l} 
                          onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 20)) {
                                  setpost_l(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                setShowOutOfRange(true)
                              }else{
                                Alert.alert("Missing values")
                              }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                        />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>Ant Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Inches"
                            style={styles.textInputStyle} 
                            value={ant_r} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 20)) {
                                  setant_r(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                setShowOutOfRange(true)
                              }else{
                                Alert.alert("Missing values")
                              }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Inches"
                            style={styles.textInputStyle} 
                            value={ant_l} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 20)) {
                                  setant_l(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                setShowOutOfRange(true)
                              }else{
                                Alert.alert("Missing values")
                              }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>
                
                {/* Form table with degrees parameters */}
                <View style={{marginBottom: 50}}>
                  <DataTable style={styles.table}>
                    <DataTable.Header>
                      <DataTable.Title style={{justifyContent:'center', flex:2}} >Degrees of Rotation Accomplished per side</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Header>
                      <DataTable.Title style={{flex: 1.8}}>Range of Motion - Upper Body</DataTable.Title>
                      <DataTable.Title style={{flex: 1.5}}>Right Side - 90 degrees</DataTable.Title>
                      <DataTable.Title style={{flex: 1.5}}>Left Side - 90 degrees</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>Internal Rotation Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={int_rot_r} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                  setint_rot_r(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                setShowOutOfRange(true)
                              }else{
                                Alert.alert("Missing values")
                              }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={int_rot_l} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                  setint_rot_l(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                setShowOutOfRange(true)
                              }else{
                                Alert.alert("Missing values")
                              }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>External Rotation Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={ext_rot_r} 
                              onChangeText={(text) => {
                                const intValue = parseInt(text);
                                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                      setext_rot_r(text === "" ? "" : intValue);
                                  
                                } else {
                                  if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                                  console.log("Invalid input. Please enter a valid number within the range.");
                                }
                              }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={ext_rot_l} 
                              onChangeText={(text) => {
                                const intValue = parseInt(text);
                                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                      setext_rot_l(text === "" ? "" : intValue);
                                  
                                } else {
                                  if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                                  console.log("Invalid input. Please enter a valid number within the range.");
                                }
                              }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>

                {/* {
                  Previous Session values
                } */}

                <View style={{marginBottom: 50, width: '100%'}}>
                  <DataTable style={styles.table}>
                      <DataTable.Header>
                        <DataTable.Title style={{justifyContent:'center', flex:2}} >Previous Session</DataTable.Title>
                      </DataTable.Header>
                      <DataTable.Header>
                        <DataTable.Title style={{justifyContent:'center', flex:2}} >Inches of Rotation Accomplished per side</DataTable.Title>
                      </DataTable.Header>
                      <DataTable.Row style={{justifyContent:'center'}}>
                        <DataTable.Cell style={{flex: 1.8, marginRight:20}}>
                          <Text>
                            Range of Motion{'\n'}Upper Body
                          </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Right Side{'\n'}20 inches
                            </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Left Side{'\n'}20 inches
                            </Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell style={{flex: 1.8}}>Post Shoulder</DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.post_r}
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.post_l}
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell style={{flex: 1.8}}>Ant Shoulder</DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.ant_r}
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.ant_l}
                        </DataTable.Cell>
                      </DataTable.Row>
                    </DataTable>
                  </View>

                  <View style={{marginBottom: 50, width:'100%'}}>
                  <DataTable style={styles.table}>
                    <DataTable.Header>
                      <DataTable.Title style={{justifyContent:'center', flex:2}} >Degrees of Rotation Accomplished per side</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                        <DataTable.Cell style={{flex: 2, marginRight:20}}>
                          <Text>
                            Range of Motion{'\n'}Upper Body
                          </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.5, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Right Side{'\n'}90 degrees
                            </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.5, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Left Side{'\n'}90 degrees
                            </Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>Internal Rotation Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.int_rot_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.int_rot_l}
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>External Rotation Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.ext_rot_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.ext_rot_l}
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>

                <View style={styles.rowContainer}>
                    <Button
                        title="Continue and save"
                        color="#729982"
                        onPress={() => {
                          for (let key in resultsUpperBody) {
                            if (resultsUpperBody.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsUpperBody[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsUpperBody).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(!flagForNullValues){
                            console.log("There are missing values")
                          }

                          setDataUpperBodyForm(resultsUpperBody);
                          saveData();
                          submitData(resultsUpperBody);
                          getDataFromDb(athlete.id);
                          // getDataFromDb(resultsUpperBody.eval_id, resultsUpperBody.athlete_id, resultsUpperBody.trainer_id, resultsUpperBody.site_id);
                          // console.log(resultsUpperBody);
                          navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Ankle', {resultsUpperBody})
                          
                        }}
                        style={styles.button}
                    />
                    <Button
                        title="Continue without saving"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Ankle')}
                        style={styles.button}
                    />
                  </View>
                    {/* Custom modals for web */}
                      {Platform.OS === 'web' && (
                        <>
                          <OutOfRangeModal visible={showOutOfRange} onClose={() => setShowOutOfRange(false)} />
                          {/* <IncompleteModal visible={showIncomplete} onClose={() => setShowIncomplete(false)} /> */}
                          <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                        </>
                      )}
              </View>

                
          {/* </View> */}
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
    flex:2,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  tableText: {
    fontFamily: 'Roboto',
    fontWeight: 'regular',
    fontSize: 15,
    color: 'black',
    flex: 1.5
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
  textInputStyle: {
    backgroundColor:"#e8e2d0", 
    color:"black", 
    borderBlockEndColor:'black',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#e8e2d0',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: 300, // Adjust the width as needed
  },
  modalText: {
    fontSize: 18,
    marginBottom: 10,
    fontFamily: 'Roboto',
    justifyContent:'center'
  },
  modalButton: {
    fontSize: 16,
    color: 'black',
    marginTop: 10,

  },
});

export default NervousSystemUpperBody;
