import {create} from 'zustand';

const useAthleteListStore = create((set) => ({
  athleteList: [],
  setAthleteList: (data) => set({ athleteList: data }),

  idTracking: 0,
  setIdTracking: (value) => set({ myVariable: value }),
}));

export default useAthleteListStore;
