import React, {useState, useEffect} from 'react';
import { Button, SafeAreaView, StyleSheet, ScrollView, Text, View } from 'react-native';
import GenderDropdown from '../components/GenderDropdown';
import IncompleteModal from '../components/IncompleteModal';
import LoginInput from '../components/LoginInput';
import { emailValidator } from '../varHelpers/emailValidator'
import { theme } from '../core/theme'
import PaperInput from '../components/PaperInput';
import { numberValidator } from '../varHelpers/numberValidator';
import { textLengthValidator } from '../varHelpers/textLengthValidator';
import { dateValidator } from '../varHelpers/dateValidator';
import Divider from '../components/Divider';
import ShortPaperInput from '../components/ShortPaperInput';
import { formatDOB } from '../varHelpers/formatDOB';
import MultilinePaperInput from '../components/MultilinePaperInput';
import useAthleteStore from '../varHelpers/athleteStore';
import ConfirmModal from '../components/ConfirmModal';
import MaterialTextComponent from '../components/MaterialTextComponent';
import SwitchAssistance from '../components/Switch';
import ErrorModal from '../components/ErrorModal';
import AccountErrorModal from '../components/AccountErrorModal';
import { addressLine2Validator } from '../varHelpers/addressLine2Validator';
import { floatValidator } from '../varHelpers/floatValidator';
import { phoneValidator } from '../varHelpers/phoneValidator';
import { inchValidator } from '../varHelpers/inchValidator';

const AthleteEdit = ({navigation}) => {
  function removeAfterT(inputString) {
    // Match 'T' and anything after that
    const regex = /T.*/;
    return inputString.replace(regex, '');
  }
  const athlete = useAthleteStore((state) => state.athlete);

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [athleteList, setAthleteList] = useState([]);

  const [isPro, setIsPro] = useState(Boolean(athlete.isPro));
  
  // Check if switches are enabled or not
  const [isEnabledInversionL, setIsEnabledInversionL] = useState(!!athlete.isPro);
  // Functions to change switch 
  const toggleSwitchInversionL = () => setIsEnabledInversionL((previousState) => !previousState);
  
  //Text fields

  // Address Line 1
  const [addressLine1, setAddressLine1] = useState({ value: athlete.address1, error: '' });

  useEffect(() => {
    const error = textLengthValidator(addressLine1.value, 256);
    setAddressLine1((prev) => ({ ...prev, error }));
  }, [addressLine1.value]);

  // Address Line 2
  const [addressLine2, setAddressLine2] = useState({ value: athlete.address2, error: '' });

  useEffect(() => {
    const error = addressLine2Validator(addressLine2.value, 256);
    setAddressLine2((prev) => ({ ...prev, error }));
  }, [addressLine2.value]);

  // Mailing Address
  const [mailingAddress, setMailingAddress] = useState({value: athlete.mailing_address, error: ''}); // Use an empty string if athlete.mailingAddress is undefined

  useEffect(() => {
    const error = addressLine2Validator(mailingAddress.value, 256);
    setMailingAddress((prev) => ({ ...prev, error }));
  }, [mailingAddress.value]);

  // City
  const [city, setCity] = useState({ value: athlete.city, error: '' });

  useEffect(() => {
    const error = textLengthValidator(city.value, 256);
    setCity((prev) => ({ ...prev, error }));
  }, [city.value]);

  // State
  const [stateUS, setStateUS] = useState({ value: athlete.state, error: '' });

  useEffect(() => {
    const error = textLengthValidator(stateUS.value, 256);
    setStateUS((prev) => ({ ...prev, error }));
  }, [stateUS.value]);

  // Zip Code
  const [zipCode, setZipCode] = useState({ value: athlete.zipcode, error: '' });

  useEffect(() => {
    const error = numberValidator(zipCode.value);
    setZipCode((prev) => ({ ...prev, error }));
  }, [zipCode.value]);

  // dob
  const [dob, setdob] = useState({ value: athlete.dob, error: '' });

  useEffect(() => {
    // Format the date with hyphens
    const formattedDate = formatDOB(dob.value);
  
    // Update the state with the formatted date
    setdob((prev) => ({ ...prev, value: formattedDate, error: '' }));
  
    // Perform the error check
    const error = dateValidator(formattedDate);
    setdob((prev) => ({ ...prev, error }));
  }, [dob.value]);

  // Height Feet
  const [heightF, setHeightF] = useState({ value: athlete.heightF, error: '' });

  useEffect(() => {
    const error = textLengthValidator(heightF.value, 15);
    setHeightF((prev) => ({ ...prev, error }));
  }, [heightF.value]);

  // Height Inches
  const [heightI, setHeightI] = useState({ value: athlete.heightI, error: '' });

  useEffect(() => {
    const error = inchValidator(heightI.value, 15);
    setHeightI((prev) => ({ ...prev, error }));
  }, [heightI.value]);

  // Weight
  const [weight, setWeight] = useState({ value: athlete.weight, error: '' });

  useEffect(() => {
    const error = floatValidator(weight.value, 15);
    setWeight((prev) => ({ ...prev, error }));
  }, [weight.value]);

  // Sport
  const [sport, setSport] = useState({ value: athlete.sport, error: '' });

  useEffect(() => {
    const error = textLengthValidator(sport.value, 256);
    setSport((prev) => ({ ...prev, error }));
  }, [sport.value]);

  // Position
  const [position, setPosition] = useState({ value: athlete.position, error: '' });

  useEffect(() => {
    const error = textLengthValidator(position.value, 256);
    setPosition((prev) => ({ ...prev, error }));
  }, [position.value]);

  // Club
  const [club, setClub] = useState({ value: athlete.club, error: '' });

  useEffect(() => {
    const error = textLengthValidator(club.value, 256);
    setClub((prev) => ({ ...prev, error }));
  }, [club.value]);

  // Jersey Number
  const [jerseyNumber, setJerseyNumber] = useState({ value: athlete.jersey_num, error: '' });

  useEffect(() => {
    const error = numberValidator(jerseyNumber.value, 15);
    setJerseyNumber((prev) => ({ ...prev, error }));
  }, [jerseyNumber.value]);

  // Phone
  const [phone, setPhone] = useState({ value: athlete.phone, error: '' });
  const phoneError = phoneValidator(phone.value, 256);

  useEffect(() => {
    const error = numberValidator(phone.value, 256);
    setPhone((prev) => ({ ...prev, error }));
  }, [phone.value]);

  // Emergency COntact
  const [ECName, setECName] = useState({ value: athlete.emergency_contact, error: '' });

  useEffect(() => {
    const error = textLengthValidator(ECName.value, 256);
    setECName((prev) => ({ ...prev, error }));
  }, [ECName.value]);

  // ECPhone
  const [ECPhone, setECPhone] = useState({ value: athlete.emergency_contact_phone, error: '' });

  useEffect(() => {
    const error = phoneValidator(ECPhone.value, 256);
    setECPhone((prev) => ({ ...prev, error }));
  }, [ECPhone.value]);

  // COMMENTS
  const [comments, setComments] = useState(athlete.comments);
  const [packageInfo, setPackageInfo] = useState(athlete.package);

  //variables and error checks
  const [email, setEmail] = useState({ value: athlete.email, error: '' });

  useEffect(() => {
    const error = emailValidator(email.value, 256);
    setEmail((prev) => ({ ...prev, error }));
  }, [email.value]);

  //Modal
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  
  const profileJSON = {
    email: email.value,
    phone: phone.value,
    address1: addressLine1.value,
    address2: addressLine2.value,
    city: city.value,
    state: stateUS.value,
    zipcode: zipCode.value,
    emergency_contact: ECName.value,
    emergency_contact_phone: ECPhone.value,
    mailing_address: mailingAddress.value.toString(),
    heightF: parseInt(heightF.value),
    heightI: parseInt(heightI.value),
    weight: parseFloat(weight.value),
    package: packageInfo,
    comments: comments,
     sport: sport.value,
    isPro: isPro,
    club: club.value,
    jersey_num: jerseyNumber.value,
    position: position.value
  };

  const patchAthlete = async (athleteId) => {
    const apiUrl = `https://gamereadyperformanceapp.com/api/athletes/${athleteId}`;
  
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        // 'api-key': process.env.API_KEY,
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      },
      body: JSON.stringify(profileJSON),
    };
  
    try {
      const response = await fetch(apiUrl, options);
  
      if (response.ok) {
        const data = await response.json();
        console.log('Athlete successfully updated:', data);
        navigation.navigate('Athlete Search');

      } else {
        setErrorMessage('Failed to update athlete.');
        setVisible(true);
        // console.log('Failed to update athlete. Status:', response.status);
      }
    } catch (error) {
      setErrorMessage('Error');
      setVisible(true);
      // console.error('Error:', error.message);
    }
  };
  
  const logInputValues = () => {

    const isEmpty = (
      addressLine1.value === '' ||
      // addressLine2.value === '' ||
      // mailingAddress.value === '' ||
      city.value === '' ||
      stateUS.value === '' ||
      zipCode.value === '' ||
      heightF.value === '' ||
      heightI.value === '' ||
      weight.value === '' ||
      sport.value === '' ||
      position.value === '' ||
      club.value === '' ||
      jerseyNumber.value === '' ||
      email.value === '' ||
      phone.value === '' ||
      ECName.value === '' ||
      ECPhone.value === '' ||
      packageInfo === '' ||
      comments === ''
    );

    const noError = (
      addressLine1.error === '' &&
      addressLine2.error === '' &&
      mailingAddress.error === '' &&
      city.error === '' &&
      stateUS.error === '' &&
      zipCode.error === '' &&
      heightF.error === '' &&
      heightI.error === '' &&
      weight.error === '' &&
      sport.error === '' &&
      position.error === '' &&
      club.error === '' &&
      jerseyNumber.error === '' &&
      email.error === '' &&
      phone.error === '' &&
      ECName.error === '' &&
      ECPhone.error === '' 
    );

  if (isEmpty) {
    setShowIncomplete(true);
    return
  }
  else if(!noError){
    showError(true);
    return
  }
  else {
    // console.log(profileJSON);
    patchAthlete(athlete.id);
    // navigation.navigate('Athlete Search');
  };
};

  return (
    <SafeAreaView>
    <ScrollView>
      <View style={styles.container}>
        <View>
          <Text style={styles.labelTitle}>Edit Athlete Profile</Text>
        </View>
        {/* <Divider/> */}
        {/* <Text style={styles.labelCat}>Name</Text> */}
        {/* Athlete Name */}
            <View style={styles.titleContainer}>
              <Text style={styles.labelCat}>{athlete.firstname} {athlete.lastname}</Text>
            </View>
            {/* <Divider/> */}
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Gender" text={athlete.gender}/>
              <MaterialTextComponent title="Date of Birth" text={removeAfterT(athlete.dob)}/>
            </View>
        <Divider/>
        {/* Athlete Address */}
        <Text style={styles.labelCat}>Address</Text>
        <View style={styles.rowContainer}>
          {/* <View> */}
            <PaperInput
              label="Address Line 1"
              returnKeyType="next"
              value={addressLine1.value}
              onChangeText={(text) => setAddressLine1({ value: text, error: '' })}
              error={!!addressLine1.error}
              errorText={addressLine1.error}
              maxLength={256}
            />
            <PaperInput
              label="Address Line 2"
              returnKeyType="next"
              value={addressLine2.value}
              onChangeText={(text) => setAddressLine2({ value: text, error: '' })}
              error={!!addressLine2.error}
              errorText={addressLine2.error}
              maxLength={256}
            />
            <PaperInput
              label="Mailing Address"
              returnKeyType="next"
              value={mailingAddress.value}
              onChangeText={(text) => setMailingAddress({ value: text, error: '' })}
              error={!!mailingAddress.error}
              errorText={mailingAddress.error}
              maxLength={256}
            />
        </View>
          <View style={styles.rowContainerShort}>
            <ShortPaperInput
              label="City"
              returnKeyType="next"
              value={city.value}
              onChangeText={(text) => setCity({ value: text, error: '' })}
              error={!!city.error}
              errorText={city.error}
              maxLength={256}
            />
            <ShortPaperInput
              label="State"
              returnKeyType="next"
              value={stateUS.value}
              onChangeText={(text) => setStateUS({ value: text, error: '' })}
              error={!!stateUS.error}
              errorText={stateUS.error}
              maxLength={256}
            />
            <ShortPaperInput
              label="Zip Code"
              returnKeyType="next"
              value={zipCode.value}
              onChangeText={(text) => setZipCode({ value: text, error: '' })}
              error={!!zipCode.error}
              errorText={zipCode.error}
              maxLength={5}
              keyboardType="number-pad"
            />
        </View> 
        {/* <View>
          <MaterialTextComponent title={"Date of Birth"} text={athlete.dob}/>
        </View> */}
        <Text style={styles.labelCat}>Physical Information</Text>
        <View style={styles.rowContainerShort}>
          <PaperInput
            label="Weight (lbs)"
            returnKeyType="next"
            value={weight.value}
            onChangeText={(text) => setWeight({ value: text, error: '' })}
            error={!!weight.error}
            errorText={weight.error}
            maxLength={10}
            keyboardType="decimal-pad"
          />
          <PaperInput
            label="Feet"
            returnKeyType="next"
            value={heightF.value}
            onChangeText={(text) => setHeightF({ value: text, error: '' })}
            error={!!heightF.error}
            errorText={heightF.error}
            maxLength={2}
            keyboardType="number-pad"
          />
          <PaperInput
            label="Inches"
            returnKeyType="next"
            value={heightI.value}
            onChangeText={(text) => setHeightI({ value: text, error: '' })}
            error={!!heightI.error}
            errorText={heightI.error}
            maxLength={2}
            keyboardType="number-pad"
          />
        </View>
        <Text style={styles.labelCat}>Sport Information</Text>
        <View style={styles.rowContainer}>
          <PaperInput
            label="Sport"
            returnKeyType="next"
            value={sport.value}
            onChangeText={(text) => setSport({ value: text, error: '' })}
            error={!!sport.error}
            errorText={sport.error}
            maxLength={256}
          />
          <PaperInput
            label="Team/Club"
            returnKeyType="next"
            value={club.value}
            onChangeText={(text) => setClub({ value: text, error: '' })}
            error={!!club.error}
            errorText={club.error}
            maxLength={256}
          />
          <PaperInput
            label="Position"
            returnKeyType="next"
            value={position.value}
            onChangeText={(text) => setPosition({ value: text, error: '' })}
            error={!!position.error}
            errorText={position.error}
            maxLength={256}
          />
          <PaperInput
            label="Jersey Number"
            returnKeyType="next"
            value={jerseyNumber.value}
            onChangeText={(text) => setJerseyNumber({ value: text, error: '' })}
            error={!!jerseyNumber.error}
            errorText={jerseyNumber.error}
            maxLength={15}
            keyboardType="decimal-pad"
          />
        </View>
        <View style={styles.rowContainer}>
          <Text style={styles.labelSwitch}>Pro Athlete?</Text>
          <SwitchAssistance 
            isEnabled={isEnabledInversionL} 
            onValueChange={(isEnabled) => {
              // Update the role based on the switch state
              const proStatus = isEnabled ? 1 : 0;
              console.log('isPro:', proStatus);
              setIsPro(proStatus);

              // Toggle the switch and update other state if needed
              toggleSwitchInversionL();
              // setQuadDominanceInversionL(isEnabled ? 1 : 0);
            }}
          />
        </View>
        {/* Athlete Contact Info */}
        <Text style={styles.labelCat}>Athlete Contact Information</Text>
        <View >
          <LoginInput
            label="Email"
            returnKeyType="next"
            value={email.value}
            onChangeText={(text) => setEmail({ value: text, error: '' })}
            error={!!email.error}
            errorText={email.error}
            autoCapitalize="none"
            autoCompleteType="email"
            textContentType="emailAddress"
            keyboardType="email-address"
          />
          <LoginInput
            label="Phone Number"
            returnKeyType="next"
            value={phone.value}
            onChangeText={(text) => setPhone({ value: text, error: '' })}
            error={!!phone.error}
            errorText={phone.error}
            autoCapitalize="none"
            keyboardType="number-pad"
          />
        </View>
        <Text style={styles.labelCat}>Emergency Contact</Text>
        <View>
          <PaperInput
            label="Full Name"
            returnKeyType="next"
            value={ECName.value}
            onChangeText={(text) => setECName({ value: text, error: '' })}
            error={!!ECName.error}
            errorText={ECName.error}
            maxLength={256}
          />  
          <PaperInput
            label="Phone Number"
            returnKeyType="next"
            value={ECPhone.value}
            onChangeText={(text) => setECPhone({ value: text, error: '' })}
            error={!!ECPhone.error}
            errorText={ECPhone.error}
            keyboardType="number-pad"
            maxLength={256}
          />
        </View>
        <Text style={styles.labelCat}>Training Information</Text>
        <View>
          <PaperInput
            label="Package"
            returnKeyType="next"
            value={packageInfo}
            onChangeText={(text) => setPackageInfo(text)}
            maxLength={256}
          />
        </View>
        <View style={styles.containerInfo}>
          <MultilinePaperInput
            label="Comments"
            returnKeyType="next"
            value={comments}
            onChangeText={(text) => setComments(text)}
            maxLength={800}
          />
        </View>
        <View style={styles.rowContainer}>
          <Button 
            title='Cancel'
            onPress={() => navigation.goBack()}
            padding = {15}
          />
          <Button 
            title='Save Changes'
            onPress={() => {
              setShowConfirm(true);
            }}
            padding = {15}
          />
          </View>
        
        <IncompleteModal visible={showIncomplete} onClose={() => setShowIncomplete(false)}/>
        <ErrorModal visible = {showError} onClose = {() => setShowError(false)}/>
        <ConfirmModal
          text={"Update Athlete Profile?"}
          visible={showConfirm} 
          onCancel={() => setShowConfirm(false)}
          onAccept={() => {setShowConfirm(false);
            logInputValues();}}
        />
        <AccountErrorModal 
          text={errorMessage} 
          visible={visible} 
          onClose={() => setVisible(false)}
        />
      </View>
    </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'100%',
  },
  containerInfo: {
    marginVertical: 15,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'70%',
  },
  buttonContainer: {
    marginVertical: 16,
  },
  profile: {
    flex: 7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelInput: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  rowContainerShort: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width:'45%'
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // width:'80%'
  },
  rowContainerName: {
    flexDirection: 'row',
  //  backgroundColor: "yellow"
  },
  labelCat: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 8,
  },
  inputText: {
      padding: 3,
  }
});

export default AthleteEdit;