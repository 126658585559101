import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Slider, Text, Icon } from '@rneui/themed';

const Sliders = () => {
  const [value, setValue] = useState(0);
  console.log("Sliders component rendered with value:", value);

  const interpolate = (start, end) => {
    let k = (value - 0) / 5; // 0 => min  && 10 => MAX
    return Math.ceil((1 - k) * start + k * end) % 256;
  };

  const color = () => {
    let r = interpolate(255, 0);
    let g = interpolate(0, 255);
    let b = interpolate(0, 0);
    return `rgb(${r},${g},${b})`;
  };

  return (
    <>
      {/* <Text style={styles.subHeader}>Quad Dominance</Text> */}

      <View style={[styles.contentView]}>
        <Slider
          value={value}
          onValueChange={setValue}
          maximumValue={4}
          minimumValue={0}
          step={1}
          allowTouchTrack
          trackStyle={{ height: 8, backgroundColor: 'transparent' }}
          thumbStyle={{ height: 2, width: 10, backgroundColor: 'transparent' }}
          thumbProps={{
            children: (
              <Icon
                name="circle"
                type="font-awesome"
                size={10}
                reverse
                containerStyle={{ bottom: 20, right: 20 }}
                color={color()}
                alignItems="center"
                justifyContent="center"
              />
            ),
          }}
        />
        <Text style={{ paddingTop: 20 }}>Value: {value}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  contentView: {
    padding: 20,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'right',
  },
  subHeader: {
    color: 'black',
    textAlign: 'center',
    // paddingVertical: 5,
    marginBottom: 10,
  },
});

export default Sliders;
