import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

// Custom Material UI-style component
const MaterialTextComponent = ({ title, text }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

// Styles
const styles = StyleSheet.create({
  container: {
    // backgroundColor: '#ffffff',
    padding: 16,
    borderRadius: 8,
    marginBottom: 16,
    elevation: 2,
    alignItems: 'center'
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  text: {
    fontSize: 16,
  },
});

export default MaterialTextComponent;
