import React, { useState, useEffect } from 'react';
import { Button, SafeAreaView, StyleSheet, ScrollView, Text, View, Pressable } from 'react-native';
import MaterialTextComponent from '../components/MaterialTextComponent';
import Divider from '../components/Divider';
import useTrainerStore from '../varHelpers/trainerStore';
import ConfirmModal from '../components/ConfirmModal';
import useTrainerListStore from '../varHelpers/trainerListStore';
import BackButton from '../components/BackButton';
import AccountErrorModal from '../components/AccountErrorModal';
import { Linking } from 'react-native';

// import useTrainerStore from '../varHelpers/trainerStore';

const TrainerProfile = ({ navigation }) => {
  const openURL = async (url) => {
    // Check if the link is supported
    const supported = await Linking.canOpenURL(url);
  
    if (supported) {
      // Open the link
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  };
  
  const currentUser = useTrainerStore((state) => state.currentUser);

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const trainer = useTrainerStore((state) => state.trainer);
  const [showDelete, setShowDelete] = useState(false);

  const setTrainer = useTrainerStore((state) => state.setTrainer);
  const setTrainerList = useTrainerListStore((state) => state.setTrainerList);

  const deleteTrainer = async (trainer_id) => {
    const apiUrl = `https://gamereadyperformanceapp.com/api/trainers/${trainer_id}`;
  
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        // 'api-key': process.env.API_KEY,
      },
    };
  
    try {
      const response = await fetch(apiUrl, options);
  
      if (response.ok) {
        console.log(`Athlete with ID ${trainer_id} deleted successfully.`);
        setTrainerList([]);
        setTrainer({});
        navigation.navigate("Trainer Search");

      } else {
        setErrorMessage('Failed to delete athlete');
        setVisible(true);
        // console.log('Failed to delete athlete.');
      }
    } catch (error) {
      setErrorMessage('Error');
      setVisible(true);
      // console.error('Error:', error.message);
    }
  };
  return (
    <SafeAreaView style={styles.container}>
       <View style={styles.leftSide}>
          <BackButton/>
        </View>
      <ScrollView>
        <View style={styles.titleContainer}>
          <Text style={styles.labelTitle}>Trainer Profile Display</Text>
          <Text style={styles.labelCat}>{trainer.firstname} {trainer.lastname}</Text>
          {/* <Text>Current UID: {trainer.user_id}</Text> */}
        </View>
        {trainer ? (
          <>
            <Divider/>
            <Text style={styles.labelCat}>Address</Text>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Adress Line 1" text={trainer.address1}/>
              <MaterialTextComponent title="Address Line 2" text={trainer.address2}/>
              <MaterialTextComponent title="City" text={trainer.city}/>
              <MaterialTextComponent title="State" text={trainer.state}/>
              <MaterialTextComponent title="Zip Code" text={trainer.zipcode}/>
            </View>
            <Divider/>
            <Text style={styles.labelCat}>Emergency Contact</Text>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Name" text={trainer.emergency_contact}/>
              <MaterialTextComponent title="Phone" text={trainer.emergency_contact_phone}/>
            </View>
            <Divider/>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Phone" text={trainer.phone}/>
              <MaterialTextComponent title="Email" text={trainer.email}/>
              <MaterialTextComponent title="Role" text={trainer.role}/>
              <MaterialTextComponent title="Location" text={trainer.location}/>
            </View>
          </>
        ) : (
          <Text style={styles.loadingText}>Loading...</Text>
        )}
        <View style={styles.buttonContainer}>
          <Button
            title='Edit Profile'
            onPress={() => {
              navigation.navigate('Trainer Edit');
            }}
          />
        </View>
        <View style={styles.buttonContainer}>
        {currentUser[0].role == 'super' && (
          <Pressable
            style={{
              backgroundColor: 'red',
              borderRadius: 5,
              padding: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              setShowDelete(true);
            }}
          >
            <Text style={{ color: 'white' }}>DELETE PROFILE</Text>
          </Pressable>
        )}
        </View>
        <ConfirmModal
          text={"This will delete this trainer's profile. Are you sure?"}
          visible={showDelete} 
          onCancel={() => setShowDelete(false)}
          onAccept={() => {setShowDelete(false);
            deleteTrainer(trainer.id);
            openURL('https://console.firebase.google.com/u/0/project/grp-app-d60bb/authentication/users')
          }}
        />
        <AccountErrorModal 
          text={errorMessage} 
          visible={visible} 
          onClose={() => setVisible(false)}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
  },
  titleContainer: {
    alignItems: 'center',
    // marginVertical: 16,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    // marginVertical: 16,
  },
  labelCat: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  labelTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  userDataText: {
    fontSize: 18,
    marginBottom: 4,
  },
  loadingText: {
    fontSize: 18,
    fontStyle: 'italic',
    color: '#888',
    marginBottom: 16,
  },
  buttonContainer: {
    marginVertical: 16,
  },
  leftSide: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 10,
    zIndex: 1,
  },
});

export default TrainerProfile;
