import React, {useState, useEffect} from 'react';
import { Button, SafeAreaView, StyleSheet, ScrollView, Text, View } from 'react-native';
import IncompleteModal from '../components/IncompleteModal';
import ErrorModal from '../components/ErrorModal';
import LoginInput from '../components/LoginInput';
import PaperInput from '../components/PaperInput';
import { numberValidator } from '../varHelpers/numberValidator';
import { textLengthValidator } from '../varHelpers/textLengthValidator';
import ShortPaperInput from '../components/ShortPaperInput';
import LocationDropdown from '../components/TrainerLocationDropDown';
import SwitchAssistance from '../components/Switch';
import useTrainerStore from '../varHelpers/trainerStore';
import MaterialTextComponent from '../components/MaterialTextComponent';
import { Divider } from 'react-native-paper';
import ConfirmModal from '../components/ConfirmModal';
import useTrainerListStore from '../varHelpers/trainerListStore';
import AccountErrorModal from '../components/AccountErrorModal';
import { addressLine2Validator } from '../varHelpers/addressLine2Validator';
import { phoneValidator } from '../varHelpers/phoneValidator';
import RoleDropdown from '../components/RoleDropdown';
import { zipCodeValidator } from '../varHelpers/zipCodeValidator';

const TrainerEdit = ({navigation}) => {

  const currentUser = useTrainerStore((state) => state.currentUser);

  const trainer = useTrainerStore((state) => state.trainer);
  const setTrainer = useTrainerStore((state) => state.setTrainer);

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // const trainerList = useTrainerListStore((state) => state.trainerList);
  const setTrainerList = useTrainerListStore((state) => state.setTrainerList);

  // const [trainerRoleCheck, setTrainerRoleCheck] = useState(false);

  // useEffect(() => {
  //   // Check if trainer.role is "admin" and set the switch accordingly
  //   if (trainer.role === "admin") {
  //     setIsEnabledInversionL(true);
  //   }
  // }, [trainer]);
  


  // Check if switches are enabled or not
  // const [isEnabledInversionL, setIsEnabledInversionL] = useState(trainerRoleCheck);
  
 
  // Functions to change switch 
  // const toggleSwitchInversionL = () => setIsEnabledInversionL((previousState) => !previousState);

  const [location, setLocation] = useState(trainer.location || '');

  const [role, setRole] = useState(trainer.role);

  //phone
  const [phone, setPhone] = useState({ value: trainer.phone||'', error: '' });
  
  useEffect(() => {
    const error = phoneValidator(phone.value);
    setPhone((prev) => ({ ...prev, error }));
  }, [phone.value]);
 
  const [firstName, setFirstName] = useState({ value: trainer.firstname || '', error: '' });

  // Update firstNameTest error based on length using useEffect
  useEffect(() => {
    const error = textLengthValidator(firstName.value || '', 50);
    setFirstName((prev) => ({ ...prev, error }));
  }, [firstName.value]);
  
  const [lastName, setLastName] = useState({ value: trainer.lastname || '', error: '' });

  // Update firstNameTest error based on length using useEffect
  useEffect(() => {
    const error = textLengthValidator(lastName.value, 50);
    setLastName((prev) => ({ ...prev, error }));
  }, [lastName.value]);

  // Address Line 1
  const [addressLine1, setAddressLine1] = useState({ value: trainer.address1 || '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(addressLine1.value, 256);
    setAddressLine1((prev) => ({ ...prev, error }));
  }, [addressLine1.value]);

  // Address Line 2
  const [addressLine2, setAddressLine2] = useState({ value: trainer.address2 || '', error: '' });

  useEffect(() => {
    const error = addressLine2Validator(addressLine2.value || '', 256);
    setAddressLine2((prev) => ({ ...prev, error }));
  }, [addressLine2.value]);

  // City
  const [city, setCity] = useState({ value: trainer.city, error: '' });

  useEffect(() => {
    const error = textLengthValidator(city.value, 256);
    setCity((prev) => ({ ...prev, error }));
  }, [city.value]);

  // State
  const [stateUS, setStateUS] = useState({ value: trainer.state || '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(stateUS.value, 256);
    setStateUS((prev) => ({ ...prev, error }));
  }, [stateUS.value]);

  // Zip Code
  const [zipCode, setZipCode] = useState({ value: trainer.zipcode || '', error: '' });

  useEffect(() => {
    const error = zipCodeValidator(zipCode.value);
    setZipCode((prev) => ({ ...prev, error }));
  }, [zipCode.value]);

  // Phone
  // const [phone, setPhone] = useState({ value: '', error: '' });

  // useEffect(() => {
  //   const error = numberValidator(phone.value, 256);
  //   setPhone((prev) => ({ ...prev, error }));
  // }, [phone.value]);

    // Emergency COntact
    const [ECName, setECName] = useState({ value: trainer.emergency_contact|| '', error: null});

    useEffect(() => {
      const error = textLengthValidator(ECName.value, 256);
      setECName((prev) => ({ ...prev, error }));
    }, [ECName.value]);
    
    // ECPhone
    const [ECPhone, setECPhone] = useState({ value: trainer.emergency_contact_phone || '', error: null });
    
    useEffect(() => {
      const error = phoneValidator(ECPhone.value);
      setECPhone((prev) => ({ ...prev, error }));
    }, [ECPhone.value]);

  //variables and error checks
  // const [email, setEmail] = useState({ value: '', error: '' });

  // useEffect(() => {
  //   const error = emailValidator(email.value, 256);
  //   setEmail((prev) => ({ ...prev, error }));
  // }, [email.value]);

  //Modal
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  
  const profileJSON = {
    address1: addressLine1.value,
    address2: addressLine2.value,
    state: stateUS.value,
    zipCode: zipCode.value,
    phone: phone.value,
    emergency_contact: ECName.value,
    emergency_contact_phone: ECPhone.value,
    location: location,
    role: role,
  };

  const saveChangesTrainer = () => {
    // const isEmpty = (
    //   addressLine1.value.trim.length === 0 ||
    //   addressLine2.value.trim.length === 0 ||
    //   stateUS.value.trim.length === 0 ||
    //   zipCode.value.trim.length === 0 ||
    //   // location.trim.length === 0 ||
    //   ECName.value.trim.length === 0 ||
    //   ECPhone.value.trim.length === 0 
    // );

    const noError = (
      addressLine1.error.trim().length === 0 &&
      addressLine2.error.trim().length === 0 &&
      stateUS.error.trim().length === 0 &&
      zipCode.error.trim().length === 0 &&
      // location.trim().length === 0 &&
      ECName.error.trim().length === 0 &&
      phone.error.trim().length === 0 &&
      ECPhone.error.trim().length === 0
    );

    const errors = {
      title: "These are the errors:",
      addressLine1: addressLine1.error,
      addressLine2: addressLine2.error,
      stateUS: addressLine2.error,
      zipCode: zipCode.error,
      ECName: ECName.error,
      ECPhone: ECPhone.error,
    }

    // if(isEmpty) {
    //   console.log(errors);
    //   console.log(profileJSON);

    //   setShowIncomplete(true);
    // }
    if(!noError){
      // console.log(profileJSON);
      // console.log(noError);
      // console.log(errors);
      setShowError(true);
    } else{
      // console.log(profileJSON);

      patchTrainer();
    }
  };

  const patchTrainer = async () => {
    const apiUrl = `https://gamereadyperformanceapp.com/api/trainers/${trainer.id}`;
  
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        // 'api-key': process.env.API_KEY,
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b'
      },
      body: JSON.stringify(profileJSON),
    };
  
    try {
      const response = await fetch(apiUrl, options);
  
      if (response.ok) {
        const data = await response.json();
        // console.log('Trainer successfully updated:');
        setTrainerList([]);
        setTrainer({});
        navigation.navigate("Trainer Search");
      } else {
        setErrorMessage('Failed to update athlete.');
        setVisible(true);
        // console.log('Failed to update athlete.');
      }
    } catch (error) {
      setErrorMessage('Error');
      setVisible(true);
      // console.error('Error');
    }
  };
  
  return (
    <SafeAreaView>
    <ScrollView>
      <View style={styles.container}>
        <View>
          <Text style={styles.labelTitle}>Trainer Profile Edit</Text>
        </View>
        {/* <Text style={styles.labelCat}>Name</Text> */}
        {/* Trainer Name */}
        <View style={styles.switchContainer}>
          <Text style={styles.labelCat}>{trainer.firstname} {trainer.lastname}</Text>
          <Text style={styles.labelCat}>{trainer.email}</Text>
        </View>
        <Divider/>
        {/* Trainer Address */}
        <View>
         <PaperInput
            label="Trainer Phone Number"
            returnKeyType="next"
            value={phone.value}
            onChangeText={(text) => setPhone({ value: text, error: '' })}
            error={!!phone.error}
            errorText={phone.error}
            keyboardType="number-pad"
            maxLength={50}
          />
        </View>
        <Text style={styles.labelCat}>Address</Text>
        <View style={styles.rowContainer}>
            <PaperInput
              label="Address Line 1"
              returnKeyType="next"
              value={addressLine1.value}
              onChangeText={(text) => setAddressLine1({ value: text, error: '' })}
              error={!!addressLine1.error}
              errorText={addressLine1.error}
              maxLength={256}
            />
            <PaperInput
              label="Address Line 2"
              returnKeyType="next"
              value={addressLine2.value}
              onChangeText={(text) => setAddressLine2({ value: text, error: '' })}
              error={!!addressLine2.error}
              errorText={addressLine2.error}
              maxLength={256}
            />
        </View>
        <View style={styles.rowContainerShort}>
          <ShortPaperInput
            label="City"
            returnKeyType="next"
            value={city.value}
            onChangeText={(text) => setCity({ value: text, error: '' })}
            error={!!city.error}
            errorText={city.error}
            maxLength={256}
          />
          <ShortPaperInput
            label="State"
            returnKeyType="next"
            value={stateUS.value}
            onChangeText={(text) => setStateUS({ value: text, error: '' })}
            error={!!stateUS.error}
            errorText={stateUS.error}
            maxLength={256}
          />
          <ShortPaperInput
            label="Zip Code"
            returnKeyType="next"
            value={zipCode.value}
            onChangeText={(text) => setZipCode({ value: text, error: '' })}
            error={!!zipCode.error}
            errorText={zipCode.error}
            maxLength={15}
            keyboardType="number-pad"
          />
        </View>
        {/* Trainer Contact Info */}
        <Text style={styles.labelCat}>Emergency Contact</Text>
        <View>
          <PaperInput
            label="Full Name"
            returnKeyType="next"
            value={ECName.value}
            onChangeText={(text) => setECName({ value: text, error: '' })}
            error={!!ECName.error}
            errorText={ECName.error}
            maxLength={256}
          />  
          <PaperInput
            label="Phone Number"
            returnKeyType="next"
            value={ECPhone.value}
            onChangeText={(text) => setECPhone({ value: text, error: '' })}
            error={!!ECPhone.error}
            errorText={ECPhone.error}
            keyboardType="number-pad"
            maxLength={256}
          />
        </View>
        <Text style={styles.labelCat}>Account Information</Text>
        <View >
          <MaterialTextComponent title="Email" text={trainer.email}/>
        </View>
        <View>
          <LocationDropdown 
            value={trainer.location}
            onChange={(value) => setLocation(value)}
          />
        {currentUser[0].role === 'super' && (
          <View style={styles.switchContainer}>
            <Text style={styles.labelSwitch}>Admin</Text>
            <RoleDropdown 
              value={role}
              onChange={(value) => setRole(value)}
            />
          </View>
        )} 
          {/* If on, role = admin, else role = trainer */}
        </View>
          <View style={styles.rowContainer}>
            <Button 
              title='Cancel'
              onPress={() => navigation.goBack()}
            />
            <Button 
              title='Save Changes'
              onPress={() => {
                setShowConfirm(true);
              }}
            />
          </View>
        </View>
        <IncompleteModal visible={showIncomplete} onClose={() => setShowIncomplete(false)}/>
        <ErrorModal visible = {showError} onClose = {() => setShowError(false)}/>
        <ConfirmModal
          text={"Update Trainer Profile?"}
          visible={showConfirm} 
          onCancel={() => setShowConfirm(false)}
          onAccept={() => {setShowConfirm(false);
            saveChangesTrainer();}}
        />
        <AccountErrorModal 
          text={errorMessage} 
          visible={visible} 
          onClose={() => setVisible(false)}
        />
      <View/>
    </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    // marginHorizontal: 100,
    marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'100%',
    // backgroundColor: 'gray'
  },
  containerInfo: {
    // marginHorizontal: 100,
    marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'70%',
    // backgroundColor: 'gray'
  },
  switchContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    // backgroundColor: 'gray'
  },
  profile: {
    flex: 7,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:"red"
  },
  labelInput: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowContainerShort: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
  //  backgroundColor: "red",
    width:'45%'
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
  //  backgroundColor: "red",
    width:'80%'
  },
  rowContainerName: {
    flexDirection: 'row',
   backgroundColor: "yellow"
  },
  labelCat: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 8,
  },
  labelSwitch: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 20,
  },
  labelTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  inputText: {
      padding: 3,
  }
});

export default TrainerEdit;