import React, {useEffect, useState} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform, Alert, Modal, TouchableOpacity, Pressable } from 'react-native';
import { DataTable, TextInput} from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'
import { useData } from '../DataContext';
import { useFocusEffect } from '@react-navigation/native';
import useAthleteStore from '../varHelpers/athleteStore';

const TableTotalBody = ({navigation}) => {
    const { athlete, setAthlete } = useAthleteStore();
    const {dataAthleteProfile} = useData();
    const [data, setData] = useState('');

    return(
        <SafeAreaView style={styles.container}>
            <ScrollView>
                <View style={styles.containerView}>
                    <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                    <Text style={{fontFamily:'Roboto', fontSize:'x-large', marginBottom:20, marginTop:20, fontWeight:'bold'}}>Total Body Assessment Parts</Text>
                    <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Range of Motion Upper Body')}
                            title="Range of Motion - Upper Body"
                            color="#989170">Range of Motion - Upper Body</Button>
                    </View>
                    <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Ankle')}
                            title="Range of Motion - Lower Body"
                            color="#989170">Range of Motion - Lower Body</Button>
                    </View>
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Hip')}
                            title="Range of Motion - Lower Body Hip"
                            color="#989170">Lower Body Hip</Button>
                    </View> */}
                    <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => {
                              navigation.navigate('Nervous System Assessment Form - Feet')}}
                            title="Nervous System"
                            color="#989170">Nervous System</Button>
                    </View>
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Ankle')}
                            title="Ankle"
                            color="#989170">Ankle</Button>
                    </View> */}
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Hip Extension')}
                            title="Hip Extension"
                            color="#989170">Hip Extension</Button>
                    </View> */}
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Hip')}
                            title="Hip"
                            color="#989170">Hip</Button>
                    </View> */}
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Hip Flexion')}
                            title="Hip Flexion"
                            color="#989170">Hip Flexion</Button>
                    </View> */}
                    <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Part 1')}
                            title="Functional Movements"
                            color="#989170">Functional Movements</Button>
                    </View>
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Overhead Squat')}
                            title="Overhead Squat"
                            color="#989170">Overhead Squat</Button>
                    </View> */}
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Single Leg Squat')}
                            title="Single Leg Squat"
                            color="#989170">Single Leg Squat</Button>
                    </View> */}
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Single Leg Eyes Open')}
                            title="Single Leg Balance Eyes Open"
                            color="#989170">Single Leg Balance Eyes Open</Button>
                    </View> */}
                    {/* <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Single Leg Eyes Closed')}
                            title="Single Leg Balance Eyes Closed"
                            color="#989170">Single Leg Balance Eyes Closed</Button>
                    </View> */}
                    <View style={styles.buttonContainer}>
                        <Button style={styles.button} 
                            onPress={() => navigation.navigate('Nervous System Assessment Form - VERT Jump')}
                            title="VERT Jump"
                            color="#989170">VERT Jump</Button>
                    </View>
                   
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: 16,
      width:'100%',
    },
    containerView:{
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: 16,
      // backgroundColor: 'gray',
      width:'100%',
    },
    button: {
      flex: 0.2,
      alignSelf: 'flex-end',
      marginBottom:30
    },
    title: {
      marginVertical: 25,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize:30,
      flex:2,
    },
    subTitle: {
      marginVertical: 25,
      fontWeight: 'regular',
      fontFamily: 'Roboto',
      fontSize:30,
    },
    table: {
      // borderWidth: 1,
      // borderColor: 'black',
      width: '100%',
      fontFamily: 'Roboto',
      fontSize: 15,
    },
    tableText: {
      fontFamily: 'Roboto',
      fontWeight: 'regular',
      fontSize: 15,
      color: 'black',
      flex: 1.5
    },
    rowContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end', 
      marginVertical: 20,
      marginHorizontal: 85,
      alignItems:'center',
      // backgroundColor:'dodgerblue',
    },
    rowContainerDownButtons: {
      flex: 2,
      flexDirection: 'row',
      justifyContent: 'flex-end', 
      marginVertical: 20,
      marginHorizontal: 150,
      alignItems:'center',
      // backgroundColor:'dodgerblue',
    },
    rowContainerHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between', 
      marginVertical: 20,
      marginHorizontal: 70,
      alignItems:'center',
      // backgroundColor:'pink',
    },
    columnContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginVertical: 20,
      marginHorizontal: 20,
      // backgroundColor:'green'
    },
    textStyleFirstRow: {
      flex: 1,
      // flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'Roboto',
      marginHorizontal: 30,
      marginVertical: 15,
      fontSize:'large',
    },
    textStyle: {
      flex: 1,
      // flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'Roboto',
      marginHorizontal: 25,
      marginVertical: 15,
      fontSize:'large',
    },
    textInputStyle: {
      backgroundColor:"#e8e2d0", 
      color:"black", 
      borderBlockEndColor:'black',
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      backgroundColor: '#e8e2d0',
      padding: 20,
      borderRadius: 10,
      alignItems: 'center',
      width: 300, // Adjust the width as needed
    },
    modalText: {
      fontSize: 18,
      marginBottom: 10,
      fontFamily: 'Roboto',
      justifyContent:'center'
    },
    modalButton: {
      fontSize: 16,
      color: 'black',
      marginTop: 10,
  
    },
    buttonContainer: {
        marginBottom: 20, // Adjust the spacing between buttons
        width: '100%', // Ensure buttons take the full width
      },
  });
  
  export default TableTotalBody;
  