import React, {useState, useEffect} from 'react';
import { Button, SafeAreaView, StyleSheet, ScrollView, Text, View, Pressable } from 'react-native';
import TextWithInput from '../components/TextWithInput';
import GenderDropdown from '../components/GenderDropdown';
import IncompleteModal from '../components/IncompleteModal';
import ErrorModal from '../components/ErrorModal';
import LoginInput from '../components/LoginInput';
import { emailValidator } from '../varHelpers/emailValidator'
import { theme } from '../core/theme'
import PaperInput from '../components/PaperInput';
import { numberValidator } from '../varHelpers/numberValidator';
import { phoneValidator } from '../varHelpers/phoneValidator';
import { textLengthValidator } from '../varHelpers/textLengthValidator';
import ShortPaperInput from '../components/ShortPaperInput';
import LocationDropdown from '../components/TrainerLocationDropDown';
import SwitchAssistance from '../components/Switch';
import { passwordValidator } from '../varHelpers/passwordValidator';
import AccountErrorModal from '../components/AccountErrorModal';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
// import { textLengthValidator } from '../varHelpers/textLengthValidator';

const SiteInfoEdit = ({ navigation }) => {
  // ... (other state variables)

  // Name
  const [name, setName] = useState('');

  // Phone
  const [phoneError, setPhoneError] = useState('');

  // Email
  const [email, setEmail] = useState('');

  // Address Line 1
  const [addressError, setAddressError] = useState('');

  //Modal
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [showError, setShowError] = useState(false);

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // Site data
  const [siteData, setSiteData] = useState({
    address: '',
    phone: '',
    // Add other fields as needed
  });

  const handleSearch = async () => {
    const options = {
      headers: {
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      },
    };

    const baseUrl = 'https://gamereadyperformanceapp.com/api/sites?site_id=1';

    try {
      const response = await fetch(baseUrl, options);

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSiteData({
          address: data.sites[0].address,
          phone: data.sites[0].phone,
          // Add other fields as needed
        });
      } else {
        setErrorMessage('Error uploading info');
        setVisible(true);
        // console.log('No data found in response');
      }
    } catch (error) {
      setErrorMessage('Error');
      setVisible(true);
      // console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    handleSearch();
    return () => {
      // console.log(siteData);
      // You can perform cleanup tasks here if needed
    };
  }, []);

  // const patchSiteInfo1 = () => {
  //   const patchData = {
  //     address: "307 CA-1, Hermosa Beach, CA 90254, United States",
  //     phone: "+1 424-262-4370",
  //   };

  //   const apiUrl = 'https://game-ready-performance-app.azurewebsites.net/api/sites/1';

  //   const options = {
  //     method: 'PATCH',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
  //     },
  //     body: JSON.stringify(patchData),
  //   };

  //   fetch(apiUrl, options)
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       console.log('Patch successful:', data);
  //       // Handle the response data as needed
  //     })
  //     .catch(error => {
  //       setErrorMessage('Error:', error);
  //       setVisible(true);
  //       console.error('Error:', error);
  //       // Handle errors
  //     });
  // };

  const patchSite = async () => {
    const patchData = {
      name: "Game Ready Performance",
      address: siteData.address, // Use the address from the state
      phone: siteData.phone, // Use the phone from the state
    };
  
    const apiUrl = 'https://game-ready-performance-app.azurewebsites.net/api/sites/1';
  
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      },
      body: JSON.stringify(patchData),
    };
  
    try {
      const response = await fetch(apiUrl, options);
  
      if (!response.ok) {
        setErrorMessage(`Error`);
        setVisible(true);
        // throw new Error(`HTTP error! Status`);
        
      }
  
      const data = await response.json();
      // console.log('Patch successful:', data);
      // Handle the response data as needed
    } catch (error) {
      setErrorMessage('Error');
      setVisible(true);
      // console.error('Error:', error);
      // Handle errors
    }
  };
  
  const siteError = (
    !siteData.address.trim() || // Check if address is empty or contains only whitespace
    !siteData.phone.trim()       // Check if phone is empty or contains only whitespace
  )
  
  return (
    <SafeAreaView>
      {/* <ScrollView> */}
        <View style={styles.containerInfo} />
        <View style={styles.container}>
          <TextWithInput
            labelText="Address"
            value={siteData.address}
            onChangeText={(text) => setSiteData({ ...siteData, address: text })}
            maxLength={256}
          />
          <TextWithInput
            labelText="Phone"
            value={siteData.phone}
            onChangeText={(text) => setSiteData({ ...siteData, phone: text })}
            maxLength={100}
          />
          {/* <Pressable
            style={styles.buttonContainer}
            onPress={() => patchSiteInfo1()}
          >
            <Text style={styles.buttonText}>Patch Site 1 Info</Text>
          </Pressable> */}
        </View>

          <Pressable
            style={styles.buttonContainer}
            onPress={() => {
              if (!siteError) {
                patchSite();
              } else {
                // Handle the case when there is an error (optional)
                setShowIncomplete(true);
              }
            }} // Use the function reference directly
          >
            <Text style={styles.buttonText}>Save Changes</Text>
          </Pressable>

        <View style={styles.containerInfo} />
        <IncompleteModal visible={showIncomplete} onClose={() => setShowIncomplete(false)} />
        <ErrorModal visible={showError} onClose={() => setShowError(false)} />
        <AccountErrorModal 
            text={errorMessage} 
            visible={visible} 
            onClose={() => setVisible(false)}
        />
      {/* </ScrollView> */}
    </SafeAreaView>
  );
};

// export default SiteInfoEdit;

const styles = StyleSheet.create({
  container: {
    flex: 4,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
    // backgroundColor: "red"
  },
  containerInfo: {
    // marginHorizontal: 100,
    // marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    // backgroundColor: 'gray'
  },
  switchContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    // backgroundColor: 'gray'
  },
  buttonContainerView: {
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width: "50%",
    backgroundColor: 'gray'
  },
  profile: {
    flex: 7,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:"red"
  },
  labelInput: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    backgroundColor: '#729982',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 10,
    width: "50%",
    alignSelf: 'center', // Align the button in the center horizontally
    marginVertical: 10, // Add vertical margin if needed
  },

  buttonText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
  //  backgroundColor: "red",
    width:'80%'
  },
  rowContainerName: {
    flexDirection: 'row',
   backgroundColor: "yellow"
  },
  labelCat: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginVertical: 15
  },
  labelSwitch: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 20,
  },
  inputText: {
      padding: 3,
  }
});

export default SiteInfoEdit;
