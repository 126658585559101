export class AuthManager {
    constructor() {
      this.token = null;
      this.apiUrl = 'https://localhost:3000/api'; // https://game-ready-performance-app.azurewebsites.net/api
    }

    isTokenExpired(token) {
        const payloadBase64 = token.split('.')[1];
        const decodedJson = atob(payloadBase64);
        const decoded = JSON.parse(decodedJson);
        const exp = decoded.exp;
        const now = Math.floor(Date.now() / 1000);
        return now >= exp;
      }

    async authenticate() {
      try {
        const response = await fetch(`${this.apiUrl}/authenticate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Authentication failed: ${response.status}`);
        }
  
        const data = await response.json();
        this.token = data.token;
      } catch (error) {
        console.error('Error during authentication:', error);
      }
    }

    logCurrentToken() {
        if (this.token) {
          console.log('Current Token:', this.token);
        } else {
          console.log('No token available');
        }
      }
 
    async refreshToken() {
        try {
          const response = await fetch(`${this.apiUrl}/refresh-token`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${this.token}`,
              'Content-Type': 'application/json'
            },
          });
    
          if (!response.ok) {
            throw new Error(`Token refresh failed: ${response.status}`);
          }
    
          const data = await response.json();
          this.token = data.token;
        } catch (error) {
          console.error('Error during token refresh:', error);
        }
      }
  
    async getAuthHeaders() {
        if (!this.token || this.isTokenExpired(this.token)) {
          await this.refreshToken(); 
        }
        
        if (!this.token) {
          throw new Error('No token available. Please authenticate.');
        }
    
        return {
          Authorization: `Bearer ${this.token}`,
        };
    }
  }
  