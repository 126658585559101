import React, {useState, useEffect} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform } from 'react-native';
import { DataTable, Divider } from 'react-native-paper';
import NervousSystemFormAnkle from './NSFormAnkle';
import NervousSystemLowerBodyHip from './NSFormLowerBodyHip';
import Slider from '@mui/material/Slider';
import SwitchAssistance from '../components/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAthleteStore from '../varHelpers/athleteStore';
import axios from 'axios'
import RequestErrorModal from '../components/RequestErrorModal';

const NervousSystemFormFeet = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();

  const [requestError, setRequestError] = useState(false);

  // Test passing parameters to other screens and initial values
  const [feet_big_toe_r, setfeet_big_toe_r] = useState(0); 
  const [feet_big_toe_l, setfeet_big_toe_l] = useState(0); 
  const [feet_4toes_r, setfeet_4toes_r] = useState(0); 
  const [feet_4toes_l, setfeet_4toes_l] = useState(0); 

  // Check if switches are enabled or not
  const [isEnabledfeet_big_toe_r, setIsEnabledfeet_big_toe_r] = useState(false);
  const [isEnabledfeet_big_toe_l, setIsEnabledfeet_big_toe_l] = useState(false);
  const [isEnabledfeet_4toes_r, setIsEnabledfeet_4toes_r] = useState(false);
  const [isEnabledfeet_4toes_l, setIsEnabledfeet_4toes_l] = useState(false);

  // Functions to change switch 
  const toggleSwitchfeet_big_toe_r = () => setIsEnabledfeet_big_toe_r((previousState) => !previousState);
  const toggleSwitchfeet_big_toe_l = () => setIsEnabledfeet_big_toe_l((previousState) => !previousState);
  const toggleSwitchfeet_4toes_r = () => setIsEnabledfeet_4toes_r((previousState) => !previousState);
  const toggleSwitchfeet_4toes_l = () => setIsEnabledfeet_4toes_l((previousState) => !previousState);

  // Variables amd setters to manage the value of the switch
  const [feet_big_toe_r_quad, setfeet_big_toe_r_quad] = useState(0);
  const [feet_big_toe_l_quad, setfeet_big_toe_l_quad] = useState(0);
  const [feet_4toes_r_quad, setfeet_4toes_r_quad] = useState(0);
  const [feet_4toes_l_quad, setfeet_4toes_l_quad] = useState(0);

  // Color for slider
  const theme = createTheme({
    palette: {
      primary: {
        main: '#989170', 
      },
    },
  });

  // // Array object where the results will be saved (testing)
  const resultsFeet = {
    feet_big_toe_r: feet_big_toe_r,
    feet_big_toe_l: feet_big_toe_l,
    feet_4toes_r: feet_4toes_r,
    feet_4toes_l: feet_4toes_l,
    feet_big_toe_r_quad: feet_big_toe_r_quad,
    feet_big_toe_l_quad: feet_big_toe_l_quad,
    feet_4toes_r_quad: feet_4toes_r_quad,
    feet_4toes_l_quad: feet_4toes_l_quad
  }

  // Receive event and take the value of the event
  const handlefeet_big_toe_rChange = (event, value) => {
    setfeet_big_toe_r(value);
  };

  const handlefeet_big_toe_lChange = (event, value) => {
    setfeet_big_toe_l(value);
  };
  
  const handlefeet_4toes_rChange = (event, value) => {
    setfeet_4toes_r(value)
  };

  const handlefeet_4toes_lChange = (event, value) => {
    setfeet_4toes_l(value)
  };

  // Used to save the array object and pass it to another screen
  const { dataAthleteProfile, dataFeetNervousSystemForm,setDataFeetNervousSystemForm} = useData();
  const [data, setData] = useState('');

  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/nervsys';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }

        const firstPageResponse = await axios.get(apiUrl, { headers });
      
        console.log(firstPageResponse.headers);

        // Access hasNextPage from the response
        hasNextPage = response.data.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const nervSysEvals = response.data.nervSysEvals;
        lastRecord = nervSysEvals[nervSysEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);
  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const nervSysEvals = lastPageResponse.data?.nervSysEvals;
      console.log("nervSysEvals:", nervSysEvals)

      if (nervSysEvals && nervSysEvals.length > 0) {
        const previousSessionValues = {
          feet_big_toe_r: nervSysEvals[0].feet_big_toe_r,
          feet_big_toe_l: nervSysEvals[0].feet_big_toe_l,
          feet_4toes_r: nervSysEvals[0].feet_4toes_r,
          feet_4toes_l: nervSysEvals[0].feet_4toes_l,
          feet_big_toe_r_quad: nervSysEvals[0].feet_big_toe_r_quad,
          feet_big_toe_l_quad: nervSysEvals[0].feet_big_toe_l_quad,
          feet_4toes_r_quad: nervSysEvals[0].feet_4toes_r_quad,
          feet_4toes_l_quad: nervSysEvals[0].feet_4toes_l_quad,
        };
        setDataFeetNervousSystemForm(previousSessionValues);
        console.log("Previous session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404) {
          setRequestError(true)
        }       
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error\
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('feet-form', JSON.stringify(resultsFeet));
      console.log("Data before save:", resultsFeet);
      setData(resultsFeet);
      console.log("Data after save:", data);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            {/* Name of the Athlet and test's title */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Form - Strength Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>
                {/* Form table */}
                <DataTable style={styles.table}>
                  <DataTable.Row>
                    <DataTable.Cell>Feet</DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Right Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Left Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Right Side(Y/N)
                      </Text>
                   </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Left Side(Y/N)
                      </Text>
                     </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Big Toes</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        <ThemeProvider theme={theme}>
                          <Slider 
                                aria-label="Strength"
                                defaultValue={0}
                                valueLabelDisplay="on"
                                step={1}
                                min={0}   
                                max={4}
                                onChange={(event, value) => handlefeet_big_toe_rChange(event, value)}
                                color='primary'
                            />
                        </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                      <ThemeProvider theme={theme}>
                        <Slider 
                              aria-label="Strength"
                              defaultValue={0}
                              valueLabelDisplay="on"
                              step={1}
                              min={0}   
                              max={4}
                              onChange={(event, value) => handlefeet_big_toe_lChange(event, value)}
                              color='primary'
                          />
                      </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledfeet_big_toe_r} onValueChange={(isEnabled, feet_big_toe_r_quad) => {
                                console.log("Enabled value: ", isEnabled )

                                // feet_big_toe_r_quad = isEnabledfeet_big_toe_r ? 1 : 0;
                                
                                if(isEnabled){
                                  feet_big_toe_r_quad = 1;
                                } else {
                                  feet_big_toe_r_quad = 0;
                                }
                                setfeet_big_toe_r_quad(feet_big_toe_r_quad);
                                toggleSwitchfeet_big_toe_r(); // Call the toggleSwitchfeet_big_toe_l function
                                
                          }}/>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledfeet_big_toe_l} onValueChange={(isEnabled, feet_big_toe_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  feet_big_toe_l_quad = 1;
                                } else {
                                  feet_big_toe_l_quad = 0;
                                }
                                toggleSwitchfeet_big_toe_l(); // Call the toggleSwitchfeet_big_toe_l function
                                setfeet_big_toe_l_quad(feet_big_toe_l_quad);
                          }} />
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Four Toes</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                aria-label="Strength"
                                defaultValue={0}
                                valueLabelDisplay="on"
                                step={1}
                                min={0}   
                                max={4}
                                onChange={(event, value) => handlefeet_4toes_rChange(event, value)}
                                color='primary'
                            />
                          </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        <ThemeProvider theme={theme}>
                          <Slider 
                                aria-label="Strength"
                                defaultValue={0}
                                valueLabelDisplay="on"
                                step={1}
                                min={0}   
                                max={4}
                                onChange={(event, value) => handlefeet_4toes_lChange(event, value)}
                                color='primary'
                            />
                        </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledfeet_4toes_r} onValueChange={(isEnabled, feet_4toes_r_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  feet_4toes_r_quad = 1;
                                } else {
                                  feet_4toes_r_quad = 0;
                                }
                                toggleSwitchfeet_4toes_r(); // Call the toggleSwitchfeet_big_toe_l function
                                setfeet_4toes_r_quad(feet_4toes_r_quad);
                          }} />
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledfeet_4toes_l} onValueChange={(isEnabled, feet_4toes_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  feet_4toes_l_quad = 1;
                                } else {
                                  feet_4toes_l_quad = 0;
                                }
                                toggleSwitchfeet_4toes_l(); // Call the toggleSwitchfeet_big_toe_l function
                                setfeet_4toes_l_quad(feet_4toes_l_quad);
                          }} />
                    </DataTable.Cell>
                  </DataTable.Row>
                </DataTable>

                <DataTable style={styles.table}>
                  <DataTable.Header>
                      <DataTable.Title>Previous Session</DataTable.Title>
                  </DataTable.Header>
                  <DataTable.Row>
                    <DataTable.Cell>Feet</DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Right Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Left Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Right Side(Y/N)
                      </Text>
                   </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Left Side(Y/N)
                      </Text>
                     </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Big Toes</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataFeetNervousSystemForm.feet_big_toe_r}      
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataFeetNervousSystemForm.feet_big_toe_l}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      {dataFeetNervousSystemForm.feet_big_toe_r_quad }
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      {dataFeetNervousSystemForm.feet_big_toe_l_quad }
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Four Toes</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataFeetNervousSystemForm.feet_4toes_r}
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataFeetNervousSystemForm.feet_4toes_l}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      {dataFeetNervousSystemForm.feet_4toes_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      {dataFeetNervousSystemForm.feet_4toes_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                </DataTable>
                

              {/* Buttons to move across screens */}
              <View style={styles.rowContainer}>
              <Button
                    title="Previous Page"
                    color="#989170"
                    onPress={() => {
                      navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Hip', NervousSystemLowerBodyHip)}}
                    style={styles.button}
                />
                
                <Button
                    title="Continue and save"
                    color="#729982"
                    onPress={ async() => {
                          
                          for (let key in resultsFeet) {
                            if (resultsFeet.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsFeet[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsFeet).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(!flagForNullValues){
                            console.log("There are missing values")
                          }
                          console.log("Results before:",resultsFeet)
                           // Wait for saveData to complete before proceeding
                          setDataFeetNervousSystemForm(resultsFeet)
                          console.log("Results after:",dataFeetNervousSystemForm);
                          // Assuming submitData is an asynchronous function, you might want to call it here
                          // and navigate only after it has completed (if it's necessary)
                          saveData();
                          navigation.navigate('Nervous System Assessment Form - Ankle', NervousSystemFormAnkle);
                          
                            
                          
                        }}
                    style={styles.button}
                />
                <Button
                    title="Continue without saving"
                    color="#989170"
                    onPress={() => {
                      navigation.navigate('Nervous System Assessment Form - Ankle', NervousSystemFormAnkle)}}
                    style={styles.button}
                />
                </View>
                 {/* Custom modals for web */}
                 {Platform.OS === 'web' && (
                        <>
                          <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                        </>
                      )}
            </View>
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'center', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
    flex:20,
    marginBottom:20,
  },
});

export default NervousSystemFormFeet;
