import React, { useState, useEffect } from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform, Alert, Modal, TouchableOpacity } from 'react-native';
import { TextInput, DataTable } from 'react-native-paper';
import NervousSystemUpperBody from './NSFormUpperBody';
import NervousSystemLowerBodyHip from './NSFormLowerBodyHip';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';

const NervousSystemLowerBodyAnkle = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();

  const [requestError, setRequestError] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState('');
  // const [formFilled, setFormFilled] = useState(false);

  // Form variables and setters with initial values 
  const [dorsi_r, setdorsi_r] = useState('');
  const [dorsi_l, setdorsi_l] = useState('');
  const [plantar_r, setplantar_r] = useState('');
  const [plantar_l, setplantar_l] = useState('');
  
  // Array object where the results will be saved (testing)
  const resultsLowerBodyAnkle = {
    dorsi_r: dorsi_r,
    dorsi_l: dorsi_l,
    plantar_r: plantar_r,
    plantar_l: plantar_l
  }

   // Used to save the array object and pass it to another screen
  const {dataLowerBodyAnkleForm, dataAthleteProfile, setDataLowerBodyAnkleForm} = useData();

 /**
  * The function `getDataFromDb` makes a series of GET requests to retrieve data from a database,
  * paginating through the results until there are no more pages, and handles any errors that occur
  * during the process.
  * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
  * you want to retrieve data from the database. This ID is used in the API request to fetch the
  * athlete's evaluation data.
  */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/lowerrom/';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }


        // Access hasNextPage from the response
        hasNextPage = response.data.hasNextPage;


        // Handle the response as needed
        // console.log(`Response for page ${currentPage}:`, response.data);

        const lowerROMEvals = response.data.lowerROMEvals;
        lastRecord = lowerROMEvals[lowerROMEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const lowerROMEvals = lastPageResponse.data?.lowerROMEvals;
      console.log("lowerROMEvals:", lowerROMEvals)

      if (lowerROMEvals && lowerROMEvals.length > 0) {
        const previousSessionValues = {
          dorsi_r: lowerROMEvals[0].dorsi_r,
          dorsi_l: lowerROMEvals[0].dorsi_l,
          plantar_r: lowerROMEvals[0].plantar_r,
          plantar_l: lowerROMEvals[0].plantar_l,
        };
        setDataLowerBodyAnkleForm(previousSessionValues);
        // console.log("Data Lower Ankle:", data);
        console.log("Previous session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true);
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /* The above code is a useEffect hook in JavaScript. It is used to fetch data from a database
  (presumably using the `getDataFromDb` function) when a component mounts. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `saveData` saves the `resultsLowerBodyAnkle` data to AsyncStorage in JSON format.
   */
  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('lower-body-ankle', JSON.stringify(resultsLowerBodyAnkle));
      setData(resultsLowerBodyAnkle);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };

  return (

    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            
            {/* Athlete Name and Test Title */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Form - Mobility Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>

                {/* Forms table */}
                <View style={{marginBottom: 50}}>
                  <DataTable style={styles.table}>
                    <DataTable.Header>
                      <DataTable.Title style={{justifyContent:'center', flex:1}} >Degrees of Rotation Accomplished per side</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row style={{flex:2}}>
                      <DataTable.Cell>
                        <Text>Range of Motion{'\n'}Lower Body Ankle</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Right Side{'\n'}180 degrees</DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Left Side{'\n'}180 degrees</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>Dorsi Flexion</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={dorsi_r} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);

                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 180)) {
                                  setdorsi_r(text === "" ? "" : intValue);                              
                            } else {
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 2}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={dorsi_l} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 180)) {
                                  setdorsi_l(text === "" ? "" : intValue);                             
                            } else {
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                    
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>Plantar Flexion</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                          <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={plantar_r} 
                              onChangeText={(text) => {
                              const intValue = parseInt(text);
                              if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 180)) {
                                    setplantar_r(text === "" ? "" : intValue);                               
                              } else {
                                console.log("Invalid input. Please enter a valid number within the range.");
                              }
                            }}
                            />
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 2}}>
                          <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={plantar_l} 
                              onChangeText={(text) => {
                              const intValue = parseInt(text);
                              if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 180)) {
                                    setplantar_l(text === "" ? "" : intValue);                                
                              } else {
                                console.log("Invalid input. Please enter a valid number within the range.");
                              }
                            }}
                            />
                        </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>

                 {/* Report table */}
                <View style={{marginBottom: 50, width: '100%'}}>
                  <DataTable style={styles.table}>
                      <DataTable.Header>
                        <DataTable.Title style={{justifyContent:'center', flex:2}} >Degrees of Rotation Accomplished per side</DataTable.Title>
                      </DataTable.Header>
                      <DataTable.Row style={{justifyContent:'center'}}>
                        <DataTable.Cell style={{flex: 1.8}}>
                          <Text>
                            Range of Motion{'\n'}Lower Body Ankle
                          </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          <Text>
                            Right Side{'\n'}180 degrees
                            </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8,  justifyContent:'center'}}>
                          <Text>
                            Left Side{'\n'}180 degrees
                            </Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell style={{flex: 1.8}}>Dorsi Flexion</DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataLowerBodyAnkleForm.dorsi_r}
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataLowerBodyAnkleForm.dorsi_l}
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell style={{flex: 1.8}}>Plantar Flexion</DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataLowerBodyAnkleForm.plantar_r}
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataLowerBodyAnkleForm.plantar_l}
                        </DataTable.Cell>
                      </DataTable.Row>
                  
                    </DataTable>
                  </View>       

              <View style={styles.rowContainerDownButtons}>
              {/* Buttons to navigate among screens */}
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Range of Motion Upper Body',NervousSystemUpperBody)}
                        style={styles.button}
                    />
                    <Button
                        title="Continue and save"
                        color="#729982"
                        onPress={() => {
                          for (let key in resultsLowerBodyAnkle) {
                            if (resultsLowerBodyAnkle.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsLowerBodyAnkle[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsLowerBodyAnkle).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(!flagForNullValues){
                            console.log("There are missing values")
                          }
                            console.log("Results before:",resultsLowerBodyAnkle)
                            setDataLowerBodyAnkleForm(resultsLowerBodyAnkle)
                            console.log("Results after:",dataLowerBodyAnkleForm)
                            saveData();
                            getDataFromDb(athlete.id);
                            navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Hip', NervousSystemLowerBodyHip)
                          
                        }}
                        style={styles.button}
                    />
                    <Button
                        title="Continue without saving"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Hip', NervousSystemLowerBodyHip)}
                        style={styles.button}
                    />
                    {/* Custom modals for web */}
                      {Platform.OS === 'web' && (
                                <>
                                  <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                                </>
                              )}
              </View>

                
          </View>
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
    display: 'flex',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 180,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 180,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between', 
    marginVertical: 180,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 180,
    marginHorizontal: 180,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
    flex:180,
  },
  textInputStyle: {
    backgroundColor:"#e8e2d0", 
    color:"black", 
    borderBlockEndColor:'black',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#e8e2d0',
    padding: 180,
    borderRadius: 10,
    alignItems: 'center',
    width: 300, // Adjust the width as needed
  },
  modalText: {
    fontSize: 18,
    marginBottom: 10,
    fontFamily: 'Roboto',
    justifyContent:'center'
  },
  modalButton: {
    fontSize: 16,
    color: 'black',
    marginTop: 10,

  },
});

export default NervousSystemLowerBodyAnkle;
