// SwitchAssistance.js
import React from 'react';
import { Switch } from 'react-native';

const SwitchAssistance = ({ isEnabled, onValueChange }) => {
  return (
    <Switch
      trackColor={{ false: '#e8e2d0', true: '#6a5f00' }}
      thumbColor={isEnabled ? '#6a5f00' : '#ffffff'}
      onValueChange={onValueChange}
      value={isEnabled}
    />
  );
};

export default SwitchAssistance;
