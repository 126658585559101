import React, {useEffect, useState} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform} from 'react-native';
import { DataTable } from 'react-native-paper';
import { useData } from '../DataContext';
import useAthleteStore from '../varHelpers/athleteStore';
import axios from 'axios';
import RequestErrorModal from '../components/RequestErrorModal';

const NervousSystemReportSquatAndEyes = ({navigation}) => {

    const { athlete, setAthlete } = useAthleteStore();

    const [requestError, setRequestError] = useState('')
                
    // Used to get the array object from another screen
    const {dataAllFunctionalMovements, setDataAllFunctionalMovements} = useData('');

    const getDataFromDb = async (athlete_id) => {
        let currentPage = 0;
        let hasNextPage = true;
        let lastRecord = 0;
        try {
          // Adjust the URL to use the correct protocol and domain
          const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/funcmov/';
          const parameters = `?athlete_id=${athlete_id}`;
      
          // const firstPageUrl = apiUrl + parameters;
      
          // You may need to adjust headers based on your API requirements
          const headers = {
            'Content-Type': 'application/json',
            'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
          };
    
          // Continue making requests as long as there is a next page
          while (hasNextPage) {
            // Make the GET request using Axios
            const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );
    
            // Log the raw response for debugging
            // console.log("Raw Response:", response);
    
            // Determine content type manually
            const contentType = response.headers['content-type'];
            // console.log("Content-Type:", contentType);
    
            // Parse data based on content type
            let responseData;
            if (contentType && contentType.includes('application/json')) {
                responseData = response.data;
                // console.log("Response data in JSON:", responseData);
            } else {
                // Handle other content types if needed
                // For now, assume it's JSON
                responseData = JSON.parse(response.data);
                // console.log("Response data in JSON:", responseData);
            }
    
    
            // Access hasNextPage from the response
            hasNextPage = response.data.hasNextPage;
    
    
            // Handle the response as needed
            console.log(`Response for page ${currentPage}:`, response.data);
    
            const funcMovEvals = response.data.funcMovEvals;
            lastRecord = funcMovEvals[funcMovEvals.length - 1].id;
            console.log(`${lastRecord}: ${typeof lastRecord}`);
    
            // Increment the page for the next request
            currentPage++;
    
            // Introduce some delay if needed to avoid overwhelming the API
            // await new Promise(resolve => setTimeout(resolve, 1000));
          }
      
          // Make the initial GET request to get the total count and last page
          const firstPageResponse = await axios.get(apiUrl, { headers });
          
          
          console.log(firstPageResponse.headers);
    
      
          // Now fetch the last page
    
          const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;
    
          console.log("Page:", currentPage);
          console.log('GET Request:', lastPageUrl);
          // console.log(lastPageResponse.data);
          console.log("Last Record ID:", lastRecord);
          const lastPageResponse = await axios.get(lastPageUrl, { headers });
      
          // Handle the response as needed
          console.log("Last Response: ", lastPageResponse);
          console.log("Last Page Response code:", lastPageResponse.status);
          console.log('Last Page Response:', lastPageResponse.data);
          // console.log("Last Page Post R: ", lastPageResponse.data.curl_up_reps)
    
      // Assuming you have a structure in lastPageData for the "Previous Session" values
          const funcMovEvals = lastPageResponse.data?.funcMovEvals;
          console.log("funcMovEvals:", funcMovEvals)
    
          if (funcMovEvals && funcMovEvals.length > 0) {
            const previousSessionValues = {
                curl_up_reps: funcMovEvals[0].curl_up_reps,
                curl_up_assisted: funcMovEvals[0].curl_up_assisted,
                curl_up_reason: funcMovEvals[0].curl_up_reason,
                push_up_reps: funcMovEvals[0].push_up_reps,
                push_up_assisted: funcMovEvals[0].push_up_assisted,
                push_up_reason: funcMovEvals[0].push_up_reason,
                right_foot_oh: funcMovEvals[0].right_foot_oh,
                left_foot_oh: funcMovEvals[0].left_foot_oh,
                right_ankle_oh: funcMovEvals[0].right_ankle_oh,
                left_ankle_oh: funcMovEvals[0].left_ankle_oh,
                right_knee_oh: funcMovEvals[0].right_knee_oh,
                left_knee_oh: funcMovEvals[0].left_knee_oh,
                pelvis_oh: funcMovEvals[0].pelvis_oh,
                spine_oh: funcMovEvals[0].spine_oh,
                right_foot_single_squat: funcMovEvals[0].right_foot_single_squat,
                left_foot_single_squat: funcMovEvals[0].left_foot_single_squat,
                right_ankle__single_squat: funcMovEvals[0].right_ankle__single_squat,
                left_ankle_single_squat: funcMovEvals[0].left_ankle_single_squat,
                right_knee_single_squat: funcMovEvals[0].right_knee_single_squat,
                left_knee_single_squat: funcMovEvals[0].left_knee_single_squat,
                pelvis_single_squat: funcMovEvals[0].pelvis_single_squat,
                spine_single_squat: funcMovEvals[0].spine_single_squat,
                right_foot_single_open: funcMovEvals[0].right_foot_single_open,
                left_foot_single_open: funcMovEvals[0].left_foot_single_open,
                right_ankle_single_open: funcMovEvals[0].right_ankle_single_open,
                left_ankle_single_open: funcMovEvals[0].left_ankle_single_open,
                right_knee_single_open: funcMovEvals[0].right_knee_single_open,
                left_knee_single_open: funcMovEvals[0].left_knee_single_open,
                pelvis_single_open: funcMovEvals[0].pelvis_single_open,
                spine_single_open: funcMovEvals[0].spine_single_open,
                right_foot_single_closed: funcMovEvals[0].right_foot_single_closed,
                left_foot_single_closed: funcMovEvals[0].left_foot_single_closed,
                right_ankle_single_closed: funcMovEvals[0].right_ankle_single_closed,
                left_ankle_single_closed: funcMovEvals[0].left_ankle_single_closed,
                right_knee_single_closed: funcMovEvals[0].right_knee_single_closed,
                left_knee_single_closed: funcMovEvals[0].left_knee_single_closed,
                pelvis_single_closed: funcMovEvals[0].pelvis_single_closed,
                spine_single_closed: funcMovEvals[0].spine_single_closed,
            };
            setDataAllFunctionalMovements(previousSessionValues);
            console.log(previousSessionValues);
          }
          
        } catch (error) {
          // Handle errors
          console.error('Error submitting data:', error);
      
          // You can also check specific error properties, e.g., error.response
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if(error.response.status !== 404){
                setRequestError(true);
            }
            console.error('Server responded with:', error.response.data);
            console.error('Status code:', error.response.status);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received. Request:', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up the request:', error.message);
          }
        }
      };
    
      useEffect(() => {
        // Retrieve data from AsyncStorage when the component mounts
        const fetchData = async () => {
          try {
            // You can get athlete_id, trainer_id, and site_id from wherever you need
            const athlete_id = athlete.id;
    
            // Call your data fetching function here
            await getDataFromDb(athlete_id);
          } catch (error) {
            console.error('Error fetching data on component mount:', error);
            // Handle errors if needed
          }
        };
    
        fetchData();
      }, []); // Empty dependency array ensures this effect runs once when the component mounts
    
    
    return (
        <SafeAreaView style={styles.container}>
            <ScrollView contentContainerstyle={styles.containerView}>
                <View>
                {/* Athlete's name and test report title */}
                    <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                    <Text style={styles.subTitle}>Functional Movements Report</Text>
                    <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Report')}
                        title="Go to : Reports Section"
                        color="#989170">Reports Section</Button>

                </View>
                <View>
                    {/* Report Table of Functional Movements */}
                  <DataTable style={styles.table}>
                    <DataTable.Header>
                      <DataTable.Title>Functional Movements</DataTable.Title>
                      <DataTable.Title>Number of Repetitions</DataTable.Title>
                      <DataTable.Title>Assisted</DataTable.Title>
                      <DataTable.Title>Comments</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                      <DataTable.Cell>Curl Up</DataTable.Cell>
                      <DataTable.Cell>{dataAllFunctionalMovements.curl_up_reps}</DataTable.Cell>
                      <DataTable.Cell>{dataAllFunctionalMovements.curl_up_assisted !== undefined ? dataAllFunctionalMovements.curl_up_assisted.toString() : '0'}</DataTable.Cell>
                      <DataTable.Cell>{dataAllFunctionalMovements.curl_up_reason}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>Push Up</DataTable.Cell>
                      <DataTable.Cell>{dataAllFunctionalMovements.push_up_reps}</DataTable.Cell>
                      <DataTable.Cell>{dataAllFunctionalMovements.push_up_assisted !== undefined ? dataAllFunctionalMovements.push_up_assisted.toString() : '0'}</DataTable.Cell>
                      <DataTable.Cell>{dataAllFunctionalMovements.push_up_reason}</DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>

                <View>
                {/* Tables with results (this will be taken from dropdowns) */}
                    <DataTable  style={styles.table}>
                        <DataTable.Header>
                          <DataTable.Title style={{justifyContent:'center'}}>Overhead Squat</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Header>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Right Movement</DataTable.Title>
                            <DataTable.Title>Left Movement</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row>
                            <DataTable.Cell>Foot</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_foot_oh}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_foot_oh}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Ankle</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_ankle_oh}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_ankle_oh}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Knee</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_knee_oh}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_knee_oh}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Movement</DataTable.Title>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Pelvis</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.pelvis_oh}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Spine</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.spine_oh}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                </View>

                <View>
                {/* Tables with results (this will be taken from dropdowns) */}
                    <DataTable  style={styles.table}>
                        <DataTable.Header>
                          <DataTable.Title style={{justifyContent:'center'}}>Single Leg Squat</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Header>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Right Movement</DataTable.Title>
                            <DataTable.Title>Left Movement</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row>
                            <DataTable.Cell>Foot</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_foot_single_squat}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_foot_single_squat}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Ankle</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_ankle__single_squat}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_ankle_single_squat}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Knee</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_knee_single_squat}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_knee_single_squat}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Movement</DataTable.Title>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Pelvis</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.pelvis_single_squat}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Spine</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.spine_single_squat}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                </View>
                <View>
                {/* Tables with results (this will be taken from dropdowns) */}
                    <DataTable  style={styles.table}>
                        <DataTable.Header>
                          <DataTable.Title style={{justifyContent:'center'}}>Single Leg Eyes Open</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Header>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Right Movement</DataTable.Title>
                            <DataTable.Title>Left Movement</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row>
                            <DataTable.Cell>Foot</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_foot_single_open}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_foot_single_open}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Ankle</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_ankle_single_open}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_ankle_single_open}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Knee</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_knee_single_open}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_ankle_single_open}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Pelvis</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.pelvis_single_open}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Spine</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.spine_single_open}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                </View>

                <View>
                {/* Tables with results (this will be taken from dropdowns) */}
                    <DataTable  style={styles.table}>
                        <DataTable.Header>
                          <DataTable.Title style={{justifyContent:'center'}}>Single Leg Eyes Closed</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Header>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Right Movement</DataTable.Title>
                            <DataTable.Title>Left Movement</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row>
                            <DataTable.Cell>Foot</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_foot_single_closed}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_ankle_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Ankle</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_ankle_single_closed}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_ankle_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Knee</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.right_knee_single_closed}</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.left_knee_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Movement</DataTable.Title>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Pelvis</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.pelvis_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Spine</DataTable.Cell>
                            <DataTable.Cell>{dataAllFunctionalMovements.spine_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                </View>
                {/* Custom modals for web */}
                {Platform.OS === 'web' && (
                                    <>
                                    <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                                    </>
                                )}
                <View style={styles.rowContainerDownButtons}>
                {/* Buttons to navigate among screens */}
                    <Button
                    title="Previous Page"
                    color="#989170"
                    onPress={() => navigation.navigate('Report - Nervous Assessment System: VERT Jump')}
                    />
                    <Button
                    title="Next Page"
                    color="#989170"
                    onPress={() => navigation.navigate('Report - Sprint Capacity and Threshold Test')}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    containerView: {
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    button: {
      flex: 0.2,
      alignSelf: 'flex-end',
      marginEnd: 50,
      borderBottomEndRadius: 10,
    },
    title: {
      marginVertical: 25,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: 30,
      alignItems: 'center',
      justifyContent:'center',
    },
    subTitle: {
      marginVertical: 25,
      fontWeight: 'regular',
      fontFamily: 'Roboto',
      fontSize:30,
    },
    rowContainerHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 20,
      marginHorizontal: 70,
      alignItems: 'center',
    },
    rowContainerDownButtons: {
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'flex-end', 
        marginVertical: 20,
        marginHorizontal: 150,
        alignItems:'center',
        // backgroundColor:'dodgerblue',
      },
    input: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
    },
    table: {
      borderWidth: 1,
      borderColor: 'black',
      width: '100%',
      marginTop:30,
    },
    textStyleFirstRow: {
      flex: 1,
      justifyContent: 'center',
      fontFamily: 'Roboto',
      marginHorizontal: 30,
      marginVertical: 15,
      fontSize: 20,
    },
  });

export default NervousSystemReportSquatAndEyes;