import React, {useState, useEffect} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform} from 'react-native';
import FeetDropdown from '../components/FeetDropdown';
import AnkleDropdown from '../components/AnkleDropdown';
import KneeDropdown from '../components/KneeDropdown';
import PelvisDropdown from '../components/PelvisDropdown';
import SpineDropdown from '../components/SpineDropdown';
import NervousSystemSingleLegEyesOpen from './NSFormSingleLegEyesOpen';
import ThresholdFormWarmUp from './ThresholdFormWarmUp';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DataTable } from 'react-native-paper';
import axios from 'axios';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';
import useTrainerStore from '../varHelpers/trainerStore';

const NervousSystemSingleLegEyesClosed = ({navigation}) => {

  const { athlete, setAthlete } = useAthleteStore();
  const {currentUser} = useTrainerStore();

  const [requestError, setRequestError] = useState(false);

  // Form variables and setters with initial values 
  const [right_foot_single_closed, setright_foot_single_closed] = useState('');
  const [left_foot_single_closed, setleft_foot_single_closed] = useState('');
  const [right_ankle_single_closed, setright_ankle_single_closed] = useState('');
  const [left_ankle_single_closed, setleft_ankle_single_closed] = useState('');
  const [right_knee_single_closed, setright_knee_single_closed] = useState('');
  const [left_knee_single_closed, setleft_knee_single_closed] = useState('');
  const [pelvis_single_closed, setpelvis_single_closed] = useState('');
  const [spine_single_closed, setspine_single_closed] = useState('');

  // Array object where the results will be saved (testing)
  const resultsSingleLegEyesClosed = {
    right_foot_single_closed: right_foot_single_closed,
    left_foot_single_closed: left_foot_single_closed,
    right_ankle_single_closed: right_ankle_single_closed,
    left_ankle_single_closed: left_ankle_single_closed,
    right_knee_single_closed: right_knee_single_closed,
    left_knee_single_closed: left_knee_single_closed,
    pelvis_single_closed: pelvis_single_closed,
    spine_single_closed: spine_single_closed
  }

  // Used to save the array object and pass it to another screen {testing}
  const {dataAthleteProfile, dataSingleLegEyesClosed,setDataSingleLegEyesClosed,
  dataSingleLegEyesOpen, dataOverheadSquat, dataSingleLegSquat, dataFunctionalMovements, setDataAllFunctionalMovements} = useData();
  const [data, setData] = useState('');

  const finalResults = {
          athlete_id:athlete.id,
          trainer_id:currentUser[0].id,
          site_id:0,
          curl_up_reps: dataFunctionalMovements.curl_up_reps,
          curl_up_assisted: parseInt(dataFunctionalMovements.curl_up_assisted),
          curl_up_reason: dataFunctionalMovements.curl_up_reason,
          push_up_reps: dataFunctionalMovements.push_up_reps,
          push_up_assisted: parseInt(dataFunctionalMovements.push_up_assisted),
          push_up_reason: dataFunctionalMovements.push_up_reason,
          overhead_squat: 54,
          right_foot_oh: dataOverheadSquat.right_foot_oh,
          left_foot_oh: dataOverheadSquat.left_foot_oh,
          right_ankle_oh: dataOverheadSquat.right_ankle_oh,
          left_ankle_oh: dataOverheadSquat.left_ankle_oh,
          right_knee_oh: dataOverheadSquat.right_knee_oh,
          left_knee_oh: dataOverheadSquat.left_knee_oh,
          pelvis_oh: dataOverheadSquat.pelvis_oh,
          spine_oh: dataOverheadSquat.spine_oh,
          right_foot_single_squat: dataSingleLegSquat.right_foot_single_squat,
          left_foot_single_squat: dataSingleLegSquat.left_foot_single_squat,
          right_ankle__single_squat: dataSingleLegSquat.right_ankle__single_squat,
          left_ankle_single_squat: dataSingleLegSquat.left_ankle_single_squat,
          right_knee_single_squat: dataSingleLegSquat.right_knee_single_squat,
          left_knee_single_squat: dataSingleLegSquat.left_knee_single_squat,
          pelvis_single_squat: dataSingleLegSquat.pelvis_single_squat,
          spine_single_squat: dataSingleLegSquat.spine_single_squat,
          right_foot_single_open: dataSingleLegEyesOpen.right_foot_single_open,
          left_foot_single_open: dataSingleLegEyesOpen.left_foot_single_open,
          right_ankle_single_open: dataSingleLegEyesOpen.right_ankle_single_open,
          left_ankle_single_open: dataSingleLegEyesOpen.left_ankle_single_open,
          right_knee_single_open: dataSingleLegEyesOpen.right_knee_single_open,
          left_knee_single_open: dataSingleLegEyesOpen.left_knee_single_open,
          pelvis_single_open: dataSingleLegEyesOpen.pelvis_single_open,
          spine_single_open: dataSingleLegEyesOpen.spine_single_open,
          right_foot_single_closed: resultsSingleLegEyesClosed.right_foot_single_closed,
          left_foot_single_closed: resultsSingleLegEyesClosed.left_foot_single_closed,
          right_ankle_single_closed: resultsSingleLegEyesClosed.right_ankle_single_closed,
          left_ankle_single_closed: resultsSingleLegEyesClosed.left_ankle_single_closed,
          right_knee_single_closed: resultsSingleLegEyesClosed.right_knee_single_closed,
          left_knee_single_closed: resultsSingleLegEyesClosed.left_knee_single_closed,
          pelvis_single_closed: resultsSingleLegEyesClosed.pelvis_single_closed,
          spine_single_closed: resultsSingleLegEyesClosed.spine_single_closed,
          single_leg_bal_r: 0,
          single_leg_bal_l: 0,
  }

 /**
  * The `submitData` function is an asynchronous function that makes a POST request to an API endpoint
  * with the provided data and handles the response and errors accordingly.
  * @param allResults - The `allResults` parameter is an object that contains the data to be submitted
  * to the API. It is passed as the request body in the POST request to the API endpoint. The structure
  * and content of the `allResults` object will depend on the requirements of the API you are working
  * with.
  */
  const submitData = async (allResults) => {
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/funcmov';
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        // Add any other headers if needed
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
  
      // Make the POST request using Axios
      const response = await axios.post(apiUrl, allResults, { headers });
  
      // Handle the response as needed
      console.log("Response code:", response.status);
      console.log('Response:', response.data);
      setDataAllFunctionalMovements(response.data)
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };


  /**
   * The function `getDataFromDb` makes a series of GET requests to retrieve data from a database,
   * handles the responses, and logs the results.
   * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
   * you want to retrieve data from the database. This ID is used in the API request to fetch the
   * athlete's evaluation data.
   */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/funcmov/';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }


        // Access hasNextPage from the response
        hasNextPage = response.data.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const funcMovEvals = response.data.funcMovEvals;
        lastRecord = funcMovEvals[funcMovEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);

      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.curl_up_reps)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const funcMovEvals = lastPageResponse.data?.funcMovEvals;
      console.log("funcMovEvals:", funcMovEvals)

      if (funcMovEvals && funcMovEvals.length > 0) {
        const previousSessionValues = {
          right_foot_single_closed: funcMovEvals[0].right_foot_single_closed,
          left_foot_single_closed: funcMovEvals[0].left_foot_single_closed,
          right_ankle_single_closed: funcMovEvals[0].right_ankle_single_closed,
          left_ankle_single_closed: funcMovEvals[0].left_ankle_single_closed,
          right_knee_single_closed: funcMovEvals[0].right_knee_single_closed,
          left_knee_single_closed: funcMovEvals[0].left_knee_single_closed,
          pelvis_single_closed: funcMovEvals[0].pelvis_single_closed,
          spine_single_closed: funcMovEvals[0].spine_single_closed,
        };
        setDataSingleLegEyesClosed(previousSessionValues);
        console.log(previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };



  /* The above code is a useEffect hook in JavaScript. It is used to fetch data from a database
  (presumably using the `getDataFromDb` function) when a component mounts. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `saveData` saves the `resultsSingleLegEyesClosed` data to AsyncStorage and updates
   * the `dataSingleLegEyesClosed` state variable.
   */
  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('single-leg-eyes-closed', JSON.stringify(resultsSingleLegEyesClosed));
      console.log('Before setting data:', resultsSingleLegEyesClosed);
      setDataSingleLegEyesClosed(resultsSingleLegEyesClosed);
      console.log('After setting data:', dataSingleLegEyesClosed);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };



    return (
         <SafeAreaView style={styles.container}>
            <ScrollView>
                <View style={styles.containerView}>
                {/* Athlete Name and Test Title */}
                    <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                    <Text style={styles.textStyleFirstRow}>Functional Movement - Single Leg Eyes Closed</Text>
                    <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>
                    {/* Side and Test Movements */}
                    <View style={styles.rowContainer}>
                        <Text style={{fontFamily:'Roboto', fontSize:'large', marginRight:50}}>Right Foot</Text>
                        <Text style={styles.textStyle}>Left Foot</Text>
                    </View>
                    <View style={styles.rowContainer}>
                        <FeetDropdown
                          value={right_foot_single_closed}
                          onChange={(value) => {
                            setright_foot_single_closed(value)
                            }}
                        />
                        <FeetDropdown
                          value={left_foot_single_closed}
                          onChange={(value) => {
                            setleft_foot_single_closed(value)
                            }}
                        />
                    </View>
                    <View style={styles.rowContainer}>
                        <Text style={{fontFamily:'Roboto', fontSize:'large', marginRight:50}}>Right Ankle</Text>
                        <Text style={styles.textStyle}>Left Ankle</Text>
                    </View>
                    <View style={styles.rowContainer}>
                        <AnkleDropdown
                          value={right_ankle_single_closed}
                          onChange={(value) => {
                            setright_ankle_single_closed(value)
                            }}
                        />
                        <AnkleDropdown
                          value={left_ankle_single_closed}
                          onChange={(value) => {
                            setleft_ankle_single_closed(value)
                            }}
                        />
                    </View>
                    <View style={styles.rowContainer}>
                        <Text style={{fontFamily:'Roboto', fontSize:'large', marginRight:50}}>Right Knee</Text>
                        <Text style={styles.textStyle}>Left Knee</Text>
                    </View>
                    <View style={styles.rowContainer}>
                        <KneeDropdown
                          value={right_knee_single_closed}
                          onChange={(value) => {
                            setright_knee_single_closed(value)
                            }}
                        />
                        <KneeDropdown
                          value={left_knee_single_closed}
                          onChange={(value) => {
                            setleft_knee_single_closed(value)
                            }}
                        />
                    </View>
                    <View style={styles.columnContainer}>
                        <Text style={styles.textStyle}>Pelvis</Text>
                        <PelvisDropdown
                          value={pelvis_single_closed}
                          onChange={(value) => {
                            setpelvis_single_closed(value)
                            }}
                        />
                    </View>
                    <View style={styles.columnContainer}>
                        <Text style={styles.textStyle}>Spine</Text>
                        <SpineDropdown
                          value={spine_single_closed}
                          onChange={(value) => {
                            setspine_single_closed(value)
                            }}
                        />
                    </View>
                    
                </View>

                <DataTable  style={styles.table}>
                        <DataTable.Header>
                          <DataTable.Title style={{justifyContent:'center'}}>Single Leg Eyes Closed</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Header>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Right Movement</DataTable.Title>
                            <DataTable.Title>Left Movement</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row>
                            <DataTable.Cell>Foot</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.right_foot_single_closed}</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.left_foot_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Ankle</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.right_ankle_single_closed}</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.left_ankle_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Knee</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.right_knee_single_closed}</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.left_knee_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Title>Body Part</DataTable.Title>
                            <DataTable.Title>Movement</DataTable.Title>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Pelvis</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.pelvis_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Spine</DataTable.Cell>
                            <DataTable.Cell>{dataSingleLegEyesClosed.spine_single_closed}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
               
                <View style={styles.rowContainer}>
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Single Leg Eyes Open', NervousSystemSingleLegEyesOpen)}
                        style={styles.button}
                    />
                    <Button
                        title="Continue and save"
                        color="#729982"
                        onPress={ async () => {
                          
                          for (let key in resultsSingleLegEyesClosed) {
                            if (resultsSingleLegEyesClosed.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsSingleLegEyesClosed[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsSingleLegEyesClosed).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(!flagForNullValues){
                            console.log("There are missing values")
                            
                          }
                          setDataSingleLegEyesClosed(resultsSingleLegEyesClosed);
                          await saveData(); // Wait for saveData to complete before proceeding

                          // Assuming submitData is an asynchronous function, you might want to call it here
                          // and navigate only after it has completed (if it's necessary)
                          try {
                            await setDataAllFunctionalMovements(finalResults);
                            await submitData(finalResults);
                            navigation.navigate('Threshold Test - Warm Up', ThresholdFormWarmUp);
                          } catch (error) {
                            console.error('Error submitting data:', error);
                          }
                          // navigation.navigate('Threshold Test - Warm Up', ThresholdFormWarmUp)
                          

                          
                        }}
                        style={styles.button}
                    />
                    <Button
                        title="Continue without saving"
                        color="#989170"
                        onPress={() => navigation.navigate('Threshold Test - Warm Up', ThresholdFormWarmUp)}
                        style={styles.button}
                    />
                     {/* Custom modals for web */}
                   {Platform.OS === 'web' && (
                                <>
                                  <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                                </>
                              )}
                </View>
            </ScrollView>
         </SafeAreaView>
    );
};



const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      // padding: 16,
      width:'100%',
      backgroundColor:'white',
    },
    containerView:{
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: 16,
      // backgroundColor: 'gray',
      width:'100%',
    },
    button: {
      flex: 0.2,
      alignSelf: 'flex-end',
      marginEnd: 50,
      borderBottomEndRadius:10,
    },
    title: {
      marginVertical: 25,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize:30,
    },
    rowContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between', 
    //   marginVertical: 20,
      marginHorizontal: 85,
      alignItems:'center',
    //   backgroundColor:'dodgerblue',
    },
    rowContainerDownButtons: {
      flex: 2,
      flexDirection: 'row',
      justifyContent: 'space-between', 
    //   marginVertical: 15,
    //   marginHorizontal: 15,
      alignItems:'center',
    //   backgroundColor:'pink',
    },
    rowContainerHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between', 
      marginVertical: 20,
      marginHorizontal: 70,
      alignItems:'center',
      // backgroundColor:'pink',
    },
    columnContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginVertical: 10,
      marginHorizontal: 20,
      // backgroundColor:'green'
    },
    textStyleFirstRow: {
      flex: 1,
      // flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'Roboto',
      marginHorizontal: 30,
      marginVertical: 15,
      fontSize: 20,
    },
    textStyle: {
      flex: 1,
      // flexDirection: 'row',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      marginHorizontal: 40,
      marginVertical: 5,
      fontSize:'large',
      alignItems:'center',
    //   backgroundColor:'green',
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      backgroundColor: '#e8e2d0',
      padding: 20,
      borderRadius: 10,
      alignItems: 'center',
      width: 300, // Adjust the width as needed
    },
    modalText: {
      fontSize: 18,
      marginBottom: 10,
      fontFamily: 'Roboto',
      justifyContent:'center'
    },
    modalButton: {
      fontSize: 16,
      color: 'black',
      marginTop: 10,
  
    },
  });
  
  export default NervousSystemSingleLegEyesClosed;