import React, { useState } from 'react'
import { TouchableOpacity, StyleSheet, View, KeyboardAvoidingView, Pressable } from 'react-native'
import useTrainerStore from '../varHelpers/trainerStore'
import { Text } from 'react-native-paper'
import LoginButton from '../components/LoginButton'
import LoginInput from '../components/LoginInput'
import { theme } from '../core/theme'
import { emailValidator } from '../varHelpers/emailValidator'
import { passwordValidator } from '../varHelpers/passwordValidator'
import { Alert } from 'react-native'
import { clearAllData } from '../App'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../firebase'; // Adjust the path accordingly
import { useEffect } from 'react'
// import { createUserWithEmailAndPassword } from 'firebase/auth';
import AccountErrorModal from '../components/AccountErrorModal'

export default function LoginScreen({ navigation }) {
  const currentUser = useTrainerStore((state) => state.currentUser);
  const setCurrentUser = useTrainerStore((state) => state.setCurrentUser);
  
  const [email, setEmail] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })
  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [compareID, setCompareID] = useState('')

  const trainer = useTrainerStore((state) => state.trainer);
  const setTrainer = useTrainerStore((state) => state.setTrainer);

  useEffect(() => {
    const handleAuthStateChanged = async (user) => {
      if (user) {
        // Set compareID in the state
        setTrainer(currentUser[0]);
        setCompareID(user.uid);
  
        // Check if compareID is truthy before making the search request
        if (user.uid && !currentUser.user_id) {
          await handleCompare(); // Wait for handleCompare to finish
          console.log(currentUser);
          if (currentUser && Object.keys(currentUser).length !== 0) {
            navigation.replace('Landing Page');
          }
        }
      }
    };
  
    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChanged);
  
    return unsubscribe;
  }, [currentUser]);
  

  const handleLogIn = () => {
    signInWithEmailAndPassword(auth, email.value, password.value)
      .then(userCredentials => {
        const user = userCredentials.user;
        // console.log('Logged in with:', user.uid);
        setCompareID(user.uid);
        handleCompare();
      })
      .catch(error => {
        setErrorMessage("Credential Error");
        // console.log('Error 1');
        // console.log(error.message);
        setVisible(true);
      });
  };
  
  const handleCompare = async () => {
    const options = {
      headers: {
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      },
    };
  
    const baseUrl = `https://gamereadyperformanceapp.com/api/trainers`;
  
    try {
      const apiUrl = `${baseUrl}?user_id=${compareID}`;
      // console.log('API URL:', apiUrl);
  
      const response = await fetch(apiUrl, options);
  
      if (response.ok) {
        const data = await response.json();
        // console.log('Data from API:', data);
  
        // Update currentUser with the trainer from the result
        setCurrentUser(data.trainers); 
        // console.log('Current User:', currentUser);
      } else {
        // console.log('No data found in response');
        setErrorMessage('Trainer profile not found');
        setVisible(true);
      }
    } catch (error) {
      // console.error('Error:', error.message);
      setErrorMessage('Error');
      // console.log('Error 2');
      setVisible(true);
    }
  };

  const resetPassword = (email) => {
    // if(email.error != ""){
      // setErrorMessage("Invalid Email");
      // setVisible(true);
    // }
    // else{
      sendPasswordResetEmail(auth, email)
      .then(() => {
        // console.log('Password reset email sent!');
        // Handle success - maybe update the UI to show a confirmation message
        setErrorMessage("If you have a registered email with this service, an email with a link to reset your password will arrive in your inbox");
        setVisible(true);
      })
      .catch(error => {
        setErrorMessage("Error encountered, check if email is valid");
        setVisible(true);
        // Handle errors - show error message to the user
      });
  
  };  

  return (
    <KeyboardAvoidingView style={styles.container}>
      <LoginInput
        label="Email"
        returnKeyType="next"
        value={email.value}
        onChangeText={(text) => setEmail({ value: text, error: '' })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />
      <LoginInput
        label="Password"
        returnKeyType="done"
        value={password.value}
        onChangeText={(text) => setPassword({ value: text, error: '' })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
      />
      <View style={styles.forgotPassword}>
        <Pressable
          onPress={() => resetPassword(email.value)}
        >
          <Text style={styles.forgot}>Reset Password</Text>
        </Pressable>
      </View>
      <LoginButton mode="contained" onPress={handleLogIn}>
        Login
      </LoginButton>
      {/* <LoginButton mode="contained" onPress={handleCompare}>
        Compare Test
      </LoginButton> */}
      {/* <LoginButton mode="contained" onPress={handleSignUp}>
        RegisterTEST
      </LoginButton> */}
      <AccountErrorModal 
        text={errorMessage} 
        visible={visible} 
        onClose={() => setVisible(false)}
      />
    </KeyboardAvoidingView>
  )
    }

const styles = StyleSheet.create({
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  container: {
    flex: 1,
    padding: 20,
    width: '100%',
    maxWidth: 340,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  forgot: {
    fontSize: 13,
    color: theme.colors.secondary,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
})
