import React, {useEffect, useState} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform} from 'react-native';
import { DataTable, TextInput } from 'react-native-paper';
import { useData } from '../DataContext';
import {saveAs} from 'file-saver';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';
import { formatISODate, formatDOB } from '../varHelpers/formatDOB';
import { timeStampValidator, dateValidator } from '../varHelpers/dateValidator';
import OutOfRangeModal from '../components/OutofRangeModal';

const SprintCapacityAndThresholdReport = ({navigation}) => {
  const { athlete, setAthlete } = useAthleteStore();
  // Used to get the array object from another screen
  const [requestError, setRequestError] = useState('');
  const [maxHR, setmaxHR] = useState('');
  const [athleteActuals, setAthleteActuals] = useState({});
  const [athleteGoals, setAthleteGoals] = useState({})
  const [report_id, setreport_id] = useState('');
  const [hour, setHour] = useState('');
  const [minutes, setMinutes] = useState('');

  const [showOutOfRange, setShowOutOfRange] = useState(false);
  


  const {dataSprintCapacityRun} = useData();
  const {dataThresholdRun} = useData();
  const {dataUpperBodyForm, dataNervousSystemForms, dataAllFunctionalMovements, dataLowerBodyForms, dataVERTJump,
  setDataThresholdRun, setDataSprintCapacityRun} = useData();

  const [date, setdate] = useState({ value: '', error: '' });



  useEffect(() => {
    console.log('Before formatting:', date.value);

    const formattedDate = formatDOB(date.value);
    console.log('After formatting:', formattedDate);

    // Update the state with the formatted date
    setdate({ value: formattedDate, error: '' });

    // Perform the error check
    const error = dateValidator(formattedDate);
    setdate((prev) => ({ ...prev, error }));

    console.log('Error:', error);
  }, [date.value]);




  const iDsforReport = {
    athlete_id: athlete.id,
    timestamp: date.value+"T"+hour+":"+minutes,
    nerv_sys_eval_id: dataNervousSystemForms.id,
    func_mov_eval_id: dataAllFunctionalMovements.id,
    upper_rom_eval_id: dataUpperBodyForm.id,
    lower_rom_eval_id: dataLowerBodyForms.id,
    vert_jump_eval_id: dataVERTJump.id,
    sprint_cap_eval_id: dataSprintCapacityRun.id,
    threshold_eval_id: dataThresholdRun.id
  };

  const getThreshold = async (athlete_id) => {
    // let currentPage = 0;
    // let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/threshold';
      const parameters = `?athlete_id=${athlete_id}`;

      console.log("Athlete ID:", athlete_id)
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        
      };
      // console.log('API KEY:', process.env.API_KEY)

      // Continue making requests as long as there is a next page
        // Make the GET request using fecth
          const response = await fetch(`${apiUrl}?athlete_id=${athlete_id}`, {
            method: 'GET',
            headers: headers,
          });
  
          const responseData = await response.json()

          let thresholdEvals = responseData.thresholdEvals;
          lastRecord = thresholdEvals[thresholdEvals.length - 1].id;
          console.log(`${lastRecord}: ${typeof lastRecord}`);
        

  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}`;

      // console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await fetch(lastPageUrl, {
        method: 'GET',
        headers: headers,
      });

      const lastPageResponseData = await lastPageResponse.json()
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponseData);
      // console.log("Last Page Response code:", lastPageResponseData[0].status);
      // console.log('Last Page Response:', lastPageResponseData[0]);
      // console.log("Initial Speed: ", lastPageResponseData[0].initial_speed);

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      thresholdEvals = lastPageResponseData;
      console.log("thresholdEvals:", thresholdEvals)

      if (thresholdEvals && thresholdEvals.thresholdEvals.length > 0 ) {
        const previousSessionValues = {
          initial_speed: thresholdEvals.thresholdEvals[0].initial_speed,
          PRE: thresholdEvals.thresholdEvals[0].PRE,
          max_hr: thresholdEvals.thresholdEvals[0].max_hr,
          max_hr_speed: thresholdEvals.thresholdEvals[0].max_hr_speed,
          aerobic_hr: thresholdEvals.thresholdEvals[0].aerobic_hr,
          anerobic_hr: thresholdEvals.thresholdEvals[0].anerobic_hr,
          lactic_hr: thresholdEvals.thresholdEvals[0].lactic_hr,
          aerobic_speed: thresholdEvals.thresholdEvals[0].aerobic_speed,
          anerobic_speed: thresholdEvals.thresholdEvals[0].anerobic_speed,
          lactic_speed: thresholdEvals.thresholdEvals[0].lactic_speed
        };
        setAthleteActuals(previousSessionValues);
        // console.log("Response to send:", athleteActuals);
        setDataThresholdRun(previousSessionValues);
        console.log("Previous Session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };
  const getSprintRun = async (athlete_id) => {
    // let currentPage = 0;
    // let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/sprint';
      const parameters = `?athlete_id=${athlete_id}`;

      console.log("Athlete ID:", athlete_id)
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        
      };
      // console.log('API KEY:', process.env.API_KEY)

      // Continue making requests as long as there is a next page
        // Make the GET request using Axios
        const response = await fetch(`${apiUrl}?athlete_id=${athlete_id}&test_type=${"sprint"}&eval_type=${"eval"}`, {
          method: 'GET',
          headers: headers,
        });

        console.log("The response:", response)


        const responseData = await response.json()
        console.log("response data;", responseData)

        let sprintEvals = responseData.sprintEvals;
        console.log("Sprint Evals:", sprintEvals)
        lastRecord = sprintEvals[sprintEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10&test_type=${"sprint"}`;

      // console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await fetch(lastPageUrl, {
        method: 'GET',
        headers: headers,
      });

      const lastPageResponseData = await lastPageResponse.json()
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponseData);
      // console.log("Last Page Response code:", lastPageResponseData[0].status);
      // console.log('Last Page Response:', lastPageResponseData[0]);
      // console.log("Initial Speed: ", lastPageResponseData[0].speed);

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      sprintEvals = lastPageResponseData;
      console.log("sprintEvals:", sprintEvals)

      if (sprintEvals) {
        const previousSessionValues = {
          speed: sprintEvals.sprintEvals[0].speed,
          PRE: sprintEvals.sprintEvals[0].PRE,
          hr_max: sprintEvals.sprintEvals[0].hr_max,
          hr_max_time: sprintEvals.sprintEvals[0].hr_max_time,
          target_hr: sprintEvals.sprintEvals[0].target_hr,
          target_hr_time: sprintEvals.sprintEvals[0].target_hr_time,
          ROR: sprintEvals.sprintEvals[0].ROR,
        };
        setmaxHR(previousSessionValues.hr_max);
        setDataSprintCapacityRun(previousSessionValues);
        console.log("PRevious Session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  const generateReport = async (athlete_id) => {
    try {
      const apiUrl = `https://gamereadyperformanceapp.com/api/athletes/${athlete_id}/generatereport`;
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(iDsforReport),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Report generated:", responseData);
      console.log("Report ID:", responseData.id);
      generateCSV(responseData.id)
      // return responseData.id
    } catch (error) {
      setRequestError(true)
      console.error('Error generating report:', error.message);
    }
  };

  const getGoals = async (athlete_id) => {
    try {
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athlete/goals';

      console.log("Athlete ID:", athlete_id)
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
      const req = `${apiUrl}?athleteId=${athlete_id}`;
      console.log("Goals Request:",req)

      // Continue making requests as long as there is a next page
        // Make the GET request using Axios
        const response = await fetch(req, {
          method: 'GET',
          headers: headers,
        });

        console.log("Response before ok:", response)

        if (!response.ok) {
          console.error('Non-ok response:', response);
          // Retrieve the HTML error content
          const errorHtml = await response.text();
          console.error('Error HTML:', errorHtml);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }


        // const responseData = await response.json()
        const goals = await response.text();
        console.log("Goals", goals);
        // console.log("Goals Aerobic Base Range:", goals[0].AerobicBase)
        // console.log("Aerobic Base Range:", goals.categories.AerobicBase)
        const parsedGoals = JSON.parse(goals);
        setAthleteGoals(parsedGoals);
        setDataThresholdRun(parsedGoals);
        // console.log("Goals Aerobic Base:", parsedGoals.categories.AerobicBase.range);
        
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  const generateCSV = async (report_id) => {
    try {
      const apiUrl = `https://gamereadyperformanceapp.com/api/athletes/reports/${report_id}/csv`;
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: headers,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log("Excel Response:", response)
      const csv = await response.text()
      console.log("Excel data:", csv);
      downloadCSV(csv)
      // const responseData = await response.json();
      // console.log("Report generated:", responseData);
      // console.log("Report ID:", responseData.id);
    } catch (error) {
      setRequestError(true);
      console.error('Error generating report CSV:', error.message);
    }
  };


  const downloadCSV = async (csv) => {
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'assessment_data.csv';

    document.body.appendChild(link);
    // link.click();
    saveAs(blob, 'assessment_data.csv');
    document.body.removeChild(link);
    
  };

  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;
        // console.log("Athlete ID = ", athlete_id)
        // Call your data fetching function here
        await getThreshold(athlete_id);
        await getGoals(athlete_id);
        await getSprintRun(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts
    return (
        <SafeAreaView style={styles.container}>
            <ScrollView contentContainerstyle={styles.containerView}>
            {/* Name and title of the report */}
                <View style={{justifyContent:'center'}}>
                    <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                    <Text style={styles.subTitle}>Sprint Capacity and Threshold Test Report</Text>
                    <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Report')}
                        title="Go to : Reports Section"
                        color="#989170">Reports Section</Button>

                    <Text style={styles.textStyleFirstRow}>Date</Text>
                    <TextInput
                      label="YYYY-MM-DD"
                      returnKeyType="next"
                      style={{backgroundColor: "#e8e2d0"}}
                      value={date.value}
                      onChangeText={(text) => setdate({ value: text, error: '' })}
                      error={!!date.error}
                      errorText={date.error}
                      maxLength={10}
                      keyboardType="numbers-and-punctuation"
                    />
                    <Text style={styles.textStyleFirstRow}>Hour</Text>
                    <TextInput
                      placeholder="Hour(XX) 24 hr"
                      style={{backgroundColor: "#e8e2d0"}}
                      value={hour}
                      onChangeText={(text) => {
                      // Check for valid input format
                      if (/^\d{0,2}$/.test(text)) {
                        const intValue = parseInt(text, 10);

                        // Check for valid hour range
                        if (text === "" || (intValue >= 0 && intValue <= 23)) {
                          setHour(text);
                        } else {
                          if (Platform.OS === 'web') {
                            // Handle out of range for web platform
                            setShowOutOfRange(true)
                          } else {
                            Alert.alert('Invalid input', 'Please enter a valid number within the range of 00 to 23.');
                          }
                          console.log('Invalid input. Please enter a valid number within the range of 00 to 23.');
                        }
                      } else {
                        if (Platform.OS === 'web') {
                          // Handle invalid format for web platform
                          setShowOutOfRange(true)
                        } else {
                          Alert.alert('Invalid input', 'Please enter a valid number.');
                        }
                        console.log('Invalid input. Please enter a valid number.');
                      }
                    }}
                    />
                    <Text style={styles.textStyleFirstRow}>Minutes</Text>
                    <TextInput
                      placeholder="Minutes(XX)"
                      style={{backgroundColor: "#e8e2d0"}}
                      value={minutes}
                      onChangeText={(text) => {
                      // Check for valid input format
                      if (/^\d{0,2}$/.test(text)) {
                        const intValue = parseInt(text, 10);

                        // Check for valid hour range
                        if (text === "" || (intValue >= 0 && intValue <= 59)) {
                          setMinutes(text);
                        } else {
                          if (Platform.OS === 'web') {
                            // Handle out of range for web platform
                            setShowOutOfRange(true)
                          } else {
                            Alert.alert('Invalid input', 'Please enter a valid number within the range of 00 to 23.');
                          }
                          console.log('Invalid input. Please enter a valid number within the range of 00 to 23.');
                        }
                      } else {
                        if (Platform.OS === 'web') {
                          // Handle invalid format for web platform
                          setShowOutOfRange(true)
                        } else {
                          Alert.alert('Invalid input', 'Please enter a valid number.');
                        }
                        console.log('Invalid input. Please enter a valid number.');
                      }
                    }}
                    />
                </View>
                <View>
                <DataTable style={styles.table}>
                        <DataTable.Header>
                          <DataTable.Title>Threshold Test</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Header>
                            <DataTable.Title style={{ justifyContent: 'center' }}>Energy System</DataTable.Title>
                            <DataTable.Title>Actual</DataTable.Title>
                            <DataTable.Title>Goals</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row style={{justifyContent:'space-evenly'}}>
                            <DataTable.Cell style={{ width: 200, flex:2}}>Energy System</DataTable.Cell>
                            <DataTable.Cell>Speed</DataTable.Cell>
                            <DataTable.Cell style={{ width: 200}}>Heart Rate</DataTable.Cell>
                            <DataTable.Cell>PRE</DataTable.Cell>
                            <DataTable.Cell>Speed</DataTable.Cell>
                            <DataTable.Cell style={{ width: 200 }}>Heart Rate</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={{ width: '100%', flex:2 }}>Aerobic Speed - Base</DataTable.Cell>
                            <DataTable.Cell>{parseFloat(athleteActuals.aerobic_speed).toFixed(1)}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.aerobic_hr}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.PRE}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AerobicBase?.range}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AerobicBase?.heartRate}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={{ width: '100%', flex:2 }}>Anaerobic Speed - Threshold</DataTable.Cell>
                            <DataTable.Cell>{parseFloat(athleteActuals.anerobic_speed).toFixed(1)}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.anerobic_hr}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.PRE}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AnaerobicBase?.range}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AnaerobicBase?.heartRate}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={{ width: 200, flex:2 }}>Lactic Speed</DataTable.Cell>
                            <DataTable.Cell>{parseFloat(athleteActuals.lactic_speed).toFixed(1)}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.lactic_hr}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.PRE}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.Lactate?.range}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.Lactate?.heartRate}</DataTable.Cell>
                        </DataTable.Row>
                        </DataTable>

                        <DataTable style={styles.table}>
                          <DataTable.Header>
                            <DataTable.Title style={{ justifyContent: 'center' }}>Sprint Capacity</DataTable.Title>
                          </DataTable.Header>
                          <DataTable.Row>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>Max Speed</DataTable.Cell>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>{dataSprintCapacityRun.speed}</DataTable.Cell>
                          </DataTable.Row>
                          <DataTable.Row>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>Max Heart Rate</DataTable.Cell>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>{maxHR}</DataTable.Cell>
                          </DataTable.Row>
                          <DataTable.Row>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>PRE</DataTable.Cell>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>{dataSprintCapacityRun.PRE}</DataTable.Cell>
                          </DataTable.Row>
                          <DataTable.Row>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>Rate of Recovery</DataTable.Cell>
                            <DataTable.Cell style={{ justifyContent: 'space-evenly' }}>{dataSprintCapacityRun.ROR}</DataTable.Cell>
                          </DataTable.Row>
                        </DataTable>
               
                </View>
                {/* Custom modals for web */}
              {Platform.OS === 'web' && (
                                <>
                                  <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                                </>
                              )}
              
              {/* Buttons to move to another pages */}
                <View style={styles.rowContainerDownButtons}>
                    <Button
                    title="Previous Page"
                    color="#989170"
                    onPress={() => navigation.navigate('Report - Nervous Assessment: Functional Movements')}
                    />
                    <Button
                    title="Download CSV File"
                    color="#729982"
                    onPress={async () => {
                      const report =await generateReport(athlete.id);
                      console.log("Report ID to send:", report);
                      console.log("Downloading CSV Report File")}}
                    />
                    {/* <Button
                    title="Continue - Energy System Test"
                    color="#989170"
                    onPress={() => {navigation.navigate('Energy System Development Form')}}
                    /> */}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};



const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    containerView: {
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    button: {
      flex: 0.2,
      alignSelf: 'flex-end',
      marginEnd: 50,
      borderBottomEndRadius: 10,
    },
    title: {
      marginVertical: 25,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: 30,
      alignItems: 'center',
      justifyContent:'center',
    },
    subTitle: {
      marginVertical: 25,
      fontWeight: 'regular',
      fontFamily: 'Roboto',
      fontSize:30,
    },
    rowContainerHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 20,
      marginHorizontal: 70,
      alignItems: 'center',
    },
    input: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
    },
    table: {
      borderWidth: 1,
      borderColor: 'black',
      width: '100%',
    },
    textStyleFirstRow: {
      flex: 1,
      justifyContent: 'center',
      fontFamily: 'Roboto',
      marginHorizontal: 30,
      marginVertical: 15,
      fontSize: 20,
    },
  });

export default SprintCapacityAndThresholdReport;