// DataContext.js
import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [dataUpperBodyForm, setDataUpperBodyForm] = useState('');
  const [dataLowerBodyAnkleForm, setDataLowerBodyAnkleForm] = useState('')
  const [dataLowerBodyHipForm, setDataLowerBodyHipForm] = useState('')
  const [dataFeetNervousSystemForm, setDataFeetNervousSystemForm] = useState('')
  const [dataAnkleNervousSystemForm, setDataAnkleNervousSystemForm] = useState('')
  const [dataHipExtensionNervousSystemForm, setDataHipExtensionNervousSystemForm] = useState('')
  const [dataHipNervousSystemForm, setDataHipNervousSystemForm] = useState('')
  const [dataHipFlexionNervousSystemForm, setDataHipFlexionNervousSystemForm] = useState('')
  const [dataFunctionalMovements, setDataFunctionalMovements] = useState('')
  const [dataOverheadSquat, setDataOverheadSquat] = useState('')
  const [dataSingleLegSquat, setDataSingleLegSquat] = useState('')
  const [dataSingleLegEyesOpen, setDataSingleLegEyesOpen] = useState('')
  const [dataSingleLegEyesClosed, setDataSingleLegEyesClosed] = useState('')
  const [dataVERTJump, setDataVERTJump] = useState('')
  const [dataSprintCapacityWarmUp, setDataSprintCapacityWarmUp] = useState('')
  const [dataSprintCapacityRun, setDataSprintCapacityRun] = useState('')
  const [dataThresholdWarmUp, setDataThresholdWarmUp] = useState('')
  const [dataThresholdRun, setDataThresholdRun] = useState('')
  const [dataAllFunctionalMovements, setDataAllFunctionalMovements] = useState('');
  const [dataLowerBodyForms, setDataLowerBodyForms] = useState('');
  const [dataNervousSystemForms, setDataNervousSystemForms] = useState('');



  const [dataAthleteProfile, setDataAthleteProfile] = useState('')
  const [dataAthleteLogin, setDataAthleteLogin] = useState('')


  return (
    <DataContext.Provider value={{ 
        dataUpperBodyForm, setDataUpperBodyForm, 
        dataLowerBodyAnkleForm, setDataLowerBodyAnkleForm,
        dataLowerBodyHipForm, setDataLowerBodyHipForm,
        dataFeetNervousSystemForm, setDataFeetNervousSystemForm,
        dataAnkleNervousSystemForm, setDataAnkleNervousSystemForm,
        dataHipExtensionNervousSystemForm, setDataHipExtensionNervousSystemForm,
        dataHipNervousSystemForm, setDataHipNervousSystemForm,
        dataHipFlexionNervousSystemForm, setDataHipFlexionNervousSystemForm,
        dataFunctionalMovements, setDataFunctionalMovements,
        dataOverheadSquat, setDataOverheadSquat,
        dataSingleLegSquat, setDataSingleLegSquat,
        dataSingleLegEyesOpen, setDataSingleLegEyesOpen,
        dataSingleLegEyesClosed, setDataSingleLegEyesClosed,
        dataVERTJump, setDataVERTJump,
        dataSprintCapacityWarmUp, setDataSprintCapacityWarmUp,
        dataSprintCapacityRun, setDataSprintCapacityRun,
        dataThresholdWarmUp, setDataThresholdWarmUp,
        dataAllFunctionalMovements, setDataAllFunctionalMovements,
        dataLowerBodyForms,setDataLowerBodyForms,
        dataNervousSystemForms, setDataNervousSystemForms,
        dataThresholdRun, setDataThresholdRun,
        dataAthleteProfile, setDataAthleteProfile,
        dataAthleteLogin, setDataAthleteLogin   }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
