import React, {useState, useEffect} from 'react';
import { Button, SafeAreaView, StyleSheet, ScrollView, Text, View } from 'react-native';
import TextWithInput from '../components/TextWithInput';
import GenderDropdown from '../components/GenderDropdown';
import IncompleteModal from '../components/IncompleteModal';
import ErrorModal from '../components/ErrorModal';
import LoginInput from '../components/LoginInput';
import { emailValidator } from '../varHelpers/emailValidator'
import { theme } from '../core/theme'
import PaperInput from '../components/PaperInput';
import { numberValidator } from '../varHelpers/numberValidator';
import { phoneValidator } from '../varHelpers/phoneValidator';
import { textLengthValidator } from '../varHelpers/textLengthValidator';
import ShortPaperInput from '../components/ShortPaperInput';
import LocationDropdown from '../components/TrainerLocationDropDown';
import SwitchAssistance from '../components/Switch';
import { passwordValidator } from '../varHelpers/passwordValidator';
import AccountErrorModal from '../components/AccountErrorModal';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import useTrainerStore from '../varHelpers/trainerStore';
import useTrainerListStore from '../varHelpers/trainerListStore';
import { addressLine2Validator } from '../varHelpers/addressLine2Validator';
import ConfirmModal from '../components/ConfirmModal';
import RoleDropdown from '../components/RoleDropdown';
import { zipCodeValidator } from '../varHelpers/zipCodeValidator';

const TrainerCreate = ({navigation}) => {

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [userIDFire, setUserIDFire] = useState('')

  const trainer = useTrainerStore((state) => state.trainer);
  const setTrainer = useTrainerStore((state) => state.setTrainer);
  const setTrainerList = useTrainerListStore((state) => state.setTrainerList);

  // Check if switches are enabled or not
  // const [isEnabledInversionL, setIsEnabledInversionL] = useState(false);

  // Functions to change switch 
  // const toggleSwitchInversionL = () => setIsEnabledInversionL((previousState) => !previousState);

  // Variables and setters to manage the value of the switch
  // const [quadDominanceInversionL, setQuadDominanceInversionL] = useState(0);

  const [password, setPassword] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = passwordValidator(password.value, 256);
    setPassword((prev) => ({ ...prev, error }));
  }, [password.value]);

  
  const [location, setLocation] = useState('');
  const [role, setRole] = useState('');

  const [firstName, setFirstName] = useState({ value: '', error: '' });

  // Update firstNameTest error based on length using useEffect
  useEffect(() => {
    const error = textLengthValidator(firstName.value, 50);
    setFirstName((prev) => ({ ...prev, error }));
  }, [firstName.value]);
  
  const [lastName, setLastName] = useState({ value: '', error: '' });

  // Update firstNameTest error based on length using useEffect
  useEffect(() => {
    const error = textLengthValidator(lastName.value, 50);
    setLastName((prev) => ({ ...prev, error }));
  }, [lastName.value]);

  // Address Line 1
  const [addressLine1, setAddressLine1] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(addressLine1.value, 256);
    setAddressLine1((prev) => ({ ...prev, error }));
  }, [addressLine1.value]);

  // Address Line 2
  const [addressLine2, setAddressLine2] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = addressLine2Validator(addressLine2.value, 256);
    setAddressLine2((prev) => ({ ...prev, error }));
  }, [addressLine2.value]);

  // City
  const [city, setCity] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(city.value, 256);
    setCity((prev) => ({ ...prev, error }));
  }, [city.value]);

  // State
  const [stateUS, setStateUS] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(stateUS.value, 256);
    setStateUS((prev) => ({ ...prev, error }));
  }, [stateUS.value]);

  // Zip Code
  const [zipCode, setZipCode] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = zipCodeValidator(zipCode.value);
    setZipCode((prev) => ({ ...prev, error }));
  }, [zipCode.value]);


  // Emergency COntact
  const [ECName, setECName] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(ECName.value, 256);
    setECName((prev) => ({ ...prev, error }));
  }, [ECName.value]);

  const [ECPhone, setECPhone] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = phoneValidator(ECPhone.value);
    setECPhone((prev) => ({ ...prev, error }));
  }, [ECPhone.value]);

  //phone
  const [phone, setPhone] = useState({ value: '', error: '' });
    
  useEffect(() => {
    const error = phoneValidator(phone.value);
    setPhone((prev) => ({ ...prev, error }));
  }, [phone.value]);

  //variables and error checks
  const [email, setEmail] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = emailValidator(email.value, 256);
    setEmail((prev) => ({ ...prev, error }));
  }, [email.value]);

  //Modal
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [showError, setShowError] = useState(false);
  
  useEffect(() => {
    console.log('User ID (Inside useEffect):', userIDFire);

    if (userIDFire !== '') {
      postTrainer();
    }
  }, [userIDFire]);

  const handleSignUp = async (onSuccess) => {
    try {
      const userCredentials = await createUserWithEmailAndPassword(auth, email.value, password.value);
      const user = userCredentials.user;
      // console.log("User UID (Before setting):", user.uid);
      // console.log("User UID (After setting):", userIDFire);

      setUserIDFire(user.uid);

      // console.log("User UID (After setting):", user.uid);
      // console.log("User UID (After setting):", userIDFire);
      
      onSuccess(); // Call the callback function
    } catch (error) {
      setErrorMessage(error.message);
      setVisible(true);
    }
  };
  
  const errors = {
    error1: firstName.error,
    error2: lastName.error,
    error3: addressLine1.error, 
    error4: addressLine2.error,
    error5: password.error,
    error6: stateUS.error, 
    error7: zipCode.error, 
    error8:email.error, 
    error10:ECName.error ,
    error11:ECPhone.error,
    errorLoc: location,
  };

  const profileJSON = {
    firstname: firstName.value,
    lastname: lastName.value,
    address1: addressLine1.value,
    address2: addressLine2.value,
    city: city.value,
    state: stateUS.value,
    zipcode: zipCode.value,
    location: location,
    email: email.value.toLowerCase(),
    phone: phone.value.toString(),
    emergency_contact: ECName.value,
    emergency_contact_phone: ECPhone.value,
    user_id: userIDFire,
    role: role,
    // pass: 'password'
  };

  const logInputValues = async () => {
  
    const isEmpty = (
      firstName.value =='' ||
      lastName.value == '' ||
      addressLine1.value == '' ||
      // addressLine2.value == '' ||
      city.value === '' ||
      stateUS.value == '' ||
      zipCode.value == '' ||
      password.value== '' ||
      location == '' ||
      email.value == '' ||
      phone.value === '' ||
      ECName.value == '' ||
      ECPhone.value == ''
    );

    const noError = (
      firstName.error === '' &&
      lastName.error === '' &&
      addressLine1.error === '' &&
      addressLine2.error === '' &&
      city.error === '' &&
      stateUS.error === '' &&
      zipCode.error === '' &&
      // location === '' &&
      email.error === '' &&
      phone.error === '' &&
      ECName.error === '' &&
      ECPhone.error === ''
    );

    if(isEmpty) {
      setShowIncomplete(true);
      // console.log(errors);
      return;
    }
    else if(!noError){
      // console.log(errors);
      setShowError(true);
      return;
    } else {
      handleSignUp(() => {
        // console.log("UID VAR:", userIDFire);
      });
    }
  };

  const postTrainer = async () => {
    const apiUrl = 'https://gamereadyperformanceapp.com/api/trainers';
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        // 'api-key': process.env.API_KEY,
      },
      body: JSON.stringify(profileJSON),
    };
  
    try {
      const response = await fetch(apiUrl, options);
  
      if (response.ok) {
        const data = await response.json();
        console.log('Trainer successfully added:', data);
        setTrainerList([]);
        setTrainer({});
        navigation.navigate("Trainer Search")
      } else {
        setVisible(true);
        setErrorMessage('Failed to add athlete.');
        return
      }
    } catch (error) {
      setVisible(true);
      setErrorMessage('Error encountered');
      return
    }
  };

  return (
    <SafeAreaView>
    <ScrollView>
      <View style={styles.container}>
        <View>
          <Text style={styles.labelCat}>Trainer Profile Create</Text>
        </View>
        <Text style={styles.labelCat}>Name</Text>
        {/* Trainer Name */}
        <View style={styles.rowContainer}>
          <View>
            <PaperInput
              label="First and Middle Name"
              returnKeyType="next"
              value={firstName.value}
              onChangeText={(text) => setFirstName({ value: text, error: '' })}
              error={!!firstName.error}
              errorText={firstName.error}
              maxLength={50}
            />
          </View>
          <View>
            <PaperInput
              label="Last Name"
              returnKeyType="next"
              value={lastName.value}
              onChangeText={(text) => setLastName({ value: text, error: '' })}
              error={!!lastName.error}
              errorText={lastName.error}
              maxLength={50}
            />
          </View>
        </View>
        <View>
         <PaperInput
            label="Trainer Phone Number"
            returnKeyType="next"
            value={phone.value}
            onChangeText={(text) => setPhone({ value: text, error: '' })}
            error={!!phone.error}
            errorText={phone.error}
            keyboardType="number-pad"
            maxLength={20}
          />
        </View>
        {/* Trainer Address */}
        <Text style={styles.labelCat}>Address</Text>
        <View style={styles.rowContainer}>
          <PaperInput
            label="Address Line 1"
            returnKeyType="next"
            value={addressLine1.value}
            onChangeText={(text) => setAddressLine1({ value: text, error: '' })}
            error={!!addressLine1.error}
            errorText={addressLine1.error}
            maxLength={256}
          />
          <PaperInput
            label="Address Line 2"
            returnKeyType="next"
            value={addressLine2.value}
            onChangeText={(text) => setAddressLine2({ value: text, error: '' })}
            error={!!addressLine2.error}
            errorText={addressLine2.error}
            maxLength={256}
          />
        </View>
        <View style={styles.rowContainerShort}>
         <ShortPaperInput
            label="City"
            returnKeyType="next"
            value={city.value}
            onChangeText={(text) => setCity({ value: text, error: '' })}
            error={!!city.error}
            errorText={city.error}
            maxLength={256}
          />
          <ShortPaperInput
            label="State"
            returnKeyType="next"
            value={stateUS.value}
            onChangeText={(text) => setStateUS({ value: text, error: '' })}
            error={!!stateUS.error}
            errorText={stateUS.error}
            maxLength={256}
          />
          <ShortPaperInput
            label="Zip Code"
            returnKeyType="next"
            value={zipCode.value}
            onChangeText={(text) => setZipCode({ value: text, error: '' })}
            error={!!zipCode.error}
            errorText={zipCode.error}
            maxLength={15}
            keyboardType="number-pad"
          />
        </View>
        {/* Trainer Contact Info */}
        <Text style={styles.labelCat}>Emergency Contact</Text>
        <View>
          <PaperInput
            label="Full Name"
            returnKeyType="next"
            value={ECName.value}
            onChangeText={(text) => setECName({ value: text, error: '' })}
            error={!!ECName.error}
            errorText={ECName.error}
            maxLength={256}
          />  
          <PaperInput
            label="Phone Number"
            returnKeyType="next"
            value={ECPhone.value}
            onChangeText={(text) => setECPhone({ value: text, error: '' })}
            error={!!ECPhone.error}
            errorText={ECPhone.error}
            keyboardType="number-pad"
            maxLength={20}
          />
        </View>
        <Text style={styles.labelCat}>Create Account</Text>
        <View >
          <LoginInput
            label="Email"
            returnKeyType="next"
            value={email.value}
            onChangeText={(text) => setEmail({ value: text, error: '' })}
            error={!!email.error}
            errorText={email.error}
            autoCapitalize="none"
            autoCompleteType="email"
            textContentType="emailAddress"
            keyboardType="email-address"
          />
          <LoginInput
            label="Password"
            returnKeyType="done"
            value={password.value}
            onChangeText={(text) => setPassword({ value: text, error: '' })}
            error={!!password.error}
            errorText={password.error}
            secureTextEntry
          />
        </View>
        <View>
          <LocationDropdown 
            value={location}
            onChange={(value) => setLocation(value)}
          />
          <View style={styles.switchContainer}>
            <Text style={styles.labelSwitch}>Role</Text>
            <RoleDropdown 
              value={role}
              onChange={(value) => setRole(value)}
            />
          </View>
          {/* If on, role = admin, else role = trainer */}
        </View>
          <View style={styles.rowContainer}>
            <Button 
              title='Cancel'
              onPress={() => navigation.goBack()}
            />
            <Button 
              title='Create Profile'
              onPress={() => {
                logInputValues();
              }}
            />
          </View>
        </View>
        <IncompleteModal visible={showIncomplete} onClose={() => setShowIncomplete(false)}/>
        <ErrorModal visible = {showError} onClose = {() => setShowError(false)}/>
        <AccountErrorModal 
          text={errorMessage} 
          visible={visible} 
          onClose={() => setVisible(false)}
        />
        {/* <ConfirmModal 
        
        /> */}
      <View/>
    </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    // marginHorizontal: 100,
    marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'100%',
    // backgroundColor: 'gray'
  },
  containerInfo: {
    // marginHorizontal: 100,
    marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'70%',
    // backgroundColor: 'gray'
  },
  switchContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    // backgroundColor: 'gray'
  },
  profile: {
    flex: 7,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:"red"
  },
  labelInput: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowContainerShort: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
  //  backgroundColor: "red",
    width:'45%'
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
  //  backgroundColor: "red",
    width:'80%'
  },
  rowContainerName: {
    flexDirection: 'row',
   backgroundColor: "yellow"
  },
  labelCat: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginVertical: 15
  },
  labelSwitch: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 20,
  },
  inputText: {
      padding: 3,
  }
});

export default TrainerCreate;