import React, {useEffect, useState} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform } from 'react-native';
import { DataTable, Divider } from 'react-native-paper';
import Switch from '../components/Switch';
import DegreesTextInput from '../components/DegreeTextInput';
import InchesTextInput from '../components/InchesTextInput';
// import NervousSystemLowerBodyAnkle from './NSFormLowerBodyAnkle';
import NervousSystemSingleLegSquat from './NSFormSingleLegSquat';
import NervousSystemLowerBodyAnkle from './NSReportLowerBodyAnkle';
import NervousSystemReportHipFlexion from './NSReportHipFlexion';
import NervousSystemVERTJump from './NSFormVERTJump';
import { useData } from '../DataContext';
import axios from 'axios';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';


const NervousSystemReportUpperBody = ({ route, navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();
  // Used to get the array object from another screen
  const { dataAthleteProfile, dataUpperBodyForm, setDataUpperBodyForm} = useData();

  const [requestError, setRequestError] = useState('');

  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/upperrom/';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }


        // Access hasNextPage from the response
        hasNextPage = response.data.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const upperROMEvals = response.data.upperROMEvals;
        lastRecord = upperROMEvals[upperROMEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);
  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const upperROMEvals = lastPageResponse.data?.upperROMEvals;
      console.log("upperROMEvals:", upperROMEvals)

      if (upperROMEvals && upperROMEvals.length > 0) {
        const previousSessionValues = {
          post_r: upperROMEvals[0].post_r,
          post_l: upperROMEvals[0].post_l,
          ant_r: upperROMEvals[0].ant_r,
          ant_l: upperROMEvals[0].ant_l,
          int_rot_r: upperROMEvals[0].int_rot_r,
          int_rot_l: upperROMEvals[0].int_rot_l,
          ext_rot_r: upperROMEvals[0].ext_rot_r,
          ext_rot_l: upperROMEvals[0].ext_rot_l,
        };
        setDataUpperBodyForm(previousSessionValues);
        console.log(previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404) {
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            {/* Athlete's name and test report title  */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Report - Mobility Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Report')}
                        title="Go to : Reports Section"
                        color="#989170">Reports Section</Button>


                {/* Report table */}
                <View style={{marginBottom: 50, width: '100%'}}>
                  <DataTable style={styles.table}>
                      <DataTable.Header>
                        <DataTable.Title style={{justifyContent:'center', flex:2}} >Inches of Rotation Accomplished per side</DataTable.Title>
                      </DataTable.Header>
                      <DataTable.Row style={{justifyContent:'center'}}>
                        <DataTable.Cell style={{flex: 1.8, marginRight:20}}>
                          <Text>
                            Range of Motion{'\n'}Upper Body
                          </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Right Side{'\n'}20 inches
                            </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Left Side{'\n'}20 inches
                            </Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell style={{flex: 1.8}}>Post Shoulder</DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.post_r}
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.post_l}
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell style={{flex: 1.8}}>Ant Shoulder</DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.ant_r}
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                          {dataUpperBodyForm.ant_l}
                        </DataTable.Cell>
                      </DataTable.Row>
                    </DataTable>
                  </View>

                  <View style={{marginBottom: 50, width:'100%'}}>
                  <DataTable style={styles.table}>
                    <DataTable.Header>
                      <DataTable.Title style={{justifyContent:'center', flex:2}} >Degrees of Rotation Accomplished per side</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                        <DataTable.Cell style={{flex: 2, marginRight:20}}>
                          <Text>
                            Range of Motion{'\n'}Upper Body
                          </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.5, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Right Side{'\n'}90 degrees
                            </Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 1.5, marginRight:20, justifyContent:'center'}}>
                          <Text>
                            Left Side{'\n'}90 degrees
                            </Text>
                        </DataTable.Cell>
                      </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>Internal Rotation Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.int_rot_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.int_rot_l}
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex: 1.8}}>External Rotation Shoulder</DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.ext_rot_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 1.8, justifyContent:'center'}}>
                        {dataUpperBodyForm.ext_rot_l}
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>


              <View style={styles.rowContainerDownButtons}>
              {/* Buttons to navigate among screens */}
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - VERT Jump', NervousSystemVERTJump)}
                        style={styles.button}
                    />
                    <Button
                        title="Next Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Report - Nervous Assessment System: Lower Body Ankle',NervousSystemLowerBodyAnkle)}
                        style={styles.button}
                    />
              </View>  
              {/* Custom modals for web */}
              {Platform.OS === 'web' && (
                                <>
                                  <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                                </>
                              )}
          </View>
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
    flex:2
  },
});

export default NervousSystemReportUpperBody;
