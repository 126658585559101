export function dateValidator(input) {
    // Regular expression for YYYY-MM-DD format
    const re = /^\d{4}-\d{2}-\d{2}$/;
  
    if (!input) return "Input can't be empty.";
    if (!re.test(input) && input.length == 10) return 'Input should be in the format YYYY-MM-DD.';
    return '';
  }

  export function timeStampValidator(input) {
    // Regular expression for YYYY-MM-DDTHH:MM format
    const re = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    
    if (!input) return "Input can't be empty.";
    if (!re.test(input)  && input.length == 13) return 'Input should be in the format YYYY-MM-DDTHH:MM.';
    return '';
  }
  
  