export function zipCodeValidator(zipCode) {
    const zipCodeRegex = /^\d{5}(-\d{4})?$/;
  
    if (!zipCode) {
      return "Zip code can't be empty.";
    }
  
    if (!zipCodeRegex.test(zipCode)) {
      return 'Invalid zip code format. Use a valid US zip code.';
    }
  
    return '';
  }
  