import React, {useState, useEffect} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Alert, Platform, TouchableOpacity, Modal } from 'react-native';
import { DataTable, TextInput } from 'react-native-paper';
import SwitchAssistance from '../components/Switch';
import NervousSystemFormHipFlexion from './NSFormHipFlexion';
import NervousSystemOverheadSquat from './NSFormOverheadSquat';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAthleteStore from '../varHelpers/athleteStore';
import MultilinePaperInput from '../components/MultilinePaperInput';
import axios from 'axios';
import RequestErrorModal from '../components/RequestErrorModal';


const NervousSystemFunctionalMovements = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();

  const [showOutOfRange, setShowOutOfRange] = useState(false);
  const [requestError, setRequestError] = useState(false);

  // Test passing parameters to other screens and initial values
  const [curl_up_reps, setcurl_up_reps] = useState('');
  const [push_up_reps, setpush_up_reps] = useState('');
  const [curl_up_assisted, setcurl_up_assisted] = useState(0);
  const [push_up_assisted, setpush_up_assisted] = useState(0);
  const [curl_up_reason, setcurl_up_reason] = useState('');
  const [push_up_reason, setpush_up_reason] = useState('');
  
  // Check if switches are enabled or not
  const [isEnabledcurl_up_reps, setIsEnabledcurl_up_reps] = useState(false);
  const [isEnabledpush_up_reps, setIsEnabledpush_up_reps] = useState(false);

  // Functions to change switch 
  const toggleSwitchcurl_up_reps = () => setIsEnabledcurl_up_reps((previousState) => !previousState);
  const toggleSwitchpush_up_reps = () => setIsEnabledpush_up_reps((previousState) => !previousState);

  // // Array object where the results will be saved (testing)
  const resultsFunctionalMovements = {
    curl_up_reps: curl_up_reps,
    push_up_reps: push_up_reps,
    curl_up_assisted: curl_up_assisted,
    push_up_assisted: push_up_assisted,
    curl_up_reason: curl_up_reason,
    push_up_reason: push_up_reason
  };
  // Used to save the array object and pass it to another screen
  const { dataAthleteProfile, dataFunctionalMovements,setDataFunctionalMovements} = useData();
  const [data, setData] = useState('');

  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/funcmov';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }


        // Access hasNextPage from the response
        hasNextPage = response.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const funcMovEvals = response.data.funcMovEvals;
        console.log("Length:", funcMovEvals.length)
        lastRecord = funcMovEvals[funcMovEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);
        

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);

  
      // Extract the total count and last page from the response headers
      // const totalCount = parseInt(firstPageResponse.headers['x-total-count'], 10);
      // console.log('Total Count:', totalCount);
      // const lastPage = Math.ceil(totalCount / 10); // Assuming a limit of 10 records per page
      // console.log('Last Page:', lastPage);
  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.curl_up_reps)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const funcMovEvals = lastPageResponse.data?.funcMovEvals;
      console.log("funcMovEvals:", funcMovEvals)

      if (funcMovEvals && funcMovEvals.length > 0) {
        const previousSessionValues = {
          curl_up_reps: funcMovEvals[0].curl_up_reps,
          curl_up_assisted: funcMovEvals[0].curl_up_assisted,
          curl_up_reason: funcMovEvals[0].curl_up_reason,
          push_up_reps: funcMovEvals[0].push_up_reps,
          push_up_assisted: funcMovEvals[0].push_up_assisted,
          push_up_reason: funcMovEvals[0].push_up_reason,
        };
        setDataFunctionalMovements(previousSessionValues);
        console.log(previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true)
        }
        
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts


  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('functional-movements', JSON.stringify(resultsFunctionalMovements));
      setData(resultsFunctionalMovements);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };
    
  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            {/* Athlete's nsame and test's title */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Form - Functional Movements</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>
                {/* <View style={{marginBottom: 50}}> */}
                {/* Form table */}
                    <DataTable style={styles.table}>
                      <DataTable.Header >
                        <DataTable.Title style={{justifyContent:'center'}}>Functional Movements</DataTable.Title>
                        <DataTable.Title style={{justifyContent:'center'}}>Repetitions</DataTable.Title>
                        <DataTable.Title style={{justifyContent:'center'}}>Assisted</DataTable.Title>
                      </DataTable.Header>
                      <DataTable.Row>
                        <DataTable.Cell style={{justifyContent:'center'}}>Curl Up</DataTable.Cell>
                        <DataTable.Cell style={{justifyContent:'center'}}>
                        <TextInput
                                placeholder="Number of Repetitions"
                                style={styles.textInputStyle} 
                                value={curl_up_reps}
                                onChangeText={(text) => {
                                const intValue = parseInt(text);
                                if (text === "" || (!isNaN(intValue) && intValue >= 0)) {
                                      setcurl_up_reps(text === "" ? "" : intValue);
                                  
                                } else {
                                  if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                                  console.log("Invalid input. Please enter a number greater than zero.");
                                }
                              }}
                              />
                        </DataTable.Cell>
                        <DataTable.Cell style={{justifyContent:'center'}}>
                          <SwitchAssistance isEnabled={isEnabledcurl_up_reps} onValueChange={(isEnabled, curl_up_assisted) => {
                                
                                console.log("Toggle value: ", isEnabledcurl_up_reps)
                                if (isEnabled) {
                                  curl_up_assisted = 1;
                                }else{
                                  curl_up_assisted = 0;
                                }
                                toggleSwitchcurl_up_reps(); 
                                setcurl_up_assisted(curl_up_assisted);
                            }} />
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell style={{justifyContent:'center'}} >Push Up</DataTable.Cell>
                        <DataTable.Cell style={{justifyContent:'center'}}>
                        <TextInput
                               placeholder="Number of Repetitions"
                                style={styles.textInputStyle} 
                                value={push_up_reps} 
                                onChangeText={(text) => {
                                const intValue = parseInt(text);
                                if (text === "" || (!isNaN(intValue) && intValue >= 0)) {
                                      setpush_up_reps(text === "" ? "" : intValue);                                 
                                } else {
                                  if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                                    console.log("Invalid input. Please enter a number greater than zero.");
                                }
                              }}
                              />
                        </DataTable.Cell>
                        <DataTable.Cell style={{justifyContent:'center'}}>
                          <SwitchAssistance isEnabled={isEnabledpush_up_reps} onValueChange={(isEnabled, push_up_assisted) => {
                                
                                if (isEnabled) {
                                  push_up_assisted = 1;
                                }else{
                                  push_up_assisted = 0;
                                }
                                toggleSwitchpush_up_reps();
                                setpush_up_assisted(push_up_assisted);
                            }} />
                          </DataTable.Cell>
                      </DataTable.Row>
                    </DataTable>

                    <View>
                      <Text style={{justifyContent:'center', fontFamily:'Roboto', fontSize:'medium'}}>Comments</Text>
                      <MultilinePaperInput
                        label="Comments Curl Up"
                        returnKeyType="next"
                        value={curl_up_reason}
                        onChangeText={(text) => setcurl_up_reason(text)}
                        maxLength={1000}
                      />
                      <MultilinePaperInput
                        label="Comments Push Up"
                        returnKeyType="next"
                        value={push_up_reason}
                        onChangeText={(text) => setpush_up_reason(text)}
                        maxLength={1000}
                      />
                    </View>
                {/* </View> */}

                {/* Report Table of Functional Movements */}
                <DataTable style={styles.table}>
                    <DataTable.Header>
                    <DataTable.Title>Previous Session</DataTable.Title>
                      <DataTable.Title><Text>Functional{'\n'}Movements</Text></DataTable.Title>
                      <DataTable.Title><Text>Number{'\n'}of Repetitions</Text></DataTable.Title>
                      <DataTable.Title>Assisted</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                      <DataTable.Cell>Curl Up</DataTable.Cell>
                      <DataTable.Cell>{dataFunctionalMovements.curl_up_reps}</DataTable.Cell>
                      <DataTable.Cell>{dataFunctionalMovements.curl_up_assisted !== undefined ? dataFunctionalMovements.curl_up_assisted : 0}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>Push Up</DataTable.Cell>
                      <DataTable.Cell>{dataFunctionalMovements.push_up_reps}</DataTable.Cell>
                      <DataTable.Cell>{dataFunctionalMovements.push_up_assisted !== undefined ? dataFunctionalMovements.push_up_assisted : 0}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>Comment Curl Up</DataTable.Cell>
                      <DataTable.Cell>{dataFunctionalMovements.curl_up_reason}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>Comment Push Up</DataTable.Cell>
                      <DataTable.Cell>{dataFunctionalMovements.push_up_reason}</DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>

                {/* Buttons to move across form screens */}
               <View style={styles.rowContainerDownButtons}>
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Hip Flexion', NervousSystemFormHipFlexion)}
                        style={styles.button}
                    />
                    <Button
                        title="Continue and save"
                        color="#729982"
                        onPress={() => {
                          
                          for (let key in resultsFunctionalMovements) {
                            if (resultsFunctionalMovements.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsFunctionalMovements[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsFunctionalMovements).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(flagForNullValues){
                            console.log("There are missing values")        
                          }
                          console.log(resultsFunctionalMovements)
                          setDataFunctionalMovements(resultsFunctionalMovements)
                          saveData();
                          navigation.navigate('Nervous System Assessment Form - Functional Movements Overhead Squat', NervousSystemOverheadSquat)
                          
                        }}
                        style={styles.button}
                    />
                    <Button
                        title="Continue without saving"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Overhead Squat', NervousSystemOverheadSquat)}
                        style={styles.button}
                    />
                    {/* Custom modals for web */}
                    {Platform.OS === 'web' && (
                            <>
                              <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                            </>
                          )}
              </View>    
            
            </View>
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'center', 
    marginVertical: 20,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
  textInputStyle: {
    backgroundColor:"#e8e2d0", 
    color:"black", 
    borderBlockEndColor:'black',
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
    flex:20,
    marginBottom:20,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#e8e2d0',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: 300, // Adjust the width as needed
  },
  modalText: {
    fontSize: 18,
    marginBottom: 10,
    fontFamily: 'Roboto',
    justifyContent:'center'
  },
  modalButton: {
    fontSize: 16,
    color: 'black',
    marginTop: 10,

  },
});

export default NervousSystemFunctionalMovements;
