import React, { useState, useEffect } from 'react';
import { Button, FlatList, Pressable, SafeAreaView, StyleSheet, Text, TextInput, View } from 'react-native';
import useAthleteStore from '../varHelpers/athleteStore';
import useAthleteListStore from '../varHelpers/athleteListStore';
import BackButton from '../components/BackButton';
import CreateButton from '../components/CreateButton';
import { AuthManager } from '../AuthManager';
import AccountErrorModal from '../components/AccountErrorModal';

const AthleteSearch = ({ navigation }) => {

  const authManager = new AuthManager();

  const [firstNameSearch, setFirstNameSearch] = useState('');
  const [lastNameSearch, setLastNameSearch] = useState('');
  const [dobSearch, setDobSearch] = useState('');
  const [searchPressed, setSearchPressed] = useState(false);

  const athleteJSON = useAthleteStore((state) => state.athlete);
  const setAthleteJSON = useAthleteStore((state) => state.setAthlete);

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // const athleteList = useAthleteListStore((state) => state.athleteList);
  // const setAthleteList = useAthleteListStore((state) => state.setAthleteList);
  const [athleteList, setAthleteList] = useState([]);

  
  function removeAfterT(inputString) {
    // Match 'T' and anything after that
    const regex = /T.*/;
    return inputString.replace(regex, '');
  }

  function removeAfterT(inputString) {
    // Match 'T' and anything after that
    const regex = /T.*/;
    return inputString.replace(regex, '');
  }

  const handleSearch = async () => {
    const options = {
      headers: {
        // 'api-key': process.env.API_KEY,
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      },
    };
  
    const baseUrl = 'https://gamereadyperformanceapp.com/api/athletes';
  
    try {
      let currpage = 1;
      let hasNextPage = true;
      let athleteList = [];
  
      while (hasNextPage) {
        const apiUrl = `${baseUrl}?firstName=${firstNameSearch}&lastName=${lastNameSearch}&dob=${dobSearch}&page=${currpage}`;
  
        const response = await fetch(apiUrl, options);
  
        if (response.ok) {
          const data = await response.json();
          const newAthletes = data.athletes;
  
          // Update athleteList with the athletes from the current page
          athleteList = [...athleteList, ...newAthletes];
  
          // Check for the next page
          hasNextPage = data.hasNextPage;
          currpage++;
        } else {
          setErrorMessage('No trainers found');
          setVisible(true);
          // console.log('No data found in response');
          break;
        }
      }
  
      setAthleteList(athleteList);
      setSearchPressed(true);
      // console.log(athleteList);
    } catch (error) {
      setErrorMessage('Error');
      setVisible(true);
      // console.error('Error:', error.message);
    }
  };
  
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.leftSide}>
        <BackButton/>
      </View>
      <View style={styles.rightSide}>
        <CreateButton labeltext="Create Athlete" onPress={() => navigation.navigate('Athlete Create')} />
      </View>
      {/* <Text>My Variable: {athleteJSON.id}</Text> */}
      <View style={styles.rowContainerHeader}>
        <Text style={styles.label}>Athlete Search:</Text>
        <TextInput
          style={styles.inputText}
          placeholder="First Name"
          value={firstNameSearch}
          onChangeText={(text) => setFirstNameSearch(text)}
          maxLength={25}
        />
        <TextInput
          style={styles.inputText}
          placeholder="Last Name"
          value={lastNameSearch}
          onChangeText={(text) => setLastNameSearch(text)}
          maxLength={25}
        />
        <TextInput
          style={styles.dateInput}
          placeholder="DoB(YYYY-MM-DD)"
          value={dobSearch}
          onChangeText={(text) => setDobSearch(text)}
          maxLength={10}
        />
        <View style={styles.searchButton}>
          <CreateButton labeltext="Search" 
          onPress={() => {
            // authManager.authenticate();
            // console.log(authManager.getAuthHeaders());
            // console.log(authManager.logCurrentToken());
            handleSearch();
            }} />
        </View>
      </View>
      {searchPressed ? (
        <View style={styles.flatListContainer}>
          <FlatList
            data={athleteList}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => (
              <Pressable
                onPress={() => {
                  setAthleteJSON(item);
                  console.log(athleteJSON);
                  setAthleteList([]);
                  console.log(athleteList);
                  navigation.navigate('Athlete Profile', { userId: item.id });
                }}
              >
                <Text style={styles.itemStyle}>
                  {item.id}. {item.firstname} {item.lastname} | {removeAfterT(item.dob)} | {item.sport}
                </Text>
              </Pressable>
            )}
          />
        </View>
      ) : (
        <View style={styles.pendingMessage}>
          <Text style={styles.pendingMessageText}>To load athlete list, press the search button. Use search filters to narrow down the list of athletes</Text>
        </View>
      )}
      <AccountErrorModal 
        text={errorMessage} 
        visible={visible} 
        onClose={() => setVisible(false)}
      />
    </SafeAreaView>
  );
};


const styles = StyleSheet.create({
  createButton: {
    marginTop: 10,
  },
  rowContainerHeader: {     
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 20,
    marginHorizontal: 16,
    alignItems: 'center',
  },
  labelCat: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  flatListContainer: {
    flex: 1,
    width: '70%',
    justifyContent:'center',
    borderColor: 'gray',
    borderWidth: 2,
    borderRadius: 5,
  },
  pendingMessage: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderColor: '#e8e2d0',
    borderWidth: 1,
  },
  pendingMessageText: {
    alignItems: 'center',
    justifyContent:'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  itemStyle: {
    paddingVertical: 15,  // Increase the top and bottom padding
    paddingHorizontal: 10,  // Maintain the left and right padding
    fontSize: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8c8',
    backgroundColor: '#e8e2d0',
    borderRadius: 10,
    marginVertical: 4,  // Increase the vertical margin
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftSide: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 10,
    zIndex: 1,
  },
  rightSide: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10,
    zIndex: 1,
  },
  label: {
    height: 20,
    padding: 3,
    marginRight: 10,
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 18,
  },
  inputText: {
    padding: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    fontSize: 16,
    width:  '15%',
  },
  dateInput: {
    padding: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    fontSize: 16,
    width: ' 20%', // Adjust the width as needed
  },
  searchButton: {
    padding: 5,
    marginHorizontal: 5
  }
});

export default AthleteSearch;
