import React, { useState, useEffect } from 'react';
import { Button, SafeAreaView, StyleSheet, ScrollView, Text, View, Pressable } from 'react-native';
import NervousSystemUpperBody from './NSFormUpperBody';
import SprintCapacityWarmUp from './SprintCapacityWarmUp';
import EnergySystemDevelopmentForm from './ESDevelopmentForm';
import { useData } from '../DataContext';
import useStore from '../varHelpers/AthleteProfileTrack';
import MaterialTextComponent from '../components/MaterialTextComponent';
import useAthleteStore from '../varHelpers/athleteStore';
import Divider from '../components/Divider';
import BackButton from '../components/BackButton';
import ConfirmModal from '../components/ConfirmModal';
import useAthleteListStore from '../varHelpers/athleteListStore';
import AccountErrorModal from '../components/AccountErrorModal';

const AthleteProfile = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();
  const setAthleteList = useAthleteListStore();
  const {setDataAthleteProfile} = useData();
  const [showDelete, setShowDelete] = useState(false);

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  function removeAfterT(inputString) {
    // Match 'T' and anything after that
    const regex = /T.*/;
    return inputString.replace(regex, '');
  }

  const deleteAthlete = async (athleteId) => {
    const apiUrl = `https://gamereadyperformanceapp.com/api/athletes/${athleteId}`;
    
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        // 'api-key': process.env.API_KEY,
      },
    };
  
    try {
      const response = await fetch(apiUrl, options);
  
      if (response.ok) {
        console.log(`Athlete with ID ${athleteId} deleted successfully.`);
        setAthlete({});
        setAthleteList([]);
        navigation.navigate("Athlete Search");
      } else {
        setErrorMessage('Failed to delete athlete.');
        setVisible(true);
        // console.log('Failed to delete athlete.');
      }
    } catch (error) {
      setErrorMessage('Failed to delete athlete.');
      setVisible(true);
      // console.error('Error:', error.message);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.leftSide}>
            <BackButton/>
        </View>
      <ScrollView>
        <View style={styles.titleContainer}>
          <Text style={styles.labelTitle}>Athlete Profile Display</Text>
        </View>
        {athlete ? (
          <>
            <View style={styles.titleContainer}>
              <Text style={styles.labelTitle}>{athlete.firstname} {athlete.lastname}</Text>
            </View>
            <Divider/>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Gender" text={athlete.gender}/>
              {athlete.dob && (
                <MaterialTextComponent 
                  title="Date of Birth" 
                  text={removeAfterT(athlete.dob)}
                />
              )}
              <MaterialTextComponent title="Height" text={athlete.heightF+"' "+athlete.heightI+'"'}/>
              <MaterialTextComponent title="Weight (lbs)" text={athlete.weight}/>
            </View>
              <Divider/>
            <Text style={styles.labelCat}>Sport</Text>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Sport" text={athlete.sport}/>
              <MaterialTextComponent title="Club/Organization" text={athlete.club}/>
              <MaterialTextComponent title="Position" text={athlete.position}/>
              <MaterialTextComponent title="Jersey Number" text={athlete.jersey_num}/>
              <MaterialTextComponent title="Pro Athlete?"   text={athlete.isPro === 0 ? 'No' : 'Yes'} />
            </View>
            <Divider/>
            <Text style={styles.labelCat}>Contact Information</Text>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Email" text={athlete.email}/>
              <MaterialTextComponent title="Phone" text={athlete.phone}/>
            </View>
            <Divider/>
            <Text style={styles.labelCat}>Address</Text>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Mailing Address" text={athlete.mailing_address}/>
              <MaterialTextComponent title="Adress Line 1" text={athlete.address1}/>
              <MaterialTextComponent title="Address Line 2" text={athlete.address2}/>
              <MaterialTextComponent title="State" text={athlete.state}/>
              <MaterialTextComponent title="City" text={athlete.city}/>
              <MaterialTextComponent title="Zip Code" text={athlete.zipcode}/>
            </View>
            <Divider/>
            <Text style={styles.labelCat}>Emergency Contact</Text>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Name" text={athlete.emergency_contact}/>
              <MaterialTextComponent title="Phone" text={athlete.emergency_contact_phone}/>
            </View>
            <Divider/>
            <Text style={styles.labelCat}>Training Information</Text>
            <View style={styles.rowContainer}>
              <MaterialTextComponent title="Package" text={athlete.package}/>
              <MaterialTextComponent title="Comments" text={athlete.comments}/>
            </View>
          </>
        ) : (
          <Text style={styles.loadingText}>Loading...</Text>
        )}
        <View style={styles.buttonContainer}>
          <Button
            title='Edit Profile'
            onPress={() => {
              navigation.navigate('Athlete Edit');
            }}
          />
        </View>
        <View style={styles.rowContainer}>
          <Button
            title='Total Body System'
            onPress={() => {
              setDataAthleteProfile(athlete.id)
              navigation.navigate('Table Total Body')
            }}
            />
            <Button
            title='Threshold Test'
            onPress={() => navigation.navigate("Threshold Test - Warm Up")}
          />
          <Button
            title='Sprint Capacity'
            onPress={() => navigation.navigate("Sprint Capacity - Warm Up at 85%")}
          />
          <Button
            title='Report'
            onPress={() => navigation.navigate("Table Report")}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Pressable
            style={{
              backgroundColor: 'red',
              borderRadius: 5,
              padding: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              setShowDelete(true);
            }}
          >
            <Text style={{ color: 'white' }}>DELETE PROFILE</Text>
          </Pressable>
        </View>
        <ConfirmModal
          text={"This will delete this athlete's profile. Are you sure?"}
          visible={showDelete} 
          onCancel={() => setShowDelete(false)}
          onAccept={() => {setShowDelete(false);
            deleteAthlete(athlete.id);
            navigation.navigate("Athlete Search")
          }}
        />
        <AccountErrorModal 
          text={errorMessage} 
          visible={visible} 
          onClose={() => setVisible(false)}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
  },
  titleContainer: {
    alignItems: 'center',
    // marginVertical: 16,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    // marginVertical: 16,
  },
  labelCat: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  labelTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  userDataText: {
    fontSize: 18,
    marginBottom: 4,
  },
  loadingText: {
    fontSize: 18,
    fontStyle: 'italic',
    color: '#888',
    marginBottom: 16,
  },
  buttonContainer: {
    marginVertical: 16,
  },
  leftSide: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 10,
    zIndex: 1,
  },
});

export default AthleteProfile;
