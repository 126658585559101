import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Input } from '@rneui/themed';
import { theme } from '../core/theme';

const TextWithInput = ({ labelText, placeholderText, maxLength, keyboardType, value, onChangeText}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{labelText}</Text>
      <Input
        maxLength={maxLength}
        value={value}
        keyboardType={keyboardType}
        style={styles.input}
        placeholder={placeholderText}
        onChangeText={onChangeText}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
  },
  label: {
    fontSize: 24,
    marginBottom: 10,
    height:20,
    padding: 3,
    marginLeft: 15
  },
  input: {
    padding: 8,
    fontSize: 20,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
});

export default TextWithInput;