import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { TextInput as Input } from 'react-native-paper';
import { theme } from '../core/theme';

export default function MultilinePaperInput({maxLength, ...props }) {
  return (
    <View style={styles.container}>
      <Input
        style={styles.multilineInput}
        selectionColor={theme.colors.primary}
        maxLength={maxLength}
        underlineColor="transparent"
        mode="outlined"
        multiline
        numberOfLines={3}
        {...props}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    marginVertical: 12,
    marginHorizontal: 12,
  },
  multilineInput: {
    height: 100, // Adjust the height as needed
    backgroundColor: theme.colors.surface,
    textAlignVertical: 'top', // Start input from the top
  },
});
