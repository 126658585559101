import React, {useState, useEffect} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform, Alert, Modal, TouchableOpacity } from 'react-native';
import { DataTable, TextInput } from 'react-native-paper';
import NervousSystemLowerBodyAnkle from './NSFormLowerBodyAnkle';
import NervousSystemFormFeet from './NSFormFeet';
import { useData } from '../DataContext';
import OutOfRangeModal from '../components/OutofRangeModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';
import useTrainerStore from '../varHelpers/trainerStore';

const NervousSystemLowerBodyHip = ({ navigation }) => {
  const [showOutOfRange, setShowOutOfRange] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [data, setData] = useState('');

  const { athlete, setAthlete } = useAthleteStore();
  const {currentUser} = useTrainerStore();
  
  // Form variables and setters with initial values 
  const [flexion_r, setflexion_r] = useState('');
  const [flexion_l, setflexion_l] = useState('');
  const [ext_r, setext_r] = useState('');
  const [ext_l, setext_l] = useState('');
  const [abb_r, setabb_r] = useState('');
  const [abb_l, setabb_l] = useState('');
  const [add_r, setadd_r] = useState('');
  const [add_l, setadd_l] = useState('');
  const [int_rot_r, setint_rot_r] = useState('');
  const [int_rot_l, setint_rot_l] = useState('');
  const [ext_rot_r, setext_rot_r] = useState('');
  const [ext_rot_l, setext_rot_l] = useState('')
  
  // Array object where the results will be saved (testing)
  const resultsLowerBodyHip = {
    flexion_r: flexion_r,
    flexion_l: flexion_l,
    ext_r: ext_r,
    ext_l: ext_l,
    abb_r: abb_r,
    abb_l: abb_l,
    add_r: add_r,
    add_l: add_l,
    int_rot_r: int_rot_r,
    int_rot_l: int_rot_l,
    ext_rot_r: ext_rot_r,
    ext_rot_l: ext_rot_l,
  }

  /**
   * The function `getObjectFromAsyncStorage` retrieves an object from AsyncStorage using a given key.
   * @param key - The `key` parameter is a string that represents the key used to retrieve the data
   * from AsyncStorage. AsyncStorage is a key-value storage system provided by React Native for
   * persisting data.
   * @returns The function `getObjectFromAsyncStorage` returns the object value retrieved from
   * AsyncStorage if it exists, or null if no data is found for the given key.
   */
  const getObjectFromAsyncStorage = async (key) => {
    try {
      const jsonString = await AsyncStorage.getItem(key);
  
      if (jsonString !== null) {
        // Parse the JSON string to get the object
        const objectValue = JSON.parse(jsonString);
        return objectValue;
      } else {
        console.log(`No data found for key: ${key}`);
        return null;
      }
    } catch (error) {
      setRequestError(true)
      console.error('Error retrieving data from AsyncStorage:', error);
      return null;
    }
  };

  /**
   * The function `fetchDataAsyncStorage` retrieves data from AsyncStorage, sets the retrieved data to
   * state variables, and then submits the data to a server.
   */
  const fetchDataAsyncStorage = async () => {
    const lowerBodyAnklePromise = getObjectFromAsyncStorage('lower-body-ankle');
    const lowerBodyHipPromise = getObjectFromAsyncStorage('lower-body-hip');
  
    try {
      const storedData = await AsyncStorage.getItem('lower-body-hip');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setflexion_r(parsedData.flexion_r);
        setflexion_l(parsedData.flexion_l);
        setext_r(parsedData.ext_r);
        setext_l(parsedData.ext_l);
        setabb_r(parsedData.abb_r);
        setabb_l(parsedData.abb_l);
        setadd_r(parsedData.add_r);
        setadd_l(parsedData.add_l);
        setint_rot_r(parsedData.int_rot_r);
        setint_rot_l(parsedData.int_rot_l);
        setext_rot_r(parsedData.ext_rot_r);
        setext_rot_l(parsedData.ext_rot_l);
      }
      // Use Promise.all() to wait for all promises to resolve
      const [
        lowerBodyAnkle,
        lowerBodyHip,
      ] = await Promise.all([
        lowerBodyAnklePromise,
        lowerBodyHipPromise,
      ]);
  
      const allResultsGet = {
        lowerBodyAnkle,
        lowerBodyHip,
      };
  
      console.log('All results from AsyncStorage:', allResultsGet);
      console.log("Lower Body Ankle:", allResultsGet.lowerBodyAnkle);
      console.log("Lower Body Ankle Dorsi Right:", allResultsGet.lowerBodyAnkle.dorsi_r);
      console.log("Lower Body Hip:", allResultsGet.lowerBodyAnkle);
      // console.log("Lower Body Hip Abduction Right:", allResultsGet.lowerBodyHip.abb_r);
  
      // Now you can use allResultsGet in your code
      const finalResults = {
        athlete_id: athlete.id,
        trainer_id: currentUser[0].id,
        site_id: 0,
        timestamp: "2023-11-29T22:29:55.807Z",
        "dorsi_r": allResultsGet.lowerBodyAnkle.dorsi_r,
        "dorsi_l": allResultsGet.lowerBodyAnkle.dorsi_l,
        "plantar_r": allResultsGet.lowerBodyAnkle.plantar_r,
        "plantar_l": allResultsGet.lowerBodyAnkle.plantar_l,
        "flexion_r": allResultsGet.lowerBodyHip.flexion_r,
        "flexion_l": allResultsGet.lowerBodyHip.flexion_l,
        "ext_r": allResultsGet.lowerBodyHip.ext_r,
        "ext_l": allResultsGet.lowerBodyHip.ext_l,
        "abb_r": allResultsGet.lowerBodyHip.abb_r,
        "abb_l": allResultsGet.lowerBodyHip.abb_l,
        "add_r": allResultsGet.lowerBodyHip.add_r,
        "add_l": allResultsGet.lowerBodyHip.add_l,
        "int_rot_r": allResultsGet.lowerBodyHip.int_rot_r,
        "int_rot_l": allResultsGet.lowerBodyHip.int_rot_l,
        "ext_rot_r": allResultsGet.lowerBodyHip.ext_rot_r,
        "ext_rot_l": allResultsGet.lowerBodyHip.ext_rot_l,
    }
      
      submitData(finalResults);
    } catch (error) {
      setRequestError(true)
      console.error('Error fetching data:', error);
    }
  };
  
  // Call fetchData somewhere in your code
  // fetchData();

  /**
   * The `submitData` function is an asynchronous function that makes a POST request to an API endpoint
   * with the provided data and handles the response and errors accordingly.
   * @param allResults - The `allResults` parameter is an object that contains the data to be submitted
   * to the API. It is passed as the request body in the POST request to the API endpoint. The
   * structure and content of the `allResults` object will depend on the requirements of the API you
   * are working with.
   */
  const submitData = async (allResults) => {
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/lowerrom';
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        // Add any other headers if needed
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
  
      // Make the POST request using Axios
      const response = await axios.post(apiUrl, allResults, { headers });
  
      // Handle the response as needed
      console.log("Response code:", response.status);
      console.log('Response:', response.data);
      setDataLowerBodyForms(response.data);
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }    
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };


  /**
   * The function `getDataFromDb` makes multiple GET requests to an API, retrieves data from the
   * responses, and handles any errors that occur.
   * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
   * you want to retrieve data from the database. This ID is used in the API request to fetch the
   * athlete's evaluation data.
   */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/lowerrom/';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }


        // Access hasNextPage from the response
        hasNextPage = response.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const lowerROMEvals = response.data.lowerROMEvals;
        lastRecord = lowerROMEvals[lowerROMEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);

  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const lowerROMEvals = lastPageResponse.data?.lowerROMEvals;
      console.log("lowerROMEvals:", lowerROMEvals)

      if (lowerROMEvals && lowerROMEvals.length > 0) {
        const previousSessionValues = {
          flexion_r: lowerROMEvals[0].flexion_r,
          flexion_l: lowerROMEvals[0].flexion_l,
          ext_r: lowerROMEvals[0].ext_r,
          ext_l: lowerROMEvals[0].ext_l,
          abb_r: lowerROMEvals[0].abb_r,
          abb_l: lowerROMEvals[0].abb_l,
          add_r: lowerROMEvals[0].add_r,
          add_l: lowerROMEvals[0].add_l,
          int_rot_r: lowerROMEvals[0].int_rot_r,
          int_rot_l: lowerROMEvals[0].int_rot_l,
          ext_rot_r: lowerROMEvals[0].ext_rot_r,
          ext_rot_l: lowerROMEvals[0].ext_rot_l,
        };
        setDataLowerBodyHipForm(previousSessionValues);
        console.log("Data Lower Hip:", data);
        console.log("Previous session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };
  // Used to save the array object and pass it to another screen (testing)
  const { dataAthleteProfile, dataLowerBodyHipForm,setDataLowerBodyHipForm, setDataLowerBodyForms} = useData();

  /* The above code is a useEffect hook in JavaScript. It is used to fetch data from AsyncStorage when
  the component mounts. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {

        // Call your data fetching function here
        await getDataFromDb(athlete.id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `saveData` saves the `resultsLowerBodyHip` data to AsyncStorage in JSON format and
   * updates the state with the saved data.
   */
  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('lower-body-hip', JSON.stringify(resultsLowerBodyHip));
      setData(resultsLowerBodyHip);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            {/* Athlete Name and Test Title */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Form - Mobility Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>
                {/* Form table */}
                <View style={{marginBottom: 50}}>
                  <DataTable style={styles.table}>
                    <DataTable.Header>
                      <DataTable.Title style={{justifyContent:'center', flex:1}} >Degrees of Rotation Accomplished per side</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row style={{flex:2}}>
                      <DataTable.Cell>
                        <Text>Range of Motion {'\n'} Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Right Side - 90 degrees</DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Left Side - 90 degrees</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>Flexion</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={flexion_r} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                  setflexion_r(text === "" ? "" : intValue);
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 2}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={flexion_l} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                  setflexion_l(text === "" ? "" : intValue);                            
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={{flex:2, marginTop: 50}}>
                      <DataTable.Cell>
                        <Text>Range of Motion {'\n'} Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Right Side - 45 degrees</DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Left Side - 45 degrees</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>Extension</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={ext_r} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 45)) {
                                  setext_r(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 2}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={ext_l} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 45)) {
                                  setext_l(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={{flex:2, marginTop: 50}}>
                      <DataTable.Cell>
                        <Text>Range of Motion {'\n'} Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Right Side - 90 degrees</DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Left Side - 90 degrees</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>Abduction</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                          <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={abb_r} 
                              onChangeText={(text) => {
                              const intValue = parseInt(text);
                              if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                    setabb_r(text === "" ? "" : intValue);                                
                              } else {
                                if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    if(Platform.OS === 'web'){
                                      setShowOutOfRange(true)
                                    }else{
                                      Alert.alert("Missing values")
                                    }
                                    Alert.alert("Missing values")
                                  }
                                console.log("Invalid input. Please enter a valid number within the range.");
                              }
                            }}
                            />
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 2}}>
                          <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={abb_l} 
                              onChangeText={(text) => {
                              const intValue = parseInt(text);
                              if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                    setabb_l(text === "" ? "" : intValue);                              
                              } else {
                                  if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                                console.log("Invalid input. Please enter a valid number within the range.");
                              }
                            }}
                            />
                        </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>Adduction</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                          <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={add_r} 
                              onChangeText={(text) => {
                              const intValue = parseInt(text);
                              if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                    setadd_r(text === "" ? "" : intValue);                                
                              } else {
                                if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                                console.log("Invalid input. Please enter a valid number within the range.");
                              }
                            }}
                            />
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 2}}>
                          <TextInput
                              placeholder="Number of Degrees"
                              style={styles.textInputStyle} 
                              value={add_l} 
                              onChangeText={(text) => {
                              const intValue = parseInt(text);
                              if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                                    setadd_l(text === "" ? "" : intValue);                               
                              } else {
                                if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                                console.log("Invalid input. Please enter a valid number within the range.");
                              }
                            }}
                            />
                        </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={{flex:2, marginTop: 50}}>
                      <DataTable.Cell>
                        <Text>Range of Motion {'\n'} Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Right Side - 45 degrees</DataTable.Cell>
                      <DataTable.Cell style={{justifyContent: 'center'}}>Left Side - 45 degrees</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>Internal Rotation</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={int_rot_r} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 45)) {
                                  setint_rot_r(text === "" ? "" : intValue);                              
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 2}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={int_rot_l} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 45)) {
                                  setint_rot_l(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{flex:2}}>External Rotation</DataTable.Cell>
                      <DataTable.Cell style={{flex: 2, justifyContent:'center'}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={ext_rot_r} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 45)) {
                                  setext_rot_r(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                      <DataTable.Cell style={{flex: 2}}>
                        <TextInput
                            placeholder="Number of Degrees"
                            style={styles.textInputStyle} 
                            value={ext_rot_l} 
                            onChangeText={(text) => {
                            const intValue = parseInt(text);
                            if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 45)) {
                                  setext_rot_l(text === "" ? "" : intValue);
                              
                            } else {
                              if(Platform.OS === 'web'){
                                    setShowOutOfRange(true)
                                  }else{
                                    Alert.alert("Missing values")
                                  }
                              console.log("Invalid input. Please enter a valid number within the range.");
                            }
                          }}
                          />
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>
                
                 {/* Report table */}
                 <DataTable style={{fontFamily: 'Roboto', fontSize:'large'}}>
                    <DataTable.Header>
                      <DataTable.Title style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>
                          Degrees of Rotation Accomplished per side
                        </Text>
                      </DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                      <DataTable.Cell style={{marginRight:50}}>
                        <Text style={styles.textStyleFirstRow}>Range of Motion Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Right Side{'\n'}90 degrees</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Left Side{'\n'}90 degrees</Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell><Text style={styles.textStyleFirstRow}>Flexion</Text></DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.flexion_r}</Text>
                        {/* <Text>90</Text> */}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.flexion_l}</Text>
                         {/* <Text>90</Text> */}
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{marginRight:50}} >
                        <Text style={styles.textStyleFirstRow}>Range of Motion Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Right Side{'\n'}45 degrees</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Left Side{'\n'}45 degrees</Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>
                        <Text style={styles.textStyleFirstRow}>
                          Extension
                        </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.ext_r}</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.ext_l}</Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{marginRight:50}}>
                        <Text style={styles.textStyleFirstRow}>Range of Motion Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Right Side{'\n'}90 degrees</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Left Side{'\n'}90 degrees</Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>
                      <Text style={styles.textStyleFirstRow}>
                        Abduction
                      </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          <Text>{dataLowerBodyHipForm.abb_r}</Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{justifyContent:'center'}}>
                          <Text>{dataLowerBodyHipForm.abb_l}</Text>
                        </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>
                        <Text style={styles.textStyleFirstRow}>
                          Adduction
                        </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          <Text>{dataLowerBodyHipForm.add_r}</Text>
                        </DataTable.Cell>
                        <DataTable.Cell style={{justifyContent:'center'}}>
                          <Text>{dataLowerBodyHipForm.add_r}</Text>
                        </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell style={{marginRight:50}}>
                        <Text style={styles.textStyleFirstRow} >Range of Motion Lower Body Hip</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Right Side{'\n'}45 degrees</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}} >
                        <Text style={styles.textStyleFirstRow}>Left Side{'\n'}45 degrees</Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>
                        <Text style={styles.textStyleFirstRow}>Internal{'\n'}Rotation</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.int_rot_r}</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.int_rot_l}</Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                    <DataTable.Cell>
                      <Text style={styles.textStyleFirstRow}>External{'\n'}Rotation</Text>
                    </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.ext_rot_r}</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>{dataLowerBodyHipForm.ext_rot_l}</Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>       



               
              <View style={styles.rowContainerDownButtons}>
              {/* Buttons to navigate among screens */}
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Range of Motion Lower Body - Ankle', NervousSystemLowerBodyAnkle)}
                        style={styles.button}
                    />
                    <Button
                        title="Continue and save"
                        color="#729982"
                        onPress={() => {
                          
                          for (let key in resultsLowerBodyHip) {
                            if (resultsLowerBodyHip.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsLowerBodyHip[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsLowerBodyHip).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(!flagForNullValues){
                            console.log("There are missing values")
                            
                          }
                          console.log(resultsLowerBodyHip)
                          setDataLowerBodyHipForm(resultsLowerBodyHip);
                          saveData();
                          // Call fetchData somewhere in your code
                          fetchDataAsyncStorage();
                          navigation.navigate('Nervous System Assessment Form - Feet', NervousSystemFormFeet)
                        }}
                        style={styles.button}
                    />
                    <Button
                        title="Continue without saving"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Feet', NervousSystemFormFeet)}
                        style={styles.button}
                    />
                   {/* Custom modals for web */}
                   {Platform.OS === 'web' && (
                                <>
                                  <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                                </>
                              )}
              </View>

                
          </View>
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginRight: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: '',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
  textInputStyle: {
    backgroundColor:"#e8e2d0", 
    color:"black", 
    borderBlockEndColor:'black',
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
    flex:20,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#e8e2d0',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: 300, // Adjust the width as needed
  },
  modalText: {
    fontSize: 18,
    marginBottom: 10,
    fontFamily: 'Roboto',
    justifyContent:'center'
  },
  modalButton: {
    fontSize: 16,
    color: 'black',
    marginTop: 10,

  },
});

export default NervousSystemLowerBodyHip;
