
import NervousSystemReportFeet from './screens/NSReportFeet.js';
import NervousSystemReportAnkle from './screens/NSReportAnkle.js';
import NervousSystemReportHipExtension from './screens/NSReportHipExtension'
import NervousSystemReportHip from './screens/NSReportHip.js';
import NervousSystemReportHipFlexion from './screens/NSReportHipFlexion.js';
import NervousSystemReportUpperBody from './screens/NSReportUpperBody.js';
import NervousSystemReportLowerBodyAnkle from './screens/NSReportLowerBodyAnkle.js';
import NervousSystemReportLowerBodyHip from './screens/NSReportLowerBodyHip.js';

import React, { useEffect } from 'react';
import {View} from 'react-native'
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { DataProvider } from './DataContext.js';

import HomePage from './screens/HomePage.js';
// import LoginPage from './screens/Login.js';
import LandingPage from './screens/LandingPage.js';
import AthleteCreate from './screens/AthleteCreate.js';
import AthleteEdit from './screens/AthleteEdit.js';
import AthleteProfile from './screens/AthleteProfile.js';
import AthleteSearch from './screens/AthleteSearch.js';
import TrainerSearch from './screens/TrainerSearch.js';
import TrainerProfile from './screens/TrainerProfile.js';
import LoginScreen from './screens/LoginTest.js';


import TrainerCreate from './screens/TrainerCreate.js';
import TrainerEdit from './screens/TrainerEdit.js';
import NervousSystemFormAnkle from './screens/NSFormAnkle.js';
import NervousSystemFormFeet from './screens/NSFormFeet.js';
import NervousSystemFormHipExtension from './screens/NSFormHipExtension.js';
import NervousSystemFormHip from './screens/NSFormHip.js';
import NervousSystemFormHipFlexion from './screens/NSFormHipFlexion.js';
import NervousSystemFunctionalMovements from './screens/NSFormFunctionalMovements.js';
import NervousSystemOverheadSquat from './screens/NSFormOverheadSquat.js';
import NervousSystemUpperBody from './screens/NSFormUpperBody.js';
import NervousSystemLowerBodyAnkle from './screens/NSFormLowerBodyAnkle.js';
import NervousSystemLowerBodyHip from './screens/NSFormLowerBodyHip.js';
import NervousSystemVERTJump from './screens/NSFormVERTJump.js';
import NervousSystemSingleLegEyesOpen from './screens/NSFormSingleLegEyesOpen.js';
import NervousSystemSingleLegEyesClosed from './screens/NSFormSingleLegEyesClosed.js';
import NervousSystemSingleLegSquat from './screens/NSFormSingleLegSquat.js';
import SprintCapacityWarmUp from './screens/SprintCapacityWarmUp.js';
import SprintCapacityRun from './screens/SprintCapacityRun.js';
import { Button } from 'react-native-paper';
import NervousSystemReportVERTJump from './screens/NSReportVERTJump.js';
import ThresholdFormRun from './screens/ThresholdFormRun.js';
import ThresholdFormWarmUp from './screens/ThresholdFormWarmUp.js';
import SprintCapacityAndThresholdReport from './screens/SprintCapacityAndThresholdReport.js';

import TableTotalBody from './screens/TableTotalBody.js';

import EnergySystemDevelopmentForm from './screens/ESDevelopmentForm.js';
import NervousSystemReportSquatAndEyes from './screens/NSReportSquatsandEyes.js';
// import dataAthleteLogin from './DataContext.js';
// import { isValid } from './screens/Login.js';
import useTrainerStore from './varHelpers/trainerStore.js';
// import {Provider} from 'zustand';
// import isLoggedIn from './varHelpers/isLoggedIn.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { auth } from './firebase.js';

import SiteInfoEdit from './screens/SiteInfo.js';

import TableReport from './screens/TableReport.js';


const Stack = createStackNavigator();

// Links of the pages because is a web application
const linking = {
  prefixes: ['https://gamereadyperformanceapp.com', 'gamereadyperformanceapp://'],
  config: {
    screens: {
      HomePage: '/Home',
      LoginPage: '/Login',
      LandingPage: '/landing-page',

      AthleteCreate: '/athlete/create',
      AthleteEdit: '/athlete/edit',
      AthleteProfile: '/athlete/profile',
      AthleteSearch: '/athlete/search',

      TrainerCreate: '/trainer/create',
      TrainerEdit: '/trainer/edit',
      TrainerSearch: '/trainer/search',
      
      TableTotalBody: '/total/bosy/table',
      NervousSystemUpperBody: '/nervous-system/form/upper-body',
      NervousSystemLowerBodyAnkle: '/nervous-system/form/lower-body-ankle',
      NervousSystemLowerBodyHip: '/nervous-system/form/lower-body-hip',
      NervousSystemFormFeet: '/nervous-system/form/feet',
      NervousSystemFormAnkle: '/nervous-system/form/ankle',
      NervousSystemFormHipExtension: '/nervous-system/form/hip-extension',
      NervousSystemFormHip: '/nervous-system/form/hip',
      NervousSystemFormHipFlexion: '/nervous-system/form/hip-flexion',
      NervousSystemFunctionalMovements: '/nervous-sysyem/form/functional-movements',
      NervousSystemOverheadSquat: '/nervous-system/form/overhead-squat',
      NervousSystemSingleLegSquat:'/nervous-system/form/single-leg-squat',
      NervousSystemSingleLegEyesOpen:'/nervous-system/form/single-leg-eyes-open',
      NervousSystemSingleLegEyesOpen:'/nervous-system/form/single-leg-eyes-closed',
      ThresholdFormWarmUp: '/threshold/form/warm-up',
      ThresholdFormRun: '/threshold/form/run',
      SprintCapacityWarmUp: '/sprint-capacity/warm-up',
      SprintCapacityRun: '/sprint-capacity/run',
      NervousSystemVERTJump: '/nervous-system/form/vert-jump',

      NervousSystemReportUpperBody: '/nervous-system/report/upper-body',
      NervousSystemReportLowerBodyAnkle: '/nervous-system/report/lower-body-ankle',
      NervousSystemReportLowerBodyHip: '/nervous-system/report/lower-body-hip',
      NervousSystemReportFeet: '/nervous-system/report/feet',
      NervousSystemReportAnkle: '/nervous-system/report/ankle',
      NervousSystemReportHipExtension: '/nervous-system/report/hip-extension',
      NervousSystemReportHip: '/nervous-system/report/hip',
      NervousSystemReportHipFlexion: '/nervous-system/report/hip-flexion',
      NervousSystemReportSquatAndEyes: '/nervous-system/report/squats-eyes',
      NervousSystemReportVERTJump: '/nervous-system/report/vert-jump',
      SprintCapacityAndThresholdReport: '/sprint-capacity/threshold/report',
      EnergySystemDevelopmentForm: '/energy-system-development/form'
    }
  },
};

// const flagLoggedIn = isLoggedIn((state) => state.isLoggedIn);
// const setloggedIn = isLoggedIn((state) => state.setisLoggedIn);

// let loggedOut = false;
// export function getLoggedOut() {
//   return loggedOut;
// }

// export function setLoggedOut(newValue) {
//   loggedOut = newValue;
// }
// export {loggedOut};

const handleSignOut = () => {
  auth
  .signOut()
  .then(() => {

  })
  .catch(error => alert(error.message))
}

const clearAllData = async () => {
  try {
    await AsyncStorage.clear();
    console.log('All data cleared successfully!');
  } catch (error) {
    console.error('Error clearing data:', error);
  }
};

export {clearAllData};

// const renderRightButtons = (navigation) => {
//   // Add flag for logged in later
//   if (isValid) { 
//     return (
//       <View style={{ flexDirection: "row" }}>
//         <Button
//           textColor="black"
//           style={{ backgroundColor: "#729982", marginRight: 10 }}
//           onPress={() => {
//             console.log("Navigating to Profile Screen");
//             navigation.navigate("Athlete Profile");
//           }}
//         >
//           Profile
//         </Button>
//         <Button
//           textColor="black"
//           style={{ backgroundColor: "#989170", marginRight: 10 }}
//           onPress={() => {
//             console.log("Logging out");
//             // setloggedIn(false);
//             clearAllData();
//             navigation.navigate("Login Test");
//           }}
//         >
//           Log Out
//         </Button>
//       </View>
//     );
//   } else {
//     // Return null or any other component if condition is not met
//     return null;
//   }
// };

export default function App() {
  
  const currentUser = useTrainerStore((state) => state.currentUser);
  const setTrainer = useTrainerStore((state) => state.setTrainer);

  return (
    // <Provider store={Store}>
    // It manages the screens and the links
    // <Provider {...isLoggedIn}>
       <NavigationContainer linking={linking}>

            {/*Header Navigator  with Buttons to go to Profile, Home or Log Out*/}

          <DataProvider>
              <Stack.Navigator  initialRouteName='Home Page' component={HomePage}
             screenOptions={({ navigation }) => ({
                headerTitle: "Game Ready Performance App",
                headerTintColor: "black",
                headerTitleAlign: "center",
                headerStyle: { backgroundColor: "#ffe600"},
                headerRight: () => (<View style={{ flexDirection: "row" }}>
                    <Button
                      textColor="black"
                      style={{ backgroundColor: "#729982", marginRight: 10 }}
                      onPress={() => {
                        // setTrainer(currentUser);
                        console.log("Navigating to Profile Screen");
                        navigation.navigate("Trainer Profile");
                      }}
                    >
                      Profile
                    </Button>
                    <Button
                      textColor="black"
                      style={{ backgroundColor: "#989170", marginRight: 10 }}
                      onPress={() => {
                        console.log("Logging out");
                        handleSignOut();
                        clearAllData();
                        navigation.navigate("Home Page");
                      }}
                    >
                      Log Out
                    </Button>
                  </View>),
                headerLeft: () => (<Button
                      textColor='black'
                      style={{ backgroundColor: '#989170' }}
                      onPress={() => {
                        console.log("Home!");
                        navigation.navigate("Landing Page")
                      } }>
                        Home
                      </Button>)})}>

            {/* Profile and main screens */}
            <Stack.Screen options={{headerShown: false}} name="Home Page" component={HomePage}/>
            {/* <Stack.Screen  name="Login Page" component={LoginPage}/> */}
            <Stack.Screen name="Landing Page" component={LandingPage}/>
            <Stack.Screen name="Athlete Search" component={AthleteSearch}/>
            <Stack.Screen name="Athlete Create" component={AthleteCreate}/>
            <Stack.Screen name="Athlete Edit" component={AthleteEdit}/>
            <Stack.Screen name="Athlete Profile" component={AthleteProfile}/>
            <Stack.Screen name="Trainer Search" component={TrainerSearch}/>
            <Stack.Screen name="Trainer Create" component={TrainerCreate}/>
            <Stack.Screen name="Trainer Edit" component={TrainerEdit}/>
            <Stack.Screen name="Trainer Profile" component={TrainerProfile}/>
            <Stack.Screen options={{headerShown: false}} name="Login Test" component={LoginScreen}/>
            <Stack.Screen name="Site Info" component={SiteInfoEdit}/>




            {/* Forms and Reports screens */}
            <Stack.Screen name="Table Total Body" component={TableTotalBody}/>
            <Stack.Screen name="Table Report" component={TableReport}/>
            <Stack.Screen name="Nervous System Assessment Form - Feet" component={NervousSystemFormFeet}/>
            <Stack.Screen name="Nervous System Assessment Form - Ankle" component={NervousSystemFormAnkle} />
            <Stack.Screen name="Nervous System Assessment Form - Hip Extension" component={NervousSystemFormHipExtension} />
            <Stack.Screen name="Nervous System Assessment Form - Hip" component={NervousSystemFormHip} />
            <Stack.Screen name="Nervous System Assessment Form - Hip Flexion" component={NervousSystemFormHipFlexion} />
            <Stack.Screen name="Nervous System Assessment Form - Functional Movements Part 1" component={NervousSystemFunctionalMovements} />
            <Stack.Screen name="Nervous System Assessment Form - Functional Movements Overhead Squat" component={NervousSystemOverheadSquat} />
            <Stack.Screen name="Nervous System Assessment Form - Functional Movements Single Leg Eyes Open" component={NervousSystemSingleLegEyesOpen} />
            <Stack.Screen name="Nervous System Assessment Form - Functional Movements Single Leg Eyes Closed" component={NervousSystemSingleLegEyesClosed} />
            <Stack.Screen name="Nervous System Assessment Form - Functional Movements Single Leg Squat" component={NervousSystemSingleLegSquat} />
            <Stack.Screen name="Nervous System Assessment Form - Range of Motion Upper Body" component={NervousSystemUpperBody} />
            <Stack.Screen name="Nervous System Assessment Form - Range of Motion Lower Body - Ankle" component={NervousSystemLowerBodyAnkle} />
            <Stack.Screen name="Nervous System Assessment Form - Range of Motion Lower Body - Hip" component={NervousSystemLowerBodyHip} />
            <Stack.Screen name="Nervous System Assessment Form - VERT Jump" component={NervousSystemVERTJump} />
            <Stack.Screen name="Sprint Capacity - Warm Up at 85%" component={SprintCapacityWarmUp} />
            <Stack.Screen name="Sprint Capacity - Run" component={SprintCapacityRun} />
            <Stack.Screen name="Threshold Test - Warm Up" component={ThresholdFormWarmUp} />
            <Stack.Screen name="Threshold Test - Run" component={ThresholdFormRun} />
            <Stack.Screen name="Report - Nervous Assessment System : Feet" component={NervousSystemReportFeet} />
            <Stack.Screen name="Report - Nervous Assessment System : Ankle" component={NervousSystemReportAnkle} />
            <Stack.Screen name="Report - Nervous Assessment System : Hip Extension" component={NervousSystemReportHipExtension} />
            <Stack.Screen name="Report - Nervous Assessment System : Hip" component={NervousSystemReportHip} />
            <Stack.Screen name="Report - Nervous Assessment System : Hip Flexion" component={NervousSystemReportHipFlexion} />
            <Stack.Screen name="Report - Nervous Assessment System: Upper Body" component={NervousSystemReportUpperBody} />
            <Stack.Screen name="Report - Nervous Assessment System: Lower Body Ankle" component={NervousSystemReportLowerBodyAnkle} />
            <Stack.Screen name="Report - Nervous Assessment System: Lower Body Hip" component={NervousSystemReportLowerBodyHip} />
            <Stack.Screen name="Report - Nervous Assessment System: VERT Jump" component={NervousSystemReportVERTJump} />
            <Stack.Screen name="Report - Sprint Capacity and Threshold Test" component={SprintCapacityAndThresholdReport} />
            <Stack.Screen name="Energy System Development Form" component={EnergySystemDevelopmentForm}/>
            <Stack.Screen name="Report - Nervous Assessment: Functional Movements" component={NervousSystemReportSquatAndEyes}/>

            </Stack.Navigator>

                        
           </DataProvider>

        </NavigationContainer>
    // </Provider>

  );
};