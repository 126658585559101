import React, {useState, useEffect} from 'react';
import { Button, SafeAreaView, StyleSheet, ScrollView, Text, View } from 'react-native';
import TextWithInput from '../components/TextWithInput';
import GenderDropdown from '../components/GenderDropdown';
import IncompleteModal from '../components/IncompleteModal';
import LoginInput from '../components/LoginInput';
import { emailValidator } from '../varHelpers/emailValidator'
import { theme } from '../core/theme'
import PaperInput from '../components/PaperInput';
import { numberValidator } from '../varHelpers/numberValidator';
import { textLengthValidator } from '../varHelpers/textLengthValidator';
import { dateValidator } from '../varHelpers/dateValidator';
import Divider from '../components/Divider';
import ShortPaperInput from '../components/ShortPaperInput';
import { formatDOB } from '../varHelpers/formatDOB';
import MultilinePaperInput from '../components/MultilinePaperInput';
import ErrorModal from '../components/ErrorModal';
import SwitchAssistance from '../components/Switch';
import axios from 'axios'; 
import useTrainerStore from '../varHelpers/trainerStore';
import { phoneValidator } from '../varHelpers/phoneValidator';
import AccountErrorModal from '../components/AccountErrorModal';
import { addressLine2Validator } from '../varHelpers/addressLine2Validator';
import { floatValidator } from '../varHelpers/floatValidator';
import { zipCodeValidator } from '../varHelpers/zipCodeValidator';
import { inchValidator } from '../varHelpers/inchValidator';

const AthleteCreate = ({navigation}) => {

  const [athleteList, setAthleteList] = useState([]);

  const isSuccess = useState(false);
 
  const { currentUser } = useTrainerStore();

  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [isPro, setIsPro] = useState(0);
   // Check if switches are enabled or not
  const [isEnabledInversionL, setIsEnabledInversionL] = useState(0);

   // Functions to change switch 
   const toggleSwitchInversionL = () => setIsEnabledInversionL((previousState) => !previousState);
 
  //Text fields
  const [firstName, setFirstName] = useState({ value: '', error: '' });

  // Update firstNameTest error based on length using useEffect
  useEffect(() => {
    const error = textLengthValidator(firstName.value, 50);
    setFirstName((prev) => ({ ...prev, error }));
  }, [firstName.value]);

  // Last Name
  const [lastName, setLastName] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(lastName.value, 50);
    setLastName((prev) => ({ ...prev, error }));
  }, [lastName.value]);

  // Gender
  const [gender, setGender] = useState('');

  // Address Line 1
  const [addressLine1, setAddressLine1] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(addressLine1.value, 256);
    setAddressLine1((prev) => ({ ...prev, error }));
  }, [addressLine1.value]);

  // Address Line 2
  const [addressLine2, setAddressLine2] = useState({ value: '', error: '' });
  useEffect(() => {
    const error = addressLine2Validator(addressLine2.value, 256);
    setAddressLine2((prev) => ({ ...prev, error }));
  }, [addressLine2.value]);

  // Mailing Address
  const [mailingAddress, setMailingAddress] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = addressLine2Validator(mailingAddress.value, 256);
    setMailingAddress((prev) => ({ ...prev, error }));
  }, [mailingAddress.value]);

  // City
  const [city, setCity] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(city.value, 256);
    setCity((prev) => ({ ...prev, error }));
  }, [city.value]);

  // State
  const [stateUS, setStateUS] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(stateUS.value, 256);
    setStateUS((prev) => ({ ...prev, error }));
  }, [stateUS.value]);

  // Zip Code
  const [zipCode, setZipCode] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = zipCodeValidator(zipCode.value);
    setZipCode((prev) => ({ ...prev, error }));
  }, [zipCode.value]);

  // dob
  const [dob, setdob] = useState({ value: '', error: '' });

  useEffect(() => {
    // Format the date with hyphens
    const formattedDate = formatDOB(dob.value);
  
    // Update the state with the formatted date
    setdob({ value: formattedDate, error: '' });

  
    // Perform the error check
    const error = dateValidator(formattedDate);
    setdob((prev) => ({ ...prev, error }));
  }, [dob.value]);

  // Height Feet
  const [heightF, setHeightF] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = numberValidator(heightF.value, 15);
    setHeightF((prev) => ({ ...prev, error }));
  }, [heightF.value]);

  // Height Inches
  const [heightI, setHeightI] = useState({ value: '', error: '' });
  useEffect(() => {
    const error = inchValidator(heightI.value, 3);
    setHeightI((prev) => ({ ...prev, error }));
  }, [heightI.value]);

  // Weight
  const [weight, setWeight] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = floatValidator(weight.value, 15);
    setWeight((prev) => ({ ...prev, error }));
  }, [weight.value]);

  // Sport
  const [sport, setSport] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(sport.value, 256);
    setSport((prev) => ({ ...prev, error }));
  }, [sport.value]);

  // Position
  const [position, setPosition] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(position.value, 256);
    setPosition((prev) => ({ ...prev, error }));
  }, [position.value]);

  // Club
  const [club, setClub] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = textLengthValidator(club.value, 256);
    setClub((prev) => ({ ...prev, error }));
  }, [club.value]);

  // Jersey Number
  const [jerseyNumber, setJerseyNumber] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = numberValidator(jerseyNumber.value, 15);
    setJerseyNumber((prev) => ({ ...prev, error }));
  }, [jerseyNumber.value]);

  // Phone
  const [phone, setPhone] = useState({ value: '', error: '' });

  useEffect(() => {
    const error = phoneValidator(phone.value, 256);
    setPhone((prev) => ({ ...prev, error }));
  }, [phone.value]);

    // Emergency COntact
    const [ECName, setECName] = useState({ value: '', error: '' });
  
    useEffect(() => {
      const error = textLengthValidator(ECName.value, 256);
      setECName((prev) => ({ ...prev, error }));
    }, [ECName.value]);
  
    // ECPhone
    const [ECPhone, setECPhone] = useState({ value: '', error: '' });
  
    useEffect(() => {
      const error = phoneValidator(ECPhone.value, 256);
      setECPhone((prev) => ({ ...prev, error }));
    }, [ECPhone.value]);

  // COMMENTS
  const [comments, setComments] = useState('');
  const [packageInfo, setPackageInfo] = useState('');

  //variables and error checks
  const [email, setEmail] = useState({ value: '', error: '' });

  // useEffect(() => {
  //   const error = emailValidator(email.value, 256);
  //   setEmail((prev) => ({ ...prev, error }));
  // }, [email.value]);

  // //Modal
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [showError, setShowError] = useState(false);

  const profileJSON = {
    trainer_id: currentUser[0].id,
    firstname: firstName.value.trim(),
    lastname: lastName.value.toString(),
    email: email.value,
    phone: phone.value,
    address1: addressLine1.value,
    address2: addressLine2.value,
    city: city.value,
    state: stateUS.value,
    zipcode: zipCode.value,
    emergency_contact: ECName.value,
    emergency_contact_phone: ECPhone.value,
    mailing_address: mailingAddress.value.toString(),
    heightF: parseInt(heightF.value),
    heightI: parseInt(heightI.value),
    weight: parseFloat(weight.value),
    package: packageInfo,
    comments: comments,
    dob: dob.value,
    gender: gender,
    sport: sport.value,
    isPro: isPro,
    club: club.value,
    jersey_num: jerseyNumber.value,
    position: position.value
  };
 
  const logInputValues = () => {

    const isEmpty = (
      firstName.value === '' ||
      lastName.value === '' ||
      gender === '' ||
      addressLine1.value === '' ||
      // addressLine2.value === '' ||
      // mailingAddress.value === '' ||
      city.value === '' ||
      stateUS.value === '' ||
      zipCode.value === '' ||
      dob.value === '' ||
      heightF.value === '' ||
      heightI.value === '' ||
      weight.value === '' ||
      sport.value === '' ||
      position.value === '' ||
      club.value === '' ||
      jerseyNumber.value === '' ||
      email.value === '' ||
      phone.value === '' ||
      ECName.value === '' ||
      ECPhone.value === '' 
    );

    const noError = (
      firstName.error === '' &&
      lastName.error === '' &&
      addressLine1.error === '' &&
      addressLine2.error === '' &&
      mailingAddress.error === '' &&
      city.error === '' &&
      stateUS.error === '' &&
      zipCode.error === '' &&
      dob.error === '' &&
      heightF.error === '' &&
      heightI.error === '' &&
      weight.error === '' &&
      sport.error === '' &&
      position.error === '' &&
      club.error === '' &&
      jerseyNumber.error === '' &&
      email.error === '' &&
      phone.error === '' &&
      ECName.error === '' &&
      ECPhone.error === '' 
    );
    
    const errors = {
      error1: firstName.error,
      error2: lastName.error,
      error3: addressLine1.error, 
      error4: addressLine2.error,
      error5: mailingAddress.error, 
      error6: city.error, 
      error7: stateUS.error, 
      error8: zipCode.error, 
      error9: dob.error, 
      error10: heightF.error, 
      error11:heightI.error, 
      error12:weight.error, 
      error13:sport.error, 
      error14:position.error, 
      error15:club.error, 
      error16:jerseyNumber.error, 
      error17:email.error, 
      error18:phone.error ,
      error19:ECName.error ,
      error20:ECPhone.error 
    };

  const emailError = emailValidator(email.value, 256);
  if(isEmpty){
    setShowIncomplete(true);
    return
  }
  else if(!noError || emailError){
    setEmail({value:email.value, error: emailError})
    setShowError(true);
    return
  }
  else if(dob.value.length < 10){
    setShowError(true);
    setdob({ value: dob.value, error: 'Incomplete Date'});
    return
  }
  else {
    // console.log(profileJSON);
    postAthlete(profileJSON);
    setAthleteList([]);
  };
};

const postAthlete = async (allResults) => {
  try {

    const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/';

    const headers = {
      'Content-Type': 'application/json',
      'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      // 'api-key': process.env.API_KEY,
    };

    const response = await axios.post(apiUrl, allResults, { headers });
    // console.log(response.status);
    if (response.status === 201) {
      // Success
      navigation.navigate('Athlete Search');
  }

    // console.log("Response code:", response.status);
    // console.log('Response:', response.data);
  } catch (error) {
    // Handle errors

    // console.error('Error submitting data:', error);
    setErrorMessage('Error submitting data, check inputs or that athlete doesn\'t already exist');
    setVisible(true);

    // You can also check specific error properties, e.g., error.response
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      setErrorMessage('Error submitting data, check inputs or that athlete doesn\'t already exist');
      setVisible(true);
      // console.error('Server responded with:', error.response.data);
      // console.error('Status code:', error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      setErrorMessage('Server did not respond');
      setVisible(true);
      // console.error('No response received. Request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.error('Error setting up the request:', error.message);
      setErrorMessage('Encountered server error');
      setVisible(true);
    }
  }
};

  return (
    <SafeAreaView>
    <ScrollView>
      <View style={styles.container}>
        <View>
          <Text style={styles.labelCat}>Create Athlete Profile</Text>
        </View>
        <Text style={styles.labelCat}>Name</Text>
        {/* Athlete Name */}
        <View style={styles.rowContainer}>
          <View>
            <PaperInput
              label="First and Middle Name"
              returnKeyType="next"
              value={firstName.value}
              onChangeText={(text) => setFirstName({ value: text, error: '' })}
              error={!!firstName.error}
              errorText={firstName.error}
              maxLength={50}
            />
          </View>
          <View>
            <PaperInput
              label="Last Name"
              returnKeyType="next"
              value={lastName.value}
              onChangeText={(text) => setLastName({ value: text, error: '' })}
              error={!!lastName.error}
              errorText={lastName.error}
              maxLength={50}
            />
          </View>
        </View>
        <View>
          <GenderDropdown 
            value={gender}
            onChange={(value) => setGender(value)}
          />
        </View>
    
        {/* Athlete Address */}
        <Text style={styles.labelCat}>Address</Text>
        <View style={styles.rowContainer}>
          {/* <View> */}
            <PaperInput
              label="Address Line 1"
              returnKeyType="next"
              value={addressLine1.value}
              onChangeText={(text) => setAddressLine1({ value: text, error: '' })}
              error={!!addressLine1.error}
              errorText={addressLine1.error}
              maxLength={256}
            />
            <PaperInput
              label="Address Line 2"
              returnKeyType="next"
              value={addressLine2.value}
              onChangeText={(text) => setAddressLine2({ value: text, error: '' })}
              error={!!addressLine2.error}
              errorText={addressLine2.error}
              maxLength={256}
            />
            <PaperInput
              label="Mailing Address"
              returnKeyType="next"
              value={mailingAddress.value}
              onChangeText={(text) => setMailingAddress({ value: text, error: '' })}
              error={!!mailingAddress.error}
              errorText={mailingAddress.error}
              maxLength={256}
            />
        </View>
          <View style={styles.rowContainerShort}>
            <ShortPaperInput
              label="City"
              returnKeyType="next"
              value={city.value}
              onChangeText={(text) => setCity({ value: text, error: '' })}
              error={!!city.error}
              errorText={city.error}
              maxLength={256}
            />
            <ShortPaperInput
              label="State"
              returnKeyType="next"
              value={stateUS.value}
              onChangeText={(text) => setStateUS({ value: text, error: '' })}
              error={!!stateUS.error}
              errorText={stateUS.error}
              maxLength={256}
            />
            <ShortPaperInput
              label="Zip Code"
              returnKeyType="next"
              value={zipCode.value}
              onChangeText={(text) => setZipCode({ value: text, error: '' })}
              error={!!zipCode.error}
              errorText={zipCode.error}
              maxLength={12}
              keyboardType="number-pad"
            />
        </View>

        <Text style={styles.labelCat}>Date of Birth</Text>
        <View>
          <PaperInput
            label="(YYYY-MM-DD)"
            returnKeyType="next"
            value={dob.value}
            onChangeText={(text) => setdob({ value: text, error: '' })}
            error={!!dob.error}
            errorText={dob.error}
            maxLength={10}
            keyboardType="numbers-and-punctuation"
          />
        </View>
        <Text style={styles.labelCat}>Physical Information</Text>
        <View style={styles.rowContainerShort}>
          <PaperInput
            label="Weight (lbs)"
            returnKeyType="next"
            value={weight.value}
            onChangeText={(text) => setWeight({ value: text, error: '' })}
            error={!!weight.error}
            errorText={weight.error}
            maxLength={10}
            keyboardType="decimal-pad"
          />
          <PaperInput
            label="Feet"
            returnKeyType="next"
            value={heightF.value}
            onChangeText={(text) => setHeightF({ value: text, error: '' })}
            error={!!heightF.error}
            errorText={heightF.error}
            maxLength={15}
            keyboardType="number-pad"
          />
          <PaperInput
            label="Inches"
            returnKeyType="next"
            value={heightI.value}
            onChangeText={(text) => setHeightI({ value: text, error: '' })}
            error={!!heightI.error}
            errorText={heightI.error}
            maxLength={15}
            keyboardType="number-pad"
          />
        </View>
        <Text style={styles.labelCat}>Sport Information</Text>
        <View style={styles.rowContainer}>
        <PaperInput
            label="Sport"
            returnKeyType="next"
            value={sport.value}
            onChangeText={(text) => setSport({ value: text, error: '' })}
            error={!!sport.error}
            errorText={sport.error}
            maxLength={256}
          />
          <PaperInput
            label="Team/Club"
            returnKeyType="next"
            value={club.value}
            onChangeText={(text) => setClub({ value: text, error: '' })}
            error={!!club.error}
            errorText={club.error}
            maxLength={256}
          />
          <PaperInput
            label="Position"
            returnKeyType="next"
            value={position.value}
            onChangeText={(text) => setPosition({ value: text, error: '' })}
            error={!!position.error}
            errorText={position.error}
            maxLength={256}
          />
          <PaperInput
            label="Jersey Number"
            returnKeyType="next"
            value={jerseyNumber.value}
            onChangeText={(text) => setJerseyNumber({ value: text, error: '' })}
            error={!!jerseyNumber.error}
            errorText={jerseyNumber.error}
            maxLength={15}
            keyboardType="decimal-pad"
          />
        </View>
        <View style={styles.rowContainer}>
          <Text style={styles.labelSwitch}>Pro Athlete?</Text>
          <SwitchAssistance 
            isEnabled={isEnabledInversionL} 
            onValueChange={(isEnabled) => {
              // Update the role based on the switch state
              const proStatus = isEnabled ? 1 : 0;
              console.log('isPro:', proStatus);
              setIsPro(proStatus);

              // Toggle the switch and update other state if needed
              toggleSwitchInversionL();
              // setQuadDominanceInversionL(isEnabled ? 1 : 0);
            }}
          />
        </View>
        {/* Athlete Contact Info */}
        <Text style={styles.labelCat}>Athlete Contact Information</Text>
        <View >
          <LoginInput
            label="Email"
            returnKeyType="next"
            value={email.value}
            onChangeText={(text) => setEmail({ value: text, error: '' })}
            error={!!email.error}
            errorText={email.error}
            autoCapitalize="none"
            autoCompleteType="email"
            textContentType="emailAddress"
            keyboardType="email-address"
          />
          <LoginInput
            label="Phone Number"
            returnKeyType="next"
            value={phone.value}
            onChangeText={(text) => setPhone({ value: text, error: '' })}
            error={!!phone.error}
            errorText={phone.error}
            autoCapitalize="none"
            keyboardType="number-pad"
          />
        </View>
        <Text style={styles.labelCat}>Emergency Contact</Text>
        <View>
          <PaperInput
            label="Full Name"
            returnKeyType="next"
            value={ECName.value}
            onChangeText={(text) => setECName({ value: text, error: '' })}
            error={!!ECName.error}
            errorText={ECName.error}
            maxLength={256}
          />  
          <PaperInput
            label="Phone Number"
            returnKeyType="next"
            value={ECPhone.value}
            onChangeText={(text) => setECPhone({ value: text, error: '' })}
            error={!!ECPhone.error}
            errorText={ECPhone.error}
            keyboardType="number-pad"
            maxLength={256}
          />
        </View>
        <Text style={styles.labelCat}>Training Information</Text>
        <View>
          <PaperInput
            label="Package"
            returnKeyType="next"
            value={packageInfo}
            onChangeText={(text) => setPackageInfo(text)}
            maxLength={256}
          />
        </View>
        <View style={styles.containerInfo}>
          <MultilinePaperInput
            label="Comments"
            returnKeyType="next"
            value={comments}
            onChangeText={(text) => setComments(text)}
            maxLength={1000}
          />
        </View>
        <View style={styles.rowContainer}>
          <Button 
            title='Cancel'
            onPress={() => navigation.navigate("Athlete Search")}
          />
          <Button
            title='Create Profile'
            onPress={() => {
              logInputValues();
            }}
          />
          </View>
        <IncompleteModal visible={showIncomplete} onClose={() => setShowIncomplete(false)}/>
        <ErrorModal visible = {showError} onClose = {() => setShowError(false)}/>
        <AccountErrorModal
          text={errorMessage} 
          visible={visible} 
          onClose={() => setVisible(false)}
        />
      </View>
    </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    // marginHorizontal: 100,
    marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'100%',
    // backgroundColor: 'gray'
  },
  containerInfo: {
    // marginHorizontal: 100,
    marginRight: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    width:'70%',
    // backgroundColor: 'gray'
  },
  profile: {
    flex: 7,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:"red"
  },
  labelInput: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowContainerShort: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
  //  backgroundColor: "red",
    width:'45%'
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
  //  backgroundColor: "red",
    width:'80%'
  },
  rowContainerName: {
    flexDirection: 'row',
   backgroundColor: "yellow"
  },
  labelCat: {
    fontSize: 20,
    height:20,
    padding: 10,
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 8,
  },
  inputText: {
      padding: 3,
  },
  labelSwitch: {
    fontSize: 20,
    height:20,
    padding: 10,
    // marginLeft: 10,
    // marginTop: 15,
    marginBottom: 20,
  },
});

export default AthleteCreate;