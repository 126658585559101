import React, { useState, useEffect } from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform } from 'react-native';
import { DataTable } from 'react-native-paper';
import NervousSystemFormHipExtension from './NSFormHipExtension';
import NervousSystemFormHipFlexion from './NSFormHipFlexion';
import Slider from '@mui/material/Slider';
import SwitchAssistance from '../components/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAthleteStore from '../varHelpers/athleteStore';
import axios from 'axios';
import RequestErrorModal from '../components/RequestErrorModal';


const NervousSystemFormHip = ({ navigation }) => {

  const [requestError, setRequestError] = useState(false);

  const { athlete, setAthlete } = useAthleteStore();

  // Test passing parameters to other screens and initial values
  const [hip_abeccentric_r, sethip_abeccentric_r] = useState(0);
  const [hip_abeccentric_l, sethip_abeccentric_l] = useState(0);
  const [hip_abconcentric_r, sethip_abconcentric_r] = useState(0);
  const [hip_abconcentric_l, sethip_abconcentric_l] = useState(0);
  const [hip_adeccentric_r, sethip_adeccentric_r] = useState(0);
  const [hip_adeccentric_l, sethip_adeccentric_l] = useState(0);
  const [hip_adconcentric_r, sethip_adconcentric_r] = useState(0);
  const [hip_adconcentric_l, sethip_adconcentric_l] = useState(0);
  const [hip_internalrot_r, sethip_internalrot_r] = useState(0);
  const [hip_internalrot_l, sethip_internalrot_l] = useState(0);
  const [hip_externalrot_r, sethip_externalrot_r] = useState(0);
  const [hip_externalrot_l, sethip_externalrot_l] = useState(0);


  // Check if switches are enabled or not
  const [isEnabledhip_abeccentric_r, setIsEnabledhip_abeccentric_r] = useState(false);
  const [isEnabledhip_abeccentric_l, setIsEnabledhip_abeccentric_l] = useState(false);
  const [isEnabledhip_abconcentric_r, setIsEnabledhip_abconcentric_r] = useState(false);
  const [isEnabledhip_abconcentric_l, setIsEnabledhip_abconcentric_l] = useState(false);
  const [isEnabledhip_adeccentric_r, setIsEnabledhip_adeccentric_r] = useState(false);
  const [isEnabledhip_adeccentric_l, setIsEnabledhip_adeccentric_l] = useState(false);
  const [isEnabledhip_adconcentric_r, setIsEnabledhip_adconcentric_r] = useState(false);
  const [isEnabledhip_adconcentric_l, setIsEnabledhip_adconcentric_l] = useState(false);
  const [isEnabledhip_internalrot_r, setIsEnabledhip_internalrot_r] = useState(false);
  const [isEnabledhip_internalrot_l, setIsEnabledhip_internalrot_l] = useState(false);
  const [isEnabledhip_externalrot_r, setIsEnabledhip_externalrot_r] = useState(false);
  const [isEnabledhip_externalrot_l, setIsEnabledhip_externalrot_l] = useState(false);


  // Functions to change switch 
  const toggleSwitchhip_abeccentric_r = () => setIsEnabledhip_abeccentric_r((previousState) => !previousState);
  const toggleSwitchhip_abeccentric_l = () => setIsEnabledhip_abeccentric_l((previousState) => !previousState);
  const toggleSwitchhip_abconcentric_r = () => setIsEnabledhip_abconcentric_r((previousState) => !previousState);
  const toggleSwitchhip_abconcentric_l = () => setIsEnabledhip_abconcentric_l((previousState) => !previousState);
  const toggleSwitchhip_adeccentric_r = () => setIsEnabledhip_adeccentric_r((previousState) => !previousState);
  const toggleSwitchhip_adeccentric_l = () => setIsEnabledhip_adeccentric_l((previousState) => !previousState);
  const toggleSwitchhip_adconcentric_r = () => setIsEnabledhip_adconcentric_r((previousState) => !previousState);
  const toggleSwitchhip_adconcentric_l = () => setIsEnabledhip_adconcentric_l((previousState) => !previousState);
  const toggleSwitchhip_internalrot_r = () => setIsEnabledhip_internalrot_r((previousState) => !previousState);
  const toggleSwitchhip_internalrot_l = () => setIsEnabledhip_internalrot_l((previousState) => !previousState);
  const toggleSwitchhip_externalrot_r = () => setIsEnabledhip_externalrot_r((previousState) => !previousState);
  const toggleSwitchhip_externalrot_l = () => setIsEnabledhip_externalrot_l((previousState) => !previousState);


  const [hip_abeccentric_r_quad, sethip_abeccentric_r_quad] = useState(0);
  const [hip_abeccentric_l_quad, sethip_abeccentric_l_quad] = useState(0);
  const [hip_abconcentric_r_quad, sethip_abconcentric_r_quad] = useState(0);
  const [hip_abconcentric_l_quad, sethip_abconcentric_l_quad] = useState(0);
  const [hip_adeccentric_r_quad, sethip_adeccentric_r_quad] = useState(0);
  const [hip_adeccentric_l_quad, sethip_adeccentric_l_quad] = useState(0);
  const [hip_adconcentric_r_quad, sethip_adconcentric_r_quad] = useState(0);
  const [hip_adconcentric_l_quad, sethip_adconcentric_l_quad] = useState(0);
  const [hip_internalrot_r_quad, sethip_internalrot_r_quad] = useState(0);
  const [hip_internalrot_l_quad, sethip_internalrot_l_quad] = useState(0);
  const [hip_externalrot_r_quad, sethip_externalrot_r_quad] = useState(0);
  const [hip_externalrot_l_quad, sethip_externalrot_l_quad] = useState(0);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#989170', // Replace with your desired primary color
      },
    },
  });

  // Variables amd setters to manage the value of the switch
  const resultsHip = {
    hip_abeccentric_r: hip_abeccentric_r,
    hip_abeccentric_l: hip_abeccentric_l,
    hip_abconcentric_r: hip_abconcentric_r,
    hip_abconcentric_l: hip_abconcentric_l,
    hip_adeccentric_r: hip_adeccentric_r,
    hip_adeccentric_l: hip_adeccentric_l,
    hip_adconcentric_r: hip_adconcentric_r,
    hip_adconcentric_l: hip_adconcentric_l,
    hip_internalrot_r: hip_internalrot_r,
    hip_internalrot_l: hip_internalrot_l,
    hip_externalrot_r: hip_externalrot_r,
    hip_externalrot_l: hip_externalrot_l,
    hip_abeccentric_r_quad: hip_abeccentric_r_quad,
    hip_abeccentric_l_quad: hip_abeccentric_l_quad,
    hip_abconcentric_r_quad: hip_abconcentric_r_quad,
    hip_abconcentric_l_quad: hip_abconcentric_l_quad,
    hip_adeccentric_r_quad: hip_adeccentric_r_quad,
    hip_adeccentric_l_quad: hip_adeccentric_l_quad,
    hip_adconcentric_r_quad: hip_adconcentric_r_quad,
    hip_adconcentric_l_quad: hip_adconcentric_l_quad,
    hip_internalrot_r_quad: hip_internalrot_r_quad,
    hip_internalrot_l_quad: hip_internalrot_l_quad,
    hip_externalrot_r_quad: hip_externalrot_r_quad,
    hip_externalrot_l_quad: hip_externalrot_l_quad

  }

  // Receive event and take the value of the event
  const handlehip_abeccentric_rChange = (event, value) => {
    sethip_abeccentric_r(value);
  };

  const handlehip_abeccentric_lChange = (event, value) => {
    sethip_abeccentric_l(value);
  };

  const handlehip_abconcentric_rChange = (event, value) => {
    sethip_abconcentric_r(value)
  };

  const handlehip_abconcentric_lChange = (event, value) => {
    sethip_abconcentric_l(value)
  };

  const handlehip_adeccentric_rChange = (event, value) => {
    sethip_adeccentric_r(value)
  };

  const handlehip_adeccentric_lChange = (event, value) => {
    sethip_adeccentric_l(value)
  };

  const handlehip_adconcentric_rChange = (event, value) => {
    sethip_adconcentric_r(value)
  };

  const handlehip_adconcentric_lChange = (event, value) => {
    sethip_adconcentric_l(value)
  };

  const handlehip_internalrot_rChange = (event, value) => {
    sethip_internalrot_r(value)
  };

  const handlehip_internalrot_lChange = (event, value) => {
    sethip_internalrot_l(value)
  };

  const handlehip_externalrot_rChange = (event, value) => {
    sethip_externalrot_r(value)
  };

  const handlehip_externalrot_lChange = (event, value) => {
    sethip_externalrot_l(value)
  };

  // Used to save the array object and pass it to another screen
  const { dataAthleteProfile, dataHipNervousSystemForm, setDataHipNervousSystemForm } = useData();

  const [data, setData] = useState('');

  /**
   * The function `getDataFromDb` makes a series of GET requests to retrieve data from a database,
   * handles the responses, and logs the data for debugging purposes.
   * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
   * you want to retrieve data from the database. This ID is used in the API request to fetch the
   * athlete's evaluation data.
   */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/nervsys';
      const parameters = `?athlete_id=${athlete_id}`;

      // const firstPageUrl = apiUrl + parameters;

      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`, { headers });

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
          responseData = response.data;
          // console.log("Response data in JSON:", responseData);
        } else {
          // Handle other content types if needed
          // For now, assume it's JSON
          responseData = JSON.parse(response.data);
          // console.log("Response data in JSON:", responseData);
        }

        const firstPageResponse = await axios.get(apiUrl, { headers });


        console.log(firstPageResponse.headers);


        // Access hasNextPage from the response
        hasNextPage = response.data.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const nervSysEvals = response.data.nervSysEvals;
        lastRecord = nervSysEvals[nervSysEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }

      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });


      console.log(firstPageResponse.headers);

      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });

      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);

      // Assuming you have a structure in lastPageData for the "Previous Session" values
      const nervSysEvals = lastPageResponse.data?.nervSysEvals;
      console.log("nervSysEvals:", nervSysEvals)

      if (nervSysEvals && nervSysEvals.length > 0) {
        const previousSessionValues = {
          hip_abeccentric_r: nervSysEvals[0].hip_abeccentric_r,
          hip_abeccentric_l: nervSysEvals[0].hip_abeccentric_l,
          hip_abconcentric_r: nervSysEvals[0].hip_abconcentric_r,
          hip_abconcentric_l: nervSysEvals[0].hip_abconcentric_l,
          hip_abeccentric_r_quad: nervSysEvals[0].hip_abeccentric_r_quad,
          hip_abeccentric_l_quad: nervSysEvals[0].hip_abeccentric_l_quad,
          hip_abconcentric_r_quad: nervSysEvals[0].hip_abconcentric_r_quad,
          hip_abconcentric_l_quad: nervSysEvals[0].hip_abconcentric_l_quad,
          hip_adeccentric_r: nervSysEvals[0].hip_adeccentric_r,
          hip_adeccentric_l: nervSysEvals[0].hip_adeccentric_l,
          hip_adconcentric_r: nervSysEvals[0].hip_adconcentric_r,
          hip_adconcentric_l: nervSysEvals[0].hip_adconcentric_l,
          hip_adeccentric_r_quad: nervSysEvals[0].hip_adeccentric_r_quad,
          hip_adeccentric_l_quad: nervSysEvals[0].hip_adeccentric_l_quad,
          hip_adconcentric_r_quad: nervSysEvals[0].hip_adconcentric_r_quad,
          hip_adconcentric_l_quad: nervSysEvals[0].hip_adconcentric_l_quad,
          hip_internalrot_r: nervSysEvals[0].hip_internalrot_r,
          hip_internalrot_l: nervSysEvals[0].hip_internalrot_l,
          hip_externalrot_r: nervSysEvals[0].hip_externalrot_r,
          hip_externalrot_l: nervSysEvals[0].hip_externalrot_l,
          hip_internalrot_r_quad: nervSysEvals[0].hip_internalrot_r_quad,
          hip_internalrot_l_quad: nervSysEvals[0].hip_internalrot_l_quad,
          hip_externalrot_r_quad: nervSysEvals[0].hip_externalrot_r_quad,
          hip_externalrot_l_quad: nervSysEvals[0].hip_externalrot_l_quad,
        };
        setDataHipNervousSystemForm(previousSessionValues);
        // console.log("Data Lower Ankle:", data);
        console.log("Previous session:", previousSessionValues);
      }

    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);

      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true);
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true);
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /* The above code is a useEffect hook in JavaScript. It is used to fetch data from a database
  (presumably using the `getDataFromDb` function) when a component mounts. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true);
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `saveData` saves data to AsyncStorage in JavaScript.
   */
  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('hip-form', JSON.stringify(resultsHip));
      setData(resultsHip);
    } catch (error) {
      setRequestError(true);
      console.error('Error saving data:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.containerView}>
          {/* Athlete's name and test title */}
          <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
          <Text style={styles.subTitle}>Nervous System Assessment Form - Strength Test</Text>
          <Button style={styles.button}
            onPress={() => navigation.navigate('Table Total Body')}
            title="Go to : Total Body Assessment Parts"
            color="#989170">Total Body Assessment Parts</Button>
          {/* Forms table */}
          <DataTable style={styles.table}>
            <DataTable.Row>
              <DataTable.Cell>Hip</DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Strength{'\n'}Right Side
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Strength{'\n'}Left Side
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Quad Dominance {'\n'} Right Side(Y/N)
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Quad Dominance {'\n'} Left Side(Y/N)
                </Text>
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  Abduction{'\n'}Eccentric
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_abeccentric_rChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_abeccentric_lChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_abeccentric_r} onValueChange={(isEnabled, hip_abeccentric_r_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_abeccentric_r_quad = 1;
                  } else {
                    hip_abeccentric_r_quad = 0;
                  }
                  toggleSwitchhip_abeccentric_r(); // Call the toggleSwitchBigToeL function
                  sethip_abeccentric_r_quad(hip_abeccentric_r_quad);
                }} />
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_abeccentric_l} onValueChange={(isEnabled, hip_abeccentric_l_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_abeccentric_l_quad = 1;
                  } else {
                    hip_abeccentric_l_quad = 0;
                  }
                  toggleSwitchhip_abeccentric_l(); // Call the toggleSwitchBigToeL function
                  sethip_abeccentric_l_quad(hip_abeccentric_l_quad);
                }} />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  Abduction{'\n'}Concentric
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_abconcentric_rChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_abconcentric_lChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_abconcentric_r} onValueChange={(isEnabled, hip_abconcentric_r_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_abconcentric_r_quad = 1;
                  } else {
                    hip_abconcentric_r_quad = 0;
                  }
                  toggleSwitchhip_abconcentric_r(); // Call the toggleSwitchBigToeL function
                  sethip_abconcentric_r_quad(hip_abconcentric_r_quad);
                }} />
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_abconcentric_l} onValueChange={(isEnabled, hip_abconcentric_l_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_abconcentric_l_quad = 1;
                  } else {
                    hip_abconcentric_l_quad = 0;
                  }
                  toggleSwitchhip_abconcentric_l(); // Call the toggleSwitchBigToeL function
                  sethip_abconcentric_l_quad(hip_abconcentric_l_quad);
                }} />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>Adduction{'\n'}Eccentric</Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_adeccentric_rChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_adeccentric_lChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_adeccentric_r} onValueChange={(isEnabled, hip_adeccentric_r_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_adeccentric_r_quad = 1;
                  } else {
                    hip_adeccentric_r_quad = 0;
                  }
                  toggleSwitchhip_adeccentric_r(); // Call the toggleSwitchBigToeL function
                  sethip_adeccentric_r_quad(hip_adeccentric_r_quad);
                }} />
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_adeccentric_l} onValueChange={(isEnabled, hip_abeccentric_l_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_abeccentric_l_quad = 1;
                  } else {
                    hip_abeccentric_l_quad = 0;
                  }
                  toggleSwitchhip_adeccentric_l(); // Call the toggleSwitchBigToeL function
                  sethip_adeccentric_l_quad(hip_abeccentric_l_quad);
                }} />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>Adduction{'\n'}Concentric</Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_adconcentric_rChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_adconcentric_lChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_adconcentric_r} onValueChange={(isEnabled, hip_adconcentric_r_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_adconcentric_r_quad = 1;
                  } else {
                    hip_adconcentric_r_quad = 0;
                  }
                  toggleSwitchhip_adconcentric_r(); // Call the toggleSwitchBigToeL function
                  sethip_adconcentric_r_quad(hip_adconcentric_r_quad);
                }} />
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_adconcentric_l} onValueChange={(isEnabled, hip_adconcentric_l_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_adconcentric_l_quad = 1;
                  } else {
                    hip_adconcentric_l_quad = 0;
                  }
                  toggleSwitchhip_adconcentric_l(); // Call the toggleSwitchBigToeL function
                  sethip_adconcentric_l_quad(hip_adconcentric_l_quad);
                }} />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  Internal{'\n'}Rotation
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_internalrot_rChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_internalrot_lChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_internalrot_r} onValueChange={(isEnabled, hip_internalrot_r_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_internalrot_r_quad = 1;
                  } else {
                    hip_internalrot_r_quad = 0;
                  }
                  toggleSwitchhip_internalrot_r(); // Call the toggleSwitchBigToeL function
                  sethip_internalrot_r_quad(hip_internalrot_r_quad);
                }} />
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_internalrot_l} onValueChange={(isEnabled, hip_internalrot_l_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_internalrot_l_quad = 1;
                  } else {
                    hip_internalrot_l_quad = 0;
                  }
                  toggleSwitchhip_internalrot_l(); // Call the toggleSwitchBigToeL function
                  sethip_internalrot_l_quad(hip_internalrot_l_quad);
                }} />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  External{'\n'}Rotation
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_externalrot_rChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                <ThemeProvider theme={theme}>
                  <Slider
                    aria-label="Strength"
                    defaultValue={0}
                    valueLabelDisplay="on"
                    step={1}
                    min={0}
                    max={4}
                    onChange={(event, value) => handlehip_externalrot_lChange(event, value)}
                    color='primary'
                  />
                </ThemeProvider>

              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_externalrot_r} onValueChange={(isEnabled, hip_externalrot_r_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_externalrot_r_quad = 1;
                  } else {
                    hip_externalrot_r_quad = 0;
                  }
                  toggleSwitchhip_externalrot_r(); // Call the toggleSwitchBigToeL function
                  sethip_externalrot_r_quad(hip_externalrot_r_quad);
                }} />
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <SwitchAssistance isEnabled={isEnabledhip_externalrot_l} onValueChange={(isEnabled, hip_externalrot_l_quad) => {
                  console.log("Enabled value: ", isEnabled)

                  if (isEnabled) {
                    hip_externalrot_l_quad = 1;
                  } else {
                    hip_externalrot_l_quad = 0;
                  }
                  toggleSwitchhip_externalrot_l(); // Call the toggleSwitchBigToeL function
                  sethip_externalrot_l_quad(hip_externalrot_l_quad);
                }} />
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>

          {/* Report Table */}
          <DataTable style={styles.table}>
            <DataTable.Header>
              <DataTable.Title>Previous Session</DataTable.Title>
            </DataTable.Header>
            <DataTable.Row>
              <DataTable.Cell>Hip</DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Strength{'\n'}Right Side
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Strength{'\n'}Left Side
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Quad Dominance {'\n'} Right Side(Y/N)
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                <Text>
                  Quad Dominance {'\n'} Left Side(Y/N)
                </Text>
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  Abduction{'\n'}Eccentric
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abeccentric_r}
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abeccentric_l}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abeccentric_r_quad}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abeccentric_l_quad}
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  Abduction{'\n'}Concentric
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abconcentric_r}
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abconcentric_l}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abconcentric_r_quad}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_abconcentric_l_quad}
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>Adduction{'\n'}Eccentric</Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adeccentric_r}
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adeccentric_l}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adeccentric_r_quad}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adeccentric_l_quad}
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>Adduction{'\n'}Concentric</Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adconcentric_r}
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adconcentric_l}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adconcentric_r_quad}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_adconcentric_l_quad}
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  Internal{'\n'}Rotation
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_internalrot_r}
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_internalrot_l}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_internalrot_r_quad}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_internalrot_l_quad}
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={{ marginTop: 30 }}>
              <DataTable.Cell>
                <Text>
                  External{'\n'}Rotation
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_externalrot_r}
              </DataTable.Cell>
              <DataTable.Cell style={{ marginRight: 20, justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_externalrot_l}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_externalrot_r_quad}
              </DataTable.Cell>
              <DataTable.Cell style={{ justifyContent: 'center' }}>
                {dataHipNervousSystemForm.hip_externalrot_l_quad}
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>

          

          {/* Buttons to move accros the forms */}
          <View style={styles.rowContainerDownButtons}>
            <Button
              title="Previous Page"
              color="#989170"
              onPress={() => navigation.navigate('Nervous System Assessment Form - Hip Extension', NervousSystemFormHipExtension)}
              style={styles.button}
            />
            <Button
              title="Continue and save"
              color="#729982"
              onPress={() => {

                for (let key in resultsHip) {
                  if (resultsHip.hasOwnProperty(key)) {
                    console.log(`${key}: ${typeof resultsHip[key]}`);
                  }
                }
                const flagForNullValues = Object.values(resultsHip).every(value => value !== '');
                console.log(flagForNullValues)

                if (!flagForNullValues) {
                  console.log("There are missing values")
                }
                console.log(resultsHip)
                setDataHipNervousSystemForm(resultsHip)
                saveData();
                navigation.navigate('Nervous System Assessment Form - Hip Flexion', NervousSystemFormHipFlexion);

              }}
              style={styles.button}
            />
            <Button
              title="Continue without saving"
              color="#989170"
              onPress={() => navigation.navigate('Nervous System Assessment Form - Hip Flexion', NervousSystemFormHipFlexion)}
              style={styles.button}
            />
          </View>
              {/* Custom modals for web */}
              {Platform.OS === 'web' && (
                        <>
                          <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                        </>
                      )}

        </View>

      </ScrollView>
    </SafeAreaView>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width: '100%',
  },
  containerView: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width: '100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius: 10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize: 30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems: 'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems: 'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems: 'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize: 'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize: 'large',
  },
});

export default NervousSystemFormHip;
