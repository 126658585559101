import React, {useState, useEffect} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform } from 'react-native';
import { DataTable, Divider } from 'react-native-paper';
// import Sliders from '../components/Sliders';
// import Switch from '../components/Switch';
import NervousSystemFormFeet from './NSFormFeet';
import NervousSystemFormHipExtension from './NSFormHipExtension';
import Slider from '@mui/material/Slider';
import SwitchAssistance from '../components/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAthleteStore from '../varHelpers/athleteStore';
import axios from 'axios';
import RequestErrorModal from '../components/RequestErrorModal';

const NervousSystemFormAnkle = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();

  // Form variables and setters with initial values 
  const [ankle_inversion_r, setankle_inversion_r] = useState(0); 
  const [ankle_inversion_l, setankle_inversion_l] = useState(0); 
  const [ankle_eversion_r, setankle_eversion_r] = useState(0); 
  const [ankle_eversion_l, setankle_eversion_l] = useState(0); 
  const [ankle_dorsiflex_r, setankle_dorsiflex_r] = useState(0); 
  const [ankle_dorsiflex_l, setankle_dorsiflex_l] = useState(0); 
  const [ankle_plantarflex_r, setankle_plantarflex_r] = useState(0); 
  const [ankle_plantarflex_l, setankle_plantarflex_l] = useState(0); 

  // Check if switches are enabled or not
  const [isEnabledankle_inversion_r, setIsEnabledankle_inversion_r] = useState(false);
  const [isEnabledankle_inversion_l, setIsEnabledankle_inversion_l] = useState(false);
  const [isEnabledankle_eversion_r, setIsEnabledankle_eversion_r] = useState(false);
  const [isEnabledankle_eversion_l, setIsEnabledankle_eversion_l] = useState(false);
  const [isEnabledankle_dorsiflex_r, setIsEnabledankle_dorsiflex_r] = useState(false);
  const [isEnabledankle_dorsiflex_l, setIsEnabledankle_dorsiflex_l] = useState(false);
  const [isEnabledankle_plantarflex_r, setIsEnabledankle_plantarflex_r] = useState(false);
  const [isEnabledankle_plantarflex_l, setIsEnabledankle_plantarflex_l] = useState(false);


  // Functions to change the switch
  const toggleSwitchankle_inversion_r = () => setIsEnabledankle_inversion_r((previousState) => !previousState);
  const toggleSwitchankle_inversion_l = () => setIsEnabledankle_inversion_l((previousState) => !previousState);
  const toggleSwitchankle_eversion_r = () => setIsEnabledankle_eversion_r((previousState) => !previousState);
  const toggleSwitchankle_eversion_l = () => setIsEnabledankle_eversion_l((previousState) => !previousState);
  const toggleSwitchankle_dorsiflex_r = () => setIsEnabledankle_dorsiflex_r((previousState) => !previousState);
  const toggleSwitchankle_dorsiflex_l = () => setIsEnabledankle_dorsiflex_l((previousState) => !previousState);
  const toggleSwitchankle_plantarflex_r = () => setIsEnabledankle_plantarflex_r((previousState) => !previousState);
  const toggleSwitchankle_plantarflex_l = () => setIsEnabledankle_plantarflex_l((previousState) => !previousState);

  // Variables amd setters to manage the value of the switch
  const [ankle_inversion_r_quad, setankle_inversion_r_quad] = useState(0);
  const [ankle_inversion_l_quad, setankle_inversion_l_quad] = useState(0);
  const [ankle_eversion_r_quad, setankle_eversion_r_quad] = useState(0);
  const [ankle_eversion_l_quad, setankle_eversion_l_quad] = useState(0);
  const [ankle_dorsiflex_r_quad, setankle_dorsiflex_r_quad] = useState(0);
  const [ankle_dorsiflex_l_quad, setankle_dorsiflex_l_quad] = useState(0);
  const [ankle_plantarflex_r_quad, setankle_plantarflex_r_quad] = useState(0);
  const [ankle_plantarflex_l_quad, setankle_plantarflex_l_quad] = useState(0);

  // Color for the Slider (MUI)
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#989170',
      },
    },
  });

  // Array object where the results will be saved (testing)
  const resultsAnkle = {
    ankle_inversion_r: ankle_inversion_r,
    ankle_inversion_l: ankle_inversion_l,
    ankle_eversion_r: ankle_eversion_r,
    ankle_eversion_l: ankle_eversion_l,
    ankle_dorsiflex_r: ankle_dorsiflex_r,
    ankle_dorsiflex_l: ankle_dorsiflex_l,
    ankle_plantarflex_r: ankle_plantarflex_r,
    ankle_plantarflex_l: ankle_plantarflex_l,
    ankle_inversion_r_quad: ankle_inversion_r_quad,
    ankle_inversion_l_quad: ankle_inversion_l_quad,
    ankle_eversion_r_quad: ankle_eversion_r_quad,
    ankle_eversion_l_quad: ankle_eversion_l_quad,
    ankle_dorsiflex_r_quad: ankle_dorsiflex_r_quad,
    ankle_dorsiflex_l_quad: ankle_dorsiflex_l_quad,
    ankle_plantarflex_r_quad: ankle_plantarflex_r_quad,
    ankle_plantarflex_l_quad: ankle_plantarflex_l_quad,

  }

  // Receive event and take the value of the variable
  const handleankle_inversion_rChange = (event, value) => {
    setankle_inversion_r(value);
  };

  const handleankle_inversion_lChange = (event, value) => {
    setankle_inversion_l(value);
  };
  
  const handleankle_eversion_rChange = (event, value) => {
    setankle_eversion_r(value)
  };

  const handleankle_eversion_lChange = (event, value) => {
    setankle_eversion_l(value)
  };

  const handleankle_dorsiflex_rChange = (event, value) => {
    setankle_dorsiflex_r(value)
  };

  const handleankle_dorsiflex_lChange = (event, value) => {
    setankle_dorsiflex_l(value)
  };

  const handleankle_plantarflex_rChange = (event, value) => {
    setankle_plantarflex_r(value)
  };

  const handleankle_plantarflex_lChange = (event, value) => {
    setankle_plantarflex_l(value)
  };

  // Used to save the array object and pass it to another screen
  const { dataAthleteProfile, dataAnkleNervousSystemForm,setDataAnkleNervousSystemForm} = useData();

  const [data, setData] = useState('');

  const [requestError, setRequestError] = useState(false);

  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/nervsys';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }


        // Access hasNextPage from the response
        hasNextPage = response.data.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const nervSysEvals = response.data.nervSysEvals;
        lastRecord = nervSysEvals[nervSysEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);

  
      // Extract the total count and last page from the response headers
      // const totalCount = parseInt(firstPageResponse.headers['x-total-count'], 10);
      // console.log('Total Count:', totalCount);
      // const lastPage = Math.ceil(totalCount / 10); // Assuming a limit of 10 records per page
      // console.log('Last Page:', lastPage);
  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const nervSysEvals = lastPageResponse.data?.nervSysEvals;
      console.log("nervSysEvals:", nervSysEvals)

      if (nervSysEvals && nervSysEvals.length > 0) {
        const previousSessionValues = {
          ankle_inversion_r: nervSysEvals[0].ankle_inversion_r,
          ankle_inversion_l: nervSysEvals[0].ankle_inversion_l,
          ankle_eversion_r: nervSysEvals[0].ankle_eversion_r,
          ankle_eversion_l: nervSysEvals[0].ankle_eversion_l,
          ankle_dorsiflex_r: nervSysEvals[0].ankle_dorsiflex_r,
          ankle_dorsiflex_l: nervSysEvals[0].ankle_dorsiflex_l,
          ankle_plantarflex_r: nervSysEvals[0].ankle_plantarflex_r,
          ankle_plantarflex_l: nervSysEvals[0].ankle_plantarflex_l,
          ankle_inversion_r_quad: nervSysEvals[0].ankle_inversion_r_quad,
          ankle_inversion_l_quad: nervSysEvals[0].ankle_inversion_l_quad,
          ankle_eversion_r_quad: nervSysEvals[0].ankle_eversion_r_quad,
          ankle_eversion_l_quad: nervSysEvals[0].ankle_eversion_l_quad,
          ankle_dorsiflex_r_quad: nervSysEvals[0].ankle_dorsiflex_r_quad,
          ankle_dorsiflex_l_quad: nervSysEvals[0].ankle_dorsiflex_l_quad,
          ankle_plantarflex_r_quad: nervSysEvals[0].ankle_plantarflex_r_quad,
          ankle_plantarflex_l_quad: nervSysEvals[0].ankle_plantarflex_l_quad,
        };
        setDataAnkleNervousSystemForm(previousSessionValues);
        // console.log("Data Lower Ankle:", data);
        console.log("Previous session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
       
        console.error('Server responded with:', error.response.data);
        if(error.response.status !== 404){
          setRequestError(true)
        }
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        setRequestError(true)
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        setRequestError(true)
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('ankle-form', JSON.stringify(resultsAnkle));
      setData(resultsAnkle);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>

            {/* Athlete name and test title */}
                <Text style={styles.Cell}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subCell}>Nervous System Assessment Form - Strength Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>

                {/* Form Table */}
                <DataTable style={styles.table}>
                  <DataTable.Row>
                    <DataTable.Cell>Ankle</DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Right Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Left Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Right Side(Y/N)
                      </Text>
                   </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Left Side(Y/N)
                      </Text>
                     </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Inversion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => {
                                    handleankle_inversion_rChange(event, value)
                                    console.log("Ankle Inversion R:", ankle_inversion_r)}}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => {
                                    handleankle_inversion_lChange(event, value)
                                    console.log("Ankle Inversion L:", ankle_inversion_l)}}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_inversion_r} onValueChange={(isEnabled, ankle_inversion_r_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_inversion_r_quad = 1;
                                } else {
                                  ankle_inversion_r_quad = 0;
                                }
                                toggleSwitchankle_inversion_r(); 
                                setankle_inversion_r_quad(ankle_inversion_r_quad);
                          }}/>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_inversion_l} onValueChange={(isEnabled, ankle_inversion_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_inversion_l_quad = 1;
                                } else {
                                  ankle_inversion_l_quad = 0;
                                }
                                toggleSwitchankle_inversion_l(); 
                                setankle_inversion_l_quad(ankle_inversion_l_quad);
                          }}/>
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Eversion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => handleankle_eversion_rChange(event, value)}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => handleankle_eversion_lChange(event, value)}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_eversion_r} onValueChange={(isEnabled, ankle_eversion_r_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_eversion_r_quad = 1;
                                } else {
                                  ankle_eversion_r_quad = 0;
                                }
                                toggleSwitchankle_eversion_r(); 
                                setankle_eversion_r_quad(ankle_eversion_r_quad);
                          }}/>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_eversion_l} onValueChange={(isEnabled, ankle_eversion_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_eversion_l_quad = 1;
                                } else {
                                  ankle_eversion_l_quad = 0;
                                }
                                toggleSwitchankle_eversion_l();
                                setankle_eversion_l_quad(ankle_eversion_l_quad);
                          }}/>
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Dorsi Flexion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => handleankle_dorsiflex_rChange(event, value)}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => handleankle_dorsiflex_lChange(event, value)}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_dorsiflex_r} onValueChange={(isEnabled, ankle_dorsiflex_r_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_dorsiflex_r_quad = 1;
                                } else {
                                  ankle_dorsiflex_r_quad = 0;
                                }
                                toggleSwitchankle_dorsiflex_r(); 
                                setankle_dorsiflex_r_quad(ankle_dorsiflex_r_quad);
                          }}/>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_dorsiflex_l} onValueChange={(isEnabled, ankle_dorsiflex_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_dorsiflex_l_quad = 1;
                                } else {
                                  ankle_dorsiflex_l_quad = 0;
                                }
                                toggleSwitchankle_dorsiflex_l(); 
                                setankle_dorsiflex_l_quad(ankle_dorsiflex_l_quad);
                          }}/>
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Plantar Flexion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => handleankle_plantarflex_rChange(event, value)}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          <ThemeProvider theme={theme}>
                            <Slider 
                                  aria-label="Strength"
                                  defaultValue={0}
                                  valueLabelDisplay="on"
                                  step={1}
                                  min={0}   
                                  max={4}
                                  onChange={(event, value) => handleankle_plantarflex_lChange(event, value)}
                                  color='secondary'
                              />
                          </ThemeProvider>
                          
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_plantarflex_r} onValueChange={(isEnabled, ankle_plantarflex_r_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_plantarflex_r_quad = 1;
                                } else {
                                  ankle_plantarflex_r_quad = 0;
                                }
                                toggleSwitchankle_plantarflex_r(); 
                                setankle_plantarflex_r_quad(ankle_plantarflex_r_quad);
                          }}/>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        <SwitchAssistance isEnabled={isEnabledankle_plantarflex_l} onValueChange={(isEnabled, ankle_plantarflex_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  ankle_plantarflex_l_quad = 1;
                                } else {
                                  ankle_plantarflex_l_quad = 0;
                                }
                                toggleSwitchankle_plantarflex_l(); 
                                setankle_plantarflex_l_quad(ankle_plantarflex_l_quad);
                          }}/>
                    </DataTable.Cell>
                  </DataTable.Row>
                </DataTable>

                <DataTable style={styles.table}>
                <DataTable.Header>
                  <DataTable.Title>Previous Session</DataTable.Title>
                </DataTable.Header>
                  <DataTable.Row>
                    <DataTable.Cell>Ankle</DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Right Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Left Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Right Side(Y/N)
                      </Text>
                   </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Left Side(Y/N)
                      </Text>
                     </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Inversion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_inversion_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_inversion_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_inversion_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        {dataAnkleNervousSystemForm.ankle_inversion_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Eversion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Dorsi Flexion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Plantar Flexion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                         {dataAnkleNervousSystemForm.ankle_plantarflex_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_plantarflex_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_plantarflex_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        {dataAnkleNervousSystemForm.ankle_plantarflex_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                </DataTable>

              {/* Buttons to move across screens*/}
              <View style={styles.rowContainerDownButtons}>
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Feet', NervousSystemFormFeet)}
                        style={styles.button}
                    />
                    <Button
                        title="Continue and save"
                        color="#729982"
                        onPress={() => {
                          
                          for (let key in resultsAnkle) {
                            if (resultsAnkle.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsAnkle[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsAnkle).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(!flagForNullValues){
                            console.log("There are missing values") 
                          }
                          console.log("Results before:", resultsAnkle)
                          setDataAnkleNervousSystemForm(resultsAnkle)
                          console.log("Results after:", dataAnkleNervousSystemForm)
                          saveData();
                          console.log("Results after 2:", dataAnkleNervousSystemForm)
                          navigation.navigate('Nervous System Assessment Form - Hip Extension', NervousSystemFormHipExtension)
                          
                        }}
                        style={styles.button}
                    />
                    <Button
                        title="Continue without saving"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Hip Extension', NervousSystemFormHipExtension)}
                        style={styles.button}
                    />
                    {/* Custom modals for web */}
                    {Platform.OS === 'web' && (
                        <>
                          <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                        </>
                      )}
              </View>

                
          </View>
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  saveAndContinueButton:{
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    marginRight:20,
    borderBottomEndRadius:10,
    color:"#729982"
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    marginRight:20,
    borderBottomEndRadius:10,
  },
  Cell: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subCell: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
  table: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 15,
    flex:20,
    marginBottom:20,
  },
});

export default NervousSystemFormAnkle;
