import React, { useState, useEffect } from 'react';
import { Button, FlatList, Pressable, SafeAreaView, StyleSheet, Text, TextInput, View } from 'react-native';
import useTrainerStore from '../varHelpers/trainerStore';
import useTrainerListStore from '../varHelpers/trainerListStore';
import BackButton from '../components/BackButton';
import CreateButton from '../components/CreateButton';
import AccountErrorModal from '../components/AccountErrorModal';
  
  const TrainerSearch = ({ navigation }) => {

    const [firstNameSearch, setFirstNameSearch] = useState('');
    const [searchPressed, setSearchPressed] = useState(false);
  
    const currentUser = useTrainerStore((state) => state.currentUser);

    const trainer = useTrainerStore((state) => state.trainer);
    // const trainerUID = useTrainerStore((state) => state.userIDFire);
    const setTrainer = useTrainerStore((state) => state.setTrainer);
  
    const trainerList = useTrainerListStore((state) => state.trainerList);
    const setTrainerList = useTrainerListStore((state) => state.setTrainerList);

    const [visible, setVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleSearch = async () => {
      const options = {
        headers: {
          'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
          // 'api-key': process.env.API_KEY,
        },
      };
    
      const baseUrl = `https://gamereadyperformanceapp.com/api/trainers`;
    
      try 
      {
        let currpage = 1;
        let hasNextPage = true;
        let trainerList = [];
    
        while (hasNextPage) {
          const apiUrl = `${baseUrl}?firstName=${firstNameSearch}&page=${currpage}`;
          // const apiUrl = baseUrl
    
          const response = await fetch(apiUrl, options);
    
          if (response.ok) {
            const data = await response.json();
            const newTrainers = data.trainers;
    
            // Update trainerList with the trainers from the current page
            trainerList = [...trainerList, ...newTrainers];
    
            // Check for the next page
            hasNextPage = data.hasNextPage;
            currpage++;
          } else {
            setErrorMessage('No data found in response');
            setVisible(true);
            console.log('No data found in response');
            break;
          }
        }
    
        setTrainerList(trainerList);
        setSearchPressed(true);
        console.log(trainerList);
      } catch (error) {
        setErrorMessage('Error', error.message);
        setVisible(true);
        console.error('Error:', error.message);
      }
    };

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.leftSide}>
          <BackButton/>
        </View>
        <View style={styles.rightSide}>
          <CreateButton labeltext="Create Trainer" onPress={() => navigation.navigate('Trainer Create')}/>
        </View>
        {/* <Text>My Variable: {currentUser.user_id}</Text> */}
        <View style={styles.rowContainerHeader}>
          <Text style={styles.label}>Trainer Search:</Text>
          <TextInput
            style={styles.inputText}
            placeholder="First Name"
            value={firstNameSearch}
            onChangeText={(text) => setFirstNameSearch(text)}
            maxLength={25}
          />
          <View style={styles.searchButton}>
            <CreateButton labeltext="Search" 
              onPress={() => {handleSearch();}} 
            />
          </View>
        </View>
        {searchPressed ? (
          <View style={styles.flatListContainer}>
            <FlatList
              data={trainerList}
              keyExtractor={(item) => item.id.toString()}
              renderItem={({ item }) => (
                <Pressable
                  onPress={() => {
                    setTrainer(item);
                    console.log(item);
                    setTrainerList([]);
                    navigation.navigate('Trainer Profile', { userId: item.id });
                  }}
                >
                  <Text style={styles.itemStyle}>
                    {item.id}. {item.firstname} {item.lastname}
                  </Text>
                </Pressable>
              )}
            />
          </View>
        ) : (
          <View style={styles.pendingMessage}>
            <Text style={styles.pendingMessageText}>To load/refresh trainer list, press the search button. Use search filters to narrow down the list of trainers</Text>
          </View>
        )}
        <AccountErrorModal 
          text={errorMessage} 
          visible={visible} 
          onClose={() => setVisible(false)}
        />
      </SafeAreaView>
    );
  };

  const styles = StyleSheet.create({
    createButton: {
      marginTop: 10,
    },
    rowContainerHeader: {     
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginVertical: 20,
      marginHorizontal: 16,
      alignItems: 'center',
      // backgroundColor: "red"
    },
    labelCat: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 8,
    },
    flatListContainer: {
      flex: 1,
      width: '70%',
      justifyContent:'center',
      borderColor: 'gray',
      borderWidth: 2,
      borderRadius: 5,
    },
    pendingMessage: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      borderColor: '#e8e2d0',
      borderWidth: 1,
    },
    pendingMessageText: {
      alignItems: 'center',
      justifyContent:'center',
      fontSize: 18,
      fontWeight: 'bold',
    },
    itemStyle: {
      paddingVertical: 15,  // Increase the top and bottom padding
      paddingHorizontal: 10,  // Maintain the left and right padding
      fontSize: 16,
      borderBottomWidth: 1,
      borderBottomColor: '#c8c8c8',
      backgroundColor: '#e8e2d0',
      borderRadius: 10,
      marginVertical: 4,  // Increase the vertical margin
    },
    container: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    leftSide: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: 10,
      zIndex: 1,
    },
    rightSide: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 10,
      zIndex: 1,
    },
    label: {
      height: 20,
      padding: 3,
      marginRight: 10,
      marginBottom: 10,
      fontWeight: 'bold',
      fontSize: 18,
    },
    inputText: {
      padding: 10,
      borderColor: 'gray',
      borderWidth: 1,
      borderRadius: 5,
      fontSize: 16,
      width: '25%',
    },
    searchButton: {
      padding: 5,
      marginHorizontal: 5
    }
  });
  
  export default TrainerSearch;
  