import React, { useState, useEffect } from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform } from 'react-native';
import { DataTable, TextInput } from 'react-native-paper';
import { useData } from '../DataContext';
import SprintCapacityWarmUp from './SprintCapacityWarmUp';
import ThresholdFormWarmUp from './ThresholdFormWarmUp';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';
import useTrainerStore from '../varHelpers/trainerStore';
import { formatISODate, formatDOB } from '../varHelpers/formatDOB';
import { timeStampValidator, dateValidator } from '../varHelpers/dateValidator';
import OutOfRangeModal from '../components/OutofRangeModal';

const ThresholdFormRun = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();
  const {currentUser} = useTrainerStore();
  
   // Form variables and setters with initial values 
  const [data, setData] = useState([]);
  const [initial_speed, setinitial_speed] = useState('');
  const [PRE, setPRE] = useState('');
  const [ROR, setROR] = useState('');
  const [athleteGoals, setAthleteGoals] = useState({})
  const [athleteActuals, setAthleteActuals] = useState({});
  const [sprintPhosSpeed, setSprintPhosSpeed] = useState('');
  const [sprintPhosHR, setSprintPhosHR] = useState('');
  const [hour, setHour] = useState('');
  const [minutes, setMinutes] = useState('');

  const [showOutOfRange, setShowOutOfRange] = useState(false);
  const [requestError, setRequestError] = useState('');

  const [date, setdate] = useState({ value: '', error: '' });



  useEffect(() => {
    console.log('Before formatting:', date.value);

    const formattedDate = formatDOB(date.value);
    console.log('After formatting:', formattedDate);

    // Update the state with the formatted date
    setdate({ value: formattedDate, error: '' });

    // Perform the error check
    const error = dateValidator(formattedDate);
    setdate((prev) => ({ ...prev, error }));

    console.log('Error:', error);
  }, [date.value]);

  const initialResults = {
    athlete_id: athlete.id,
    site_id: 0,
    device_id:0,
    trainer_id: currentUser[0].id,
    sensor_id:0,
    exercise_timestamp: date.value+"T"+hour+":"+minutes,
    initial_speed: initial_speed,
    eval_type: "eval",
    PRE: PRE,
  }

  const formatTime = (seconds) => {
    const slotSize = 5;
    const slotIndex = Math.floor(seconds / slotSize);
    const slotStartSeconds = slotIndex * slotSize;
    const totalMinutes = Math.floor(slotStartSeconds / 60);
    const totalSeconds = slotStartSeconds % 60;

  return`${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
  };

  const {setDataThresholdRun, dataThresholdRun} = useData();

  

  const getHRFromPolar = async (eval_id, initialSpeed) => {
  
    console.log("enter to get polar hr");
    try {
        const apiUrl = `https://gamereadyperformanceapp.com/api/heartdata/${eval_id}`;
        const headers = {
            'Content-Type': 'application/json',
            'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        };

        console.log('API URL:', apiUrl);

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

          const responseData = await response.json();
          console.log("Full Response HR:", responseData);
          console.log("Going to get the hr");
          console.log("Duration:", responseData[0].duration);
          // setHrates(responseData[0].hr_data);  // Use setHrates to update the state

          
          const intervalTimeInSeconds = 5;
          const numberOfRows = Math.ceil(responseData[0].duration / intervalTimeInSeconds);
          // const formattedTime = formatTime(responseData[0].duration);
        
          // Initialize the counter and initial_speed
          let counter = 0;
          let speed = initialSpeed;
        
          const newRows = Array.from({ length: numberOfRows }, (_, index) => {
            // Check if 12 iterations have passed
            if (counter >= 12) {
              // Increment initial_speed and reset the counter
              speed += 0.2;
              counter = 0;
            }
        
            // Increment the counter for each iteration
            counter++;
            console.log("Speed:", initialSpeed);
            // setGeneratedSpeed(speed);

            const currentTime = index * intervalTimeInSeconds


        
            return {
              key: index,
              time: formatTime(currentTime),
              heartRate: parseFloat(responseData[0].hr_data[index * 5]).toFixed(1),
              speed: speed,
            };
          });
        
          setData(newRows);
      } catch (error) {
          // Handle errors
          setRequestError(true)
          console.error('Error getting heart rate data:', error);
      }
  };

  const getDataFromDb = async (athlete_id) => {
    let thresholdEvals;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/threshold';
      const parameters = `?athlete_id=${athlete_id}`;

      console.log("Athlete ID:", athlete_id)
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        
      };
      // console.log('API KEY:', process.env.API_KEY)

      // Continue making requests as long as there is a next page
        // Make the GET request using Axios
          const response = await fetch(`${apiUrl}?athlete_id=${athlete_id}&eval_type=eval`, {
            method: 'GET',
            headers: headers,
          });
  
          const responseData = await response.json()

          thresholdEvals = responseData.thresholdEvals;
          lastRecord = thresholdEvals[thresholdEvals.length - 1].id;
          console.log(`${lastRecord}: ${typeof lastRecord}`);
        

  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}`;

      // console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await fetch(lastPageUrl, {
        method: 'GET',
        headers: headers,
      });

      const lastPageResponseData = await lastPageResponse.json()
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponseData);
      // console.log("Last Page Response code:", lastPageResponseData[0].status);
      // console.log('Last Page Response:', lastPageResponseData[0]);
      // console.log("Initial Speed: ", lastPageResponseData[0].initial_speed);

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      thresholdEvals = lastPageResponseData;
      console.log("thresholdEvals:", thresholdEvals)

      if (thresholdEvals && thresholdEvals.thresholdEvals.length > 0 ) {
        const previousSessionValues = {
          initial_speed: thresholdEvals.thresholdEvals[0].initial_speed,
          PRE: thresholdEvals.thresholdEvals[0].PRE,
          max_hr: thresholdEvals.thresholdEvals[0].max_hr,
          max_hr_speed: thresholdEvals.thresholdEvals[0].max_hr_speed,
          aerobic_hr: thresholdEvals.thresholdEvals[0].aerobic_hr,
          anerobic_hr: thresholdEvals.thresholdEvals[0].anerobic_hr,
          lactic_hr: thresholdEvals.thresholdEvals[0].lactic_hr,
          aerobic_speed: thresholdEvals.thresholdEvals[0].aerobic_speed,
          anerobic_speed: thresholdEvals.thresholdEvals[0].anerobic_speed,
          lactic_speed: thresholdEvals.thresholdEvals[0].lactic_speed
        };
        setAthleteActuals(previousSessionValues);
        // console.log("Response to send:", athleteActuals);
        // setDataThresholdRun(previousSessionValues);
        console.log("Previous Session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      let thresholdEvalsLength = thresholdEvals && thresholdEvals.thresholdEvals ? thresholdEvals.thresholdEvals.length : undefined;
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404 && thresholdEvalsLength !== undefined){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        // setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        if(thresholdEvalsLength !== undefined){
          setRequestError(true)
        }
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;
        // console.log("Athlete ID = ", athlete_id)
        // Call your data fetching function here
        await getDataFromDb(athlete_id);
        await getGoals(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts


  const submitData = async (allResults) => {
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/threshold/';
  
      // Make the POST request using fetch
      console.log('Data to be sent:', allResults);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        },
        body: JSON.stringify(allResults),
      });
  
      if (!response.ok) {
        console.error('Network request failed');
        return; // Handle error accordingly
      }
  
      const responseData = await response.json(); // This returns a Promise
  
      // Access specific values from the resolved data
      const evalId = responseData[0].id;
      const sendToGet = responseData[0].eval_data_id;
      const aerobicHr = responseData[0].aerobic_hr;
      // ... and so on
      console.log("Send actual parameters:", responseData[0]);
      setAthleteActuals(responseData[0]);
      console.log('Eval ID:', evalId);
      console.log('Aerobic HR:', aerobicHr);
      // ... log other values as needed
  
      // Handle the response as needed
      console.log('Response code:', response.status);
      console.log('Response:', responseData);

      await getHRFromPolar(sendToGet, responseData[0].initial_speed);
      console.log("Athlet ID to send to Goals endpoint:", athlete.id);
      await getGoals(athlete.id);
    } catch (error) {
      // Handle errors
      setRequestError(true)
      console.log('Full response', error.response);
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /**
   * The function `getGoals` makes an asynchronous GET request to retrieve goals data for a specific
   * athlete using the provided athlete ID.
   * @param athlete_id - The `athlete_id` parameter is the ID of the athlete for whom you want to
   * retrieve goals. It is used to specify the athlete for which the goals should be fetched from the
   * API.
   */
  const getGoals = async (athlete_id) => {
    try {
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athlete/goals';

      console.log("Athlete ID:", athlete_id)
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
      const req = `${apiUrl}?athleteId=${athlete_id}`;
      console.log("Goals Request:",req)

      // Continue making requests as long as there is a next page
        // Make the GET request using Axios
        const response = await fetch(req, {
          method: 'GET',
          headers: headers,
        });

        console.log("Response before ok:", response)

        if (!response.ok) {
          console.error('Non-ok response:', response);
          // Retrieve the HTML error content
          const errorHtml = await response.text();
          console.error('Error HTML:', errorHtml);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }


        // const responseData = await response.json()
        const goals = await response.text();
        console.log("Goals", goals);
        // console.log("Goals Aerobic Base Range:", goals[0].AerobicBase)
        // console.log("Aerobic Base Range:", goals.categories.AerobicBase)
        const parsedGoals = JSON.parse(goals);
        setAthleteGoals(parsedGoals);
        setDataThresholdRun(parsedGoals);
        // console.log("Goals Aerobic Base:", parsedGoals.categories.AerobicBase.range);
        
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true);
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.containerView}>
        {/* Athlete's name and test form title */}
          <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
          <Text style={styles.subTitle}>Threshold Test - Run</Text>
          <Button style={styles.button} 
                        onPress={() => navigation.navigate('Athlete Profile')}
                        title="Go to : Athlete Profile"
                        color="#989170">Athlete Profile</Button>

          {/* Threshold Test form template HEART RATE WILL BE DISPLAYED AFTER THE SESSION */}
          <DataTable  style={styles.table}>
                        <DataTable.Header>
                            <DataTable.Title style={{ width: 200  }}>Energy System</DataTable.Title>
                            <DataTable.Title style={{justifyContent:'center'}}>Actual</DataTable.Title>
                            <DataTable.Title style={{justifyContent:'center'}}>Goals</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row style={{justifyContent:'space-evenly'}}>
                            <DataTable.Cell style={{ width: 200, flex:2}}>Energy System</DataTable.Cell>
                            <DataTable.Cell>Speed</DataTable.Cell>
                            <DataTable.Cell style={{ width: 200}}>Heart Rate</DataTable.Cell>
                            <DataTable.Cell>PRE</DataTable.Cell>
                            <DataTable.Cell>Speed</DataTable.Cell>
                            <DataTable.Cell style={{ width: 200 }}>Heart Rate</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={{ width: '100%', flex:2 }}>Aerobic Speed - Base</DataTable.Cell>
                            <DataTable.Cell>{parseFloat(athleteActuals.aerobic_speed).toFixed(1)}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.aerobic_hr}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.PRE}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AerobicBase?.range}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AerobicBase?.heartRate}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={{ width: '100%', flex:2 }}>Anaerobic Speed - Threshold</DataTable.Cell>
                            <DataTable.Cell>{parseFloat(athleteActuals.anerobic_speed).toFixed(1)}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.anerobic_hr}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.PRE}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AnaerobicBase?.range}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.AnaerobicBase?.heartRate}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={{ width: 200, flex:2 }}>Lactic Speed</DataTable.Cell>
                            <DataTable.Cell>{parseFloat(athleteActuals.lactic_speed).toFixed(1)}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.lactic_hr}</DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.PRE}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.Lactate?.range}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.Lactate?.heartRate}</DataTable.Cell>
                        </DataTable.Row>
                        {/* <DataTable.Row>
                            <DataTable.Cell style={{ width: 200, flex:2}}>Sprint Phosphocreatine</DataTable.Cell>
                            <DataTable.Cell>
                              <TextInput 
                                label="Enter Speed"
                                style={{width:125, backgroundColor: "#e8e2d0"}}
                                value={sprintPhosSpeed}
                                onChangeText={(text) => {
                                  const intValue = parseInt(text);
                                  if (text === "" || (!isNaN(intValue))) {
                                    setSprintPhosSpeed(text === "" ? "" : intValue);

                                  } else {
                                    console.log("Invalid input. Please enter a valid number within the range.");
                                  }
                                }}
                              />
                            </DataTable.Cell>
                            <DataTable.Cell>
                            <TextInput 
                                label="Enter HR"
                                style={{width:125, backgroundColor: "#e8e2d0"}}
                                value={sprintPhosHR}
                                onChangeText={(text) => {
                                  const intValue = parseInt(text);
                                  if (text === "" || (!isNaN(intValue))) {
                                    setSprintPhosHR(text === "" ? "" : intValue);

                                  } else {
                                    console.log("Invalid input. Please enter a valid number within the range.");
                                  }
                                }}
                              />
                            </DataTable.Cell>
                            <DataTable.Cell>{athleteActuals.PRE}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.SprintPhos?.range}</DataTable.Cell>
                            <DataTable.Cell>{dataThresholdRun.categories?.SprintPhos?.heartRate}</DataTable.Cell>
                        </DataTable.Row> */}
                    </DataTable>

                    <View style={styles.rowContainerHeader}>
            <Text style={styles.textStyleFirstRow}>Date</Text>
            <TextInput
              label="YYYY-MM-DD"
              returnKeyType="next"
              style={{backgroundColor: "#e8e2d0"}}
              value={date.value}
              onChangeText={(text) => setdate({ value: text, error: '' })}
              error={!!date.error}
              errorText={date.error}
              maxLength={10}
              keyboardType="numbers-and-punctuation"
            />
             <Text style={styles.textStyleFirstRow}>Hour</Text>
            <TextInput
              placeholder="Hour(XX) 24 hr"
              style={styles.textInputStyle}
              value={hour}
              onChangeText={(text) => {
              // Check for valid input format
              if (/^\d{0,2}$/.test(text)) {
                const intValue = parseInt(text, 10);

                // Check for valid hour range
                if (text === "" || (intValue >= 0 && intValue <= 23)) {
                  setHour(text);
                } else {
                  if (Platform.OS === 'web') {
                    // Handle out of range for web platform
                    setShowOutOfRange(true)
                  } else {
                    Alert.alert('Invalid input', 'Please enter a valid number within the range of 00 to 23.');
                  }
                  console.log('Invalid input. Please enter a valid number within the range of 00 to 23.');
                }
              } else {
                if (Platform.OS === 'web') {
                  // Handle invalid format for web platform
                  setShowOutOfRange(true)
                } else {
                  Alert.alert('Invalid input', 'Please enter a valid number.');
                }
                console.log('Invalid input. Please enter a valid number.');
              }
            }}
            />
             <Text style={styles.textStyleFirstRow}>Minutes</Text>
            <TextInput
              placeholder="Minutes(XX)"
              style={styles.textInputStyle}
              value={minutes}
              onChangeText={(text) => {
              // Check for valid input format
              if (/^\d{0,2}$/.test(text)) {
                const intValue = parseInt(text, 10);

                // Check for valid hour range
                if (text === "" || (intValue >= 0 && intValue <= 59)) {
                  setMinutes(text);
                } else {
                  if (Platform.OS === 'web') {
                    // Handle out of range for web platform
                    setShowOutOfRange(true)
                  } else {
                    Alert.alert('Invalid input', 'Please enter a valid number within the range of 00 to 23.');
                  }
                  console.log('Invalid input. Please enter a valid number within the range of 00 to 23.');
                }
              } else {
                if (Platform.OS === 'web') {
                  // Handle invalid format for web platform
                  setShowOutOfRange(true)
                } else {
                  Alert.alert('Invalid input', 'Please enter a valid number.');
                }
                console.log('Invalid input. Please enter a valid number.');
              }
            }}
            />
          </View>
          <View style={styles.rowContainerHeader}>
          <Text style={styles.textStyleFirstRow}>Initial Speed</Text>
            <TextInput
              label="Enter Initial Speed"
              style={styles.textInputStyle}
              value={initial_speed}
              onChangeText={(text) => {
                const intValue = parseInt(text);
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                  setinitial_speed(text === "" ? "" : intValue);

                } else {
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            />
            <Text style={styles.textStyleFirstRow}>Enter PRE</Text>
            <TextInput
              label="Enter PRE"
              style={styles.textInputStyle}
              value={PRE}
              onChangeText={(text) => {
                const intValue = parseInt(text);
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                  setPRE(text === "" ? "" : intValue);

                } else {
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            />
            {/* <Text style={styles.textStyleFirstRow}>Enter Rate of Recovery</Text>
            <TextInput
              label="Enter Rate of Recovery"
              style={styles.textInputStyle}
              value={ROR}
              onChangeText={(text) => {
                const intValue = parseInt(text);
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                  setROR(text === "" ? "" : intValue);

                } else {
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            /> */}
          </View>
          <Text style={styles.subTitle}>Run</Text>
          {/* Table to be generated */}
          <DataTable style={styles.table}>
            <DataTable.Header>
              <DataTable.Title>Time</DataTable.Title>
              <DataTable.Title>Heart Rate</DataTable.Title>
              <DataTable.Title>Speed</DataTable.Title>
              {/* <DataTable.Title>PRE</DataTable.Title> */}
            </DataTable.Header>
            {data.map((item) => (
              <DataTable.Row key={item.key}>
                <DataTable.Cell>{item.time}</DataTable.Cell>
                <DataTable.Cell>
                  {item.heartRate}
                </DataTable.Cell>
                <DataTable.Cell>
                  {parseFloat(item.speed).toFixed(1)}
                </DataTable.Cell>
                {/* <DataTable.Cell> */}
                {/* <TextInput
                    label="Enter PRE"
                    style={styles.textInputStyle}
                    value={item.tablePRE}
                    onChangeText={(text) => {
                      const updatedData = data.map((rowData) =>
                        rowData.key === item.key ? { ...rowData, PRE: text } : rowData
                      );
                      const intValue = parseInt(text)
                      if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                        setData(updatedData);
                      } else {
                        // Handle the case where the input is not a valid integer
                        // You may show an error message or take other appropriate action
                        console.log("Invalid input. Please enter a valid number within the range.");
                      }


                    }}
                  /> */}
                {/* </DataTable.Cell> */}
              </DataTable.Row>
            ))}
          </DataTable>

          <View style={styles.rowContainerDownButtons}>
          {/* Buttons to navigate among screens and generate the table */}
            <Button
              title="Previous Page"
              color="#989170"
              onPress={() => navigation.navigate('Threshold Test - Warm Up', ThresholdFormWarmUp)}
            />
            <Button
              title="Get heart rate and save"
              color="#729982"
              onPress={async() => {
                setDataThresholdRun(initialResults);
                await submitData(initialResults)}}
            />
            <Button
              title="Continue - Sprint Capacity"
              color="#989170"
              onPress={() => {
                console.log(data)
                console.log(initialResults)
                navigation.navigate('Sprint Capacity - Warm Up at 85%', SprintCapacityWarmUp)}}
            />
          </View>
          {/* Custom modals for web */}
          {Platform.OS === 'web' && (
            <>
              <OutOfRangeModal visible={showOutOfRange} onClose={() => setShowOutOfRange(false)} />
              <RequestErrorModal visible={requestError} text={"Heart Rate Data not available. Check the date or wait a couple of minutes for the data to be uplodaded to the Polar"} onClose={() => setRequestError(false)} />
            </>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerView: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems: 'center',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
  },
  textStyleFirstRow: {
    flex: 1,
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize: 20,
  },
  textInputStyle: {
    backgroundColor: "#e8e2d0",
    color: "black",
    borderBlockEndColor: 'black',
  },
  table: {
    borderWidth: 1,
    borderColor: 'black',
    width: '100%',
    fontFamily: 'Roboto',
  },
});

export default ThresholdFormRun;
