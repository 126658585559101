import React, { useState, useEffect } from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform} from 'react-native';
import { DataTable, TextInput } from 'react-native-paper';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAthleteStore from '../varHelpers/athleteStore';
import RequestErrorModal from '../components/RequestErrorModal';
import useTrainerStore from '../varHelpers/trainerStore';
import { formatDOB } from '../varHelpers/formatDOB';
import { dateValidator } from '../varHelpers/dateValidator';
import OutOfRangeModal from '../components/OutofRangeModal';

const SprintCapacityWarmUp = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();
  const {currentUser} = useTrainerStore();

  const [requestError, setRequestError] = useState('');
  
  // Form variables and setters with initial values 
  const [data, setData] = useState([]);
  const [speed, setspeed] = useState('');
  const [PRE, setPRE] = useState('');
  const [ROR, setROR] = useState('');
  const {dataAthleteProfile, dataSprintCapacityWarmUp,setDataSprintCapacityWarmUp} = useData();
  const [maxHR, setmaxHR] = useState('')
  const [hour, setHour] = useState('');
  const [minutes, setMinutes] = useState('');

  const [showOutOfRange, setShowOutOfRange] = useState(false);

  const [date, setdate] = useState({ value: '', error: '' });

  useEffect(() => {
    console.log('Before formatting:', date.value);

    const formattedDate = formatDOB(date.value);
    console.log('After formatting:', formattedDate);

    // Update the state with the formatted date
    setdate({ value: formattedDate, error: '' });

    // Perform the error check
    const error = dateValidator(formattedDate);
    setdate((prev) => ({ ...prev, error }));

    console.log('Error:', error);
  }, [date.value]);
  
  const formatTime = (seconds) => {
    const slotSize = 5;
    const slotIndex = Math.floor(seconds / slotSize);
    const slotStartSeconds = slotIndex * slotSize;
    const totalMinutes = Math.floor(slotStartSeconds / 60);
    const totalSeconds = slotStartSeconds % 60;

  return`${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
  };

   // Array object where the results will be saved (testing)
  const initialResults = {
    athlete_id: athlete.id,
    trainer_id: currentUser[0].id,
    sensor_id: 0,
    site_id: 0,
    device_id: 0,
    eval_type: "warmup",
    speed: speed,
    exercise_timestamp: date.value+"T"+hour+":"+minutes,
    PRE: PRE,
    sprint_phospho: 1,
    ROR:ROR
  }

  const getDataFromDb = async (athlete_id) => {
    let sprintEvals;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/sprint';
      const parameters = `?athlete_id=${athlete_id}`;

      console.log("Athlete ID:", athlete_id)
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        
      };
      // console.log('API KEY:', process.env.API_KEY)

      // Continue making requests as long as there is a next page
        // Make the GET request using Axios
        const response = await fetch(`${apiUrl}?athlete_id=${athlete_id}&test_type=${"sprint"}&eval_type=${"warmup"}`, {
          method: 'GET',
          headers: headers,
        });

        console.log("The response:", response)


        const responseData = await response.json()
        console.log("response data;", responseData)

        sprintEvals = responseData.sprintEvals;
        console.log("Sprint Evals:", sprintEvals)
        lastRecord = sprintEvals[sprintEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&test_type=${"sprint"}&eval_type=${"warmup"}`;

      // console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await fetch(lastPageUrl, {
        method: 'GET',
        headers: headers,
      });

      const lastPageResponseData = await lastPageResponse.json()
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponseData);

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      sprintEvals = lastPageResponseData;
      console.log("sprintEvals:", sprintEvals)

      if (sprintEvals) {
        const previousSessionValues = {
          speed: sprintEvals.sprintEvals[0].speed,
          PRE: sprintEvals.sprintEvals[0].PRE,
          hr_max: sprintEvals.sprintEvals[0].hr_max,
          hr_max_time: sprintEvals.sprintEvals[0].hr_max_time,
          target_hr: sprintEvals.sprintEvals[0].target_hr,
          target_hr_time: sprintEvals.sprintEvals[0].target_hr_time,
          ROR: sprintEvals.sprintEvals[0].ROR,
        };
        console.log("HR MAX:", previousSessionValues.hr_max);
        setmaxHR(previousSessionValues.hr_max);
        setDataSprintCapacityWarmUp(previousSessionValues);
        console.log("PRevious Session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
      let sprintEvalsLength = sprintEvals && sprintEvals.sprintEvals ? sprintEvals.sprintEvals.length : undefined;
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404 && sprintEvalsLength !== undefined){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        // setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        if(sprintEvalsLength !== undefined){
          setRequestError(true)
        }
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  const getHRFromPolar = async (eval_id, initial_speed) => {
  
    console.log("enter to get polar hr");
    try {
        const apiUrl = `https://gamereadyperformanceapp.com/api/heartdata/${eval_id}`;
        const headers = {
            'Content-Type': 'application/json',
            'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        };

        console.log('API URL:', apiUrl);

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
        });

        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`);
        // }

        const responseData = await response.json();
        console.log("Full Response HR:", responseData);
        console.log("Going to get the hr");
        console.log("Duration:", responseData[0].duration);
        // setHrates(responseData[0].hr_data);  // Use setHrates to update the state

        
        const intervalTimeInSeconds = 5;
        const numberOfRows = Math.ceil(responseData[0].duration / intervalTimeInSeconds);
        // const formattedTime = formatTime(responseData[0].duration);
      
        // Initialize the counter and speed
        let counter = 0;
        let speedTable = initial_speed;
      
        const newRows = Array.from({ length: numberOfRows }, (_, index) => {
          // Check if 12 iterations have passed
          if (counter >= 12) {
            // Increment speed and reset the counter
            speedTable += 0.2;
            counter = 0;
          }
      
          // Increment the counter for each iteration
          counter++;
          console.log("Speed Table:", speedTable);

          const currentTime = index * intervalTimeInSeconds


      
          return {
            key: index,
            time: formatTime(currentTime),
            heartRate: parseFloat(responseData[0].hr_data[index * 5]).toFixed(1),
            speed: speedTable,
            pre: '',
          };
        });
      
        setData(newRows);
        // setDataSprintCapacityWarmUp(saveData());
        // console.log("HR:", hrates);
    } catch (error) {
        // Handle errors
        setRequestError(true)
        console.error('Error getting heart rate data:', error);
    }
};

useEffect(() => {
  // Retrieve data from AsyncStorage when the component mounts
  const fetchData = async () => {
    try {
      // You can get athlete_id, trainer_id, and site_id from wherever you need
      const athlete_id = athlete.id;
      // console.log("Athlete ID = ", athlete_id)
      // Call your data fetching function here
      await getDataFromDb(athlete_id);
    } catch (error) {
      setRequestError(true)
      console.error('Error fetching data on component mount:', error);
      // Handle errors if needed
    }
  };

  fetchData();
}, []); // Empty dependency array ensures this effect runs once when the component mounts

  const submitData = async (allResults) => {
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/sprint';
  
      // Make the POST request using fetch
      console.log('Data to be sent:', allResults);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
        },
        body: JSON.stringify(allResults),
      });
  
      if (!response.ok) {
        console.error('Network request failed');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json(); // This returns a Promise
  
      // Access specific values from the resolved data
      const evalId = responseData[0].id;
      const sendToGet = responseData[0].eval_data_id;
      const aerobicHr = responseData[0].target_hr;
      // ... and so on
  
      console.log('Eval ID:', evalId);
      console.log('Aerobic HR:', aerobicHr);
      console.log('Submit Speed:', responseData[0].speed)
      // ... log other values as needed
  
      // Handle the response as needed
      console.log('Response code:', response.status);
      console.log('Response:', responseData);

      setmaxHR(responseData[0].hr_max);
      setDataSprintCapacityWarmUp(responseData[0]);
      getHRFromPolar(sendToGet, responseData[0].speed);
      
    } catch (error) {
      // Handle errors
      setRequestError(true)
      console.log('Full response', error.response);
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('sprint-capacity-warm-up', JSON.stringify(initialResults));
      setDataSprintCapacityWarmUp(initialResults);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.containerView}>
        {/* Athlete's name and test form title */}
          <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
          <Text style={styles.subTitle}>Sprint Capacity - Warm Up at 85%</Text>
          <Button style={styles.button} 
                        onPress={() => navigation.navigate('Athlete Profile')}
                        title="Go to : Athlete Profile"
                        color="#989170">Athlete Profile</Button>


          {/* Form initial partr - table */}
          <DataTable style={styles.table}>
            <DataTable.Header>
              <DataTable.Title style={{justifyContent:'center'}}>Previous Session Report</DataTable.Title>
            </DataTable.Header>
            <DataTable.Row>
              <DataTable.Cell  style={{justifyContent:'space-evenly'}}>Max Speed</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>{parseFloat(dataSprintCapacityWarmUp.speed).toFixed(1)}</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell  style={{justifyContent:'space-evenly'}}>Max Heart Rate</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>{maxHR}</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell  style={{justifyContent:'space-evenly'}}>PRE</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>{dataSprintCapacityWarmUp.PRE}</DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>Rate of Recovery</DataTable.Cell>
              <DataTable.Cell style={{justifyContent:'space-evenly'}}>{dataSprintCapacityWarmUp.ROR}</DataTable.Cell>
            </DataTable.Row>
          </DataTable>

          {/* Current initial parameters implementatiob */}
          <View style={styles.rowContainerHeader}>
          <Text style={styles.textStyleFirstRow}>Date</Text>
            <TextInput
              label="YYYY-MM-DD"
              returnKeyType="next"
              style={{backgroundColor: "#e8e2d0"}}
              value={date.value}
              onChangeText={(text) => setdate({ value: text, error: '' })}
              error={!!date.error}
              errorText={date.error}
              maxLength={10}
              keyboardType="numbers-and-punctuation"
            />
             <Text style={styles.textStyleFirstRow}>Hour</Text>
            <TextInput
              placeholder="Hour(XX) 24 hr"
              style={styles.textInputStyle}
              value={hour}
              onChangeText={(text) => {
              // Check for valid input format
              if (/^\d{0,2}$/.test(text)) {
                const intValue = parseInt(text, 10);

                // Check for valid hour range
                if (text === "" || (intValue >= 0 && intValue <= 23)) {
                  setHour(text);
                } else {
                  if (Platform.OS === 'web') {
                    // Handle out of range for web platform
                    setShowOutOfRange(true)
                  } else {
                    Alert.alert('Invalid input', 'Please enter a valid number within the range of 00 to 23.');
                  }
                  console.log('Invalid input. Please enter a valid number within the range of 00 to 23.');
                }
              } else {
                if (Platform.OS === 'web') {
                  // Handle invalid format for web platform
                  setShowOutOfRange(true)
                } else {
                  Alert.alert('Invalid input', 'Please enter a valid number.');
                }
                console.log('Invalid input. Please enter a valid number.');
              }
            }}
            />
             <Text style={styles.textStyleFirstRow}>Minutes</Text>
            <TextInput
              placeholder="Minutes(XX)"
              style={styles.textInputStyle}
              value={minutes}
              onChangeText={(text) => {
              // Check for valid input format
              if (/^\d{0,2}$/.test(text)) {
                const intValue = parseInt(text, 10);

                // Check for valid hour range
                if (text === "" || (intValue >= 0 && intValue <= 59)) {
                  setMinutes(text);
                } else {
                  if (Platform.OS === 'web') {
                    // Handle out of range for web platform
                    setShowOutOfRange(true)
                  } else {
                    Alert.alert('Invalid input', 'Please enter a valid number within the range of 00 to 23.');
                  }
                  console.log('Invalid input. Please enter a valid number within the range of 00 to 23.');
                }
              } else {
                if (Platform.OS === 'web') {
                  // Handle invalid format for web platform
                  setShowOutOfRange(true)
                } else {
                  Alert.alert('Invalid input', 'Please enter a valid number.');
                }
                console.log('Invalid input. Please enter a valid number.');
              }
            }}
            />
          </View>
          <View style={styles.rowContainerHeader}>
          <Text style={styles.textStyleFirstRow}>Initial Speed</Text>
            <TextInput
              label="Enter Initial Speed"
              style={styles.textInputStyle}
              value={speed}
              onChangeText={(text) => {
                const intValue = parseInt(text);
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 90)) {
                  setspeed(text === "" ? "" : intValue);

                } else {
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            />
            <Text style={styles.textStyleFirstRow}>Enter PRE</Text>
            <TextInput
              label="Enter PRE"
              style={styles.textInputStyle}
              value={PRE}
              onChangeText={(text) => {
                const intValue = parseInt(text);
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                  setPRE(text === "" ? "" : intValue);

                } else {
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            />
            {/* <Text style={styles.textStyleFirstRow}>Enter Rate of Recovery</Text>
            <TextInput
              label="Enter Rate of Recovery"
              style={styles.textInputStyle}
              value={ROR}
              onChangeText={(text) => {
                const intValue = parseInt(text);
                if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                  setROR(text === "" ? "" : intValue);

                } else {
                  console.log("Invalid input. Please enter a valid number within the range.");
                }
              }}
            /> */}
          </View>
          
          <Text style={styles.subTitle}>Warm Up</Text>
          {/* Table titles, headers and rows to be generated with the data assigned */}
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Time</DataTable.Title>
              <DataTable.Title>Heart Rate</DataTable.Title>
              <DataTable.Title>Speed</DataTable.Title>
              {/* <DataTable.Title>PRE</DataTable.Title> */}
            </DataTable.Header>
            {data.map((item) => (
              <DataTable.Row key={item.key}>
                <DataTable.Cell>{item.time}</DataTable.Cell>
                <DataTable.Cell>
                  {item.heartRate}
                </DataTable.Cell>
                <DataTable.Cell>
                  {parseFloat(item.speed).toFixed(1)}
                </DataTable.Cell>
                {/* <DataTable.Cell>
                <TextInput
                    label="Enter PRE"
                    style={styles.textInputStyle}
                    value={item.pre}
                    onChangeText={(text) => {
                      const updatedData = data.map((rowData) =>
                        rowData.key === item.key ? { ...rowData, pre: text } : rowData
                      );
                      const intValue = parseInt(text)
                      if (text === "" || (!isNaN(intValue) && intValue >= 0 && intValue <= 10)) {
                        setData(updatedData);
                      } else {
                        // Handle the case where the input is not a valid integer
                        // You may show an error message or take other appropriate action
                        console.log("Invalid input. Please enter a valid number within the range.");
                      }


                    }}
                  />
                </DataTable.Cell> */}
              </DataTable.Row>
            ))}
          </DataTable>
          
          <View style={styles.rowContainerDownButtons}>
          {/* Buttons to navigate among screens and generate table */}
            <Button
              title="Previous Page"
              color="#989170"
              onPress={() =>
                navigation.navigate(
                  'Threshold Test - Run'
                )
              }
              style={styles.button}
            />
            <Button
              title="Get heart rate and save"
              color="#729982"
              onPress={async () => {
                // addRows(totalTimeInSeconds)
                setDataSprintCapacityWarmUp(initialResults);
                saveData();
                console.log("Data Sprint Capacity:", dataSprintCapacityWarmUp);
                console.log("Speed Data Sprint Capacity:", dataSprintCapacityWarmUp.speed)
                submitData(initialResults)
                // console.log("Time after calculation:", resultssprintTimes);
                // console.log("Speed after calculation: ", resultssprintSpeed)
                // console.log("sprint after calculation: ", resultsHRsprints)
                }}
              style={styles.button}
            />
            <Button
              title="Continue - Sprint Capacity Run"
              color="#989170"
              onPress={() => {
                console.log(data)
                console.log(initialResults)
                setDataSprintCapacityWarmUp(data)
                navigation.navigate('Sprint Capacity - Run')}}
              style={styles.button}
            />
            
          </View>
          {/* Custom modals for web */}
          {/* Custom modals for web */}
          {Platform.OS === 'web' && (
            <>
              <OutOfRangeModal visible={showOutOfRange} onClose={() => setShowOutOfRange(false)} />
              <RequestErrorModal visible={requestError} text={"Heart Rate Data not available. Check the date or wait a couple of minutes for the data to be uplodaded to the Polar"} onClose={() => setRequestError(false)} />
            </>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerView: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius: 10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems: 'center',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  textStyleFirstRow: {
    flex: 1,
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize: 20,
  },
  textInputStyle: {
    backgroundColor: "#e8e2d0",
    color: "black",
    borderBlockEndColor: 'black',
  },
  table: {
    borderWidth: 1,
    borderColor: 'black',
    width: '100%',
  },
});

export default SprintCapacityWarmUp;
