import React, {useEffect, useState} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform } from 'react-native';
import { DataTable, Divider } from 'react-native-paper';
import Sliders from '../components/Sliders';
import Switch from '../components/Switch';
import { useData } from '../DataContext';
import NervousSystemReportFeet from './NSFormFeet';
import NervousSystemReportHipExtension from './NSReportHipExtension';
import useAthleteStore from '../varHelpers/athleteStore';
import axios from 'axios';
import RequestErrorModal from '../components/RequestErrorModal';

const NervousSystemReportAnkle = ({ navigation }) => {
  const { athlete, setAthlete } = useAthleteStore();

  const [requestError, setRequestError] = useState(false)

  // Used to get the array object from another screen
  const {dataAthleteProfile, dataAnkleNervousSystemForm, setDataAnkleNervousSystemForm} = useData();

  /* The above code is a useEffect hook in JavaScript. It is used to fetch data from a database
  (presumably using the `getDataFromDb` function) when a component mounts. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `getDataFromDb` makes a series of GET requests to retrieve data from a database API,
   * paginating through the results and handling the responses accordingly.
   * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
   * you want to retrieve data from the database. This ID is used in the API request to fetch the
   * athlete's evaluation data.
   */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/nervsys';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }


        // Access hasNextPage from the response
        hasNextPage = responseData.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const nervSysEvals = response.data.nervSysEvals;
        lastRecord = nervSysEvals[nervSysEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);
  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const nervSysEvals = lastPageResponse.data?.nervSysEvals;
      console.log("nervSysEvals:", nervSysEvals)

      if (nervSysEvals && nervSysEvals.length > 0) {
        const previousSessionValues = {
          ankle_inversion_r: nervSysEvals[0].ankle_inversion_r,
          ankle_inversion_l: nervSysEvals[0].ankle_inversion_l,
          ankle_eversion_r: nervSysEvals[0].ankle_eversion_r,
          ankle_eversion_l: nervSysEvals[0].ankle_eversion_l,
          ankle_dorsiflex_r: nervSysEvals[0].ankle_dorsiflex_r,
          ankle_dorsiflex_l: nervSysEvals[0].ankle_dorsiflex_l,
          ankle_plantarflex_r: nervSysEvals[0].ankle_plantarflex_r,
          ankle_plantarflex_l: nervSysEvals[0].ankle_plantarflex_l,
          ankle_inversion_r_quad: nervSysEvals[0].ankle_inversion_r_quad,
          ankle_inversion_l_quad: nervSysEvals[0].ankle_inversion_l_quad,
          ankle_eversion_r_quad: nervSysEvals[0].ankle_eversion_r_quad,
          ankle_eversion_l_quad: nervSysEvals[0].ankle_eversion_l_quad,
          ankle_dorsiflex_r_quad: nervSysEvals[0].ankle_dorsiflex_r_quad,
          ankle_dorsiflex_l_quad: nervSysEvals[0].ankle_dorsiflex_l_quad,
          ankle_plantarflex_r_quad: nervSysEvals[0].ankle_plantarflex_r_quad,
          ankle_plantarflex_l_quad: nervSysEvals[0].ankle_plantarflex_l_quad,
        };
        setDataAnkleNervousSystemForm(previousSessionValues);
        // console.log("Data Lower Ankle:", data);
        console.log("Previous session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            {/* Athlet's Name and Report Title */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Report - Strength Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Report')}
                        title="Go to : Reports Section"
                        color="#989170">Reports Section</Button>

                {/* Table Report */}
                <DataTable style={styles.table}>
                  <DataTable.Row>
                    <DataTable.Cell>Ankle</DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Right Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                          Strength{'\n'}Left Side
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Right Side(Y/N)
                      </Text>
                   </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <Text>
                        Quad Dominance {'\n'} Left Side(Y/N)
                      </Text>
                     </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Inversion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_inversion_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_inversion_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_inversion_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        {dataAnkleNervousSystemForm.ankle_inversion_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Eversion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_eversion_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Dorsi Flexion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_dorsiflex_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Plantar Flexion</DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                         {dataAnkleNervousSystemForm.ankle_plantarflex_r}
                      </DataTable.Cell>
                      <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_plantarflex_l}
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                          {dataAnkleNervousSystemForm.ankle_plantarflex_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        {dataAnkleNervousSystemForm.ankle_plantarflex_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>
                </DataTable>

                {/* Old way of doing report, not aligned correctly */}
                {/* <View style={styles.rowContainerHeader}>
                    <Text style={styles.textStyleFirstRow}>Ankle</Text>
                    <Text style={styles.textStyleFirstRow}>Side</Text>
                    <Text style={styles.textStyleFirstRow}>Strength</Text>
                    <Text style={styles.textStyleFirstRow}>Quad Dominance (Y/N)</Text>
                </View>
                <View style={styles.rowContainer}>
                  <View style={styles.columnContainer}>
                      <Text style={styles.textStyle}>Inversion</Text>
                  </View>
                  <View style={styles.columnContainer}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.textStyle}>R</Text>
                            <Text style={styles.textStyle}>4</Text>
                            <Text style={styles.textStyle}>Yes</Text>
                        </View>
                        <View style={styles.rowContainer}>
                          <Text style={styles.textStyle}>L</Text>
                          <Text style={styles.textStyle}>4</Text>
                          <Text style={styles.textStyle}>Yes</Text>
                        </View>
                </View>
              </View>
                
                <View style={styles.rowContainer}>
                  <View style={styles.columnContainer}>
                      <Text style={styles.textStyle}>Eversion</Text>
                  </View>
                  <View style={styles.columnContainer}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.textStyle}>R</Text>
                            <Text style={styles.textStyle}>4</Text>
                          <Text style={styles.textStyle}>Yes</Text>
                        </View>
                        <View style={styles.rowContainer}>
                          <Text style={styles.textStyle}>L</Text>
                          <Text style={styles.textStyle}>4</Text>
                          <Text style={styles.textStyle}>Yes</Text>
                        </View>
                </View>
              </View>

              <View style={styles.rowContainer}>
                  <View style={styles.columnContainer}>
                      <Text style={styles.textStyle}>Dorsi Flex</Text>
                  </View>
                  <View style={styles.columnContainer}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.textStyle}>R</Text>
                            <Text style={styles.textStyle}>4</Text>
                            <Text style={styles.textStyle}>Yes</Text>
                        </View>
                        <View style={styles.rowContainer}>
                          <Text style={styles.textStyle}>L</Text>
                          <Text style={styles.textStyle}>4</Text>
                          <Text style={styles.textStyle}>Yes</Text>
                        </View>
                </View>
                
              </View>

              <View style={styles.rowContainer}>
                  <View style={styles.columnContainer}>
                      <Text style={styles.textStyle}>Plantar Flex</Text>
                  </View>
                  <View style={styles.columnContainer}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.textStyle}>R</Text>
                            <Text style={styles.textStyle}>4</Text>
                            <Text style={styles.textStyle}>Yes</Text>
                        </View>
                        <View style={styles.rowContainer}>
                          <Text style={styles.textStyle}>L</Text>
                          <Text style={styles.textStyle}>4</Text>
                          <Text style={styles.textStyle}>Yes</Text>
                        </View>
                </View>
                
              </View> */}
              <View style={styles.rowContainerDownButtons}>
              {/* Button navigation among screens */}
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Report - Nervous Assessment System : Feet', NervousSystemReportFeet)}
                        style={styles.button}
                    />
                    <Button
                        title="Next Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Report - Nervous Assessment System : Hip Extension', NervousSystemReportHipExtension)}
                        style={styles.button}
                    />
              </View>
              {/* Custom modals for web */}
              {Platform.OS === 'web' && (
                                <>
                                  <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                                </>
                              )}
                
          </View>
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
});

export default NervousSystemReportAnkle;
