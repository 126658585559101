import React, {useState, useEffect} from 'react';
import { View, Button, Text, StyleSheet, SafeAreaView, ScrollView, Platform } from 'react-native';
import { DataTable, Divider } from 'react-native-paper';
import NervousSystemFormHip from './NSFormHip';
import NervousSystemFunctionalMovements from './NSFormFunctionalMovements';
import Slider from '@mui/material/Slider';
import SwitchAssistance from '../components/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useData } from '../DataContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAthleteStore from '../varHelpers/athleteStore';
import axios from 'axios';
import RequestErrorModal from '../components/RequestErrorModal';
import useTrainerStore from '../varHelpers/trainerStore';

const NervousSystemFormHipFlexion = ({ navigation }) => {

  const { athlete, setAthlete } = useAthleteStore();
  const {currentUser} = useTrainerStore();

  const [requestError, setRequestError] = useState(false);
  
  // Form variables and setters with initial values 
  const [quad_eccentric_r, setquad_eccentric_r] = useState(0);
  const [quad_eccentric_l, setquad_eccentric_l] = useState(0);
  const [quad_concentric_r, setquad_concentric_r] = useState(0);
  const [quad_concentric_l, setquad_concentric_l] = useState(0);

  // Check if switches are enabled or not
  const [isEnabledquad_eccentric_r, setIsEnabledquad_eccentric_r] = useState(false);
  const [isEnabledquad_eccentric_l, setIsEnabledquad_eccentric_l] = useState(false);
  const [isEnabledquad_concentric_r, setIsEnabledquad_concentric_r] = useState(false);
  const [isEnabledquad_concentric_l, setIsEnabledquad_concentric_l] = useState(false);

  // Functions to change the switch
  const toggleSwitchquad_eccentric_r = () => setIsEnabledquad_eccentric_r((previousState) => !previousState);
  const toggleSwitchquad_eccentric_l = () => setIsEnabledquad_eccentric_l((previousState) => !previousState);
  const toggleSwitchquad_concentric_r = () => setIsEnabledquad_concentric_r((previousState) => !previousState);
  const toggleSwitchquad_concentric_l = () => setIsEnabledquad_concentric_l((previousState) => !previousState);

  // Variables amd setters to manage the value of the switch
  const [quad_eccentric_r_quad, setquad_eccentric_r_quad] = useState(0);
  const [quad_eccentric_l_quad, setquad_eccentric_l_quad] = useState(0);
  const [quad_concentric_r_quad, setquad_concentric_r_quad] = useState(0);
  const [quad_concentric_l_quad, setquad_concentric_l_quad] = useState(0);

  // Color for the Slider (MUI)
  const theme = createTheme({
    palette: {
      primary: {
        main: '#989170', 
      },
    },
  });

   // Array object where the results will be saved (testing)
  const resultsHipFlexion = {
    quad_eccentric_r: quad_eccentric_r,
    quad_eccentric_l: quad_eccentric_l,
    quad_concentric_r: quad_concentric_r,
    quad_concentric_l: quad_concentric_l,
    quad_eccentric_r_quad: quad_eccentric_r_quad,
    quad_eccentric_l_quad: quad_eccentric_l_quad,
    quad_concentric_r_quad: quad_concentric_r_quad,
    quad_concentric_l_quad: quad_concentric_l_quad
  }

  // Receive event and take the value of the variable
  const handlequad_eccentric_rChange = (event, value) => {
    setquad_eccentric_r(value);
  };

  const handlequad_eccentric_lChange = (event, value) => {
    setquad_eccentric_l(value);
  };
  
  const handlequad_concentric_rChange = (event, value) => {
    setquad_concentric_r(value)
  };

  const handlequad_concentric_lChange = (event, value) => {
    setquad_concentric_l(value)
  };

  // Used to save the array object and pass it to another screen(testing)
  const {dataAthleteProfile, dataHipFlexionNervousSystemForm,setDataHipFlexionNervousSystemForm, dataFeetNervousSystemForm,
    dataAnkleNervousSystemForm, dataHipExtensionNervousSystemForm, dataHipNervousSystemForm, setDataNervousSystemForms} = useData()
  const [data, setData] = useState('');

  const finalResults = {
    athlete_id: athlete.id,
    trainer_id: currentUser[0].id,
    site_id: 0,
    timestamp: "2023-11-29T22:29:55.807Z",
    "feet_big_toe_r": dataFeetNervousSystemForm.feet_big_toe_r,
    "feet_big_toe_r_quad": dataFeetNervousSystemForm.feet_big_toe_r_quad,
    "feet_big_toe_l": dataFeetNervousSystemForm.feet_big_toe_l,
    "feet_big_toe_l_quad": dataFeetNervousSystemForm.feet_big_toe_l_quad,
    "feet_4toes_r": dataFeetNervousSystemForm.feet_4toes_r,
    "feet_4toes_r_quad": dataFeetNervousSystemForm.feet_4toes_r_quad,
    "feet_4toes_l": dataFeetNervousSystemForm.feet_4toes_l,
    "feet_4toes_l_quad": dataFeetNervousSystemForm.feet_4toes_l_quad,
    "ankle_inversion_r": dataAnkleNervousSystemForm.ankle_inversion_r,
    "ankle_inversion_r_quad": dataAnkleNervousSystemForm.ankle_inversion_r_quad,
    "ankle_inversion_l": dataAnkleNervousSystemForm.ankle_inversion_l,
    "ankle_inversion_l_quad": dataAnkleNervousSystemForm.ankle_inversion_l_quad,
    "ankle_eversion_r": dataAnkleNervousSystemForm.ankle_eversion_r,
    "ankle_eversion_r_quad": dataAnkleNervousSystemForm.ankle_eversion_r_quad,
    "ankle_eversion_l": dataAnkleNervousSystemForm.ankle_eversion_l,
    "ankle_eversion_l_quad": dataAnkleNervousSystemForm.ankle_eversion_l_quad,
    "ankle_dorsiflex_r": dataAnkleNervousSystemForm.ankle_dorsiflex_r,
    "ankle_dorsiflex_r_quad": dataAnkleNervousSystemForm.ankle_dorsiflex_r_quad,
    "ankle_dorsiflex_l": dataAnkleNervousSystemForm.ankle_dorsiflex_l,
    "ankle_dorsiflex_l_quad": dataAnkleNervousSystemForm.ankle_dorsiflex_l_quad,
    "ankle_plantarflex_r": dataAnkleNervousSystemForm.ankle_plantarflex_r,
    "ankle_plantarflex_r_quad": dataAnkleNervousSystemForm.ankle_plantarflex_r_quad,
    "ankle_plantarflex_l": dataAnkleNervousSystemForm.ankle_plantarflex_l,
    "ankle_plantarflex_l_quad": dataAnkleNervousSystemForm.ankle_plantarflex_l_quad,
    "hip_eccentric_r": dataHipExtensionNervousSystemForm.hip_eccentric_r,
    "hip_eccentric_r_quad": dataHipExtensionNervousSystemForm.hip_eccentric_r_quad,
    "hip_eccentric_l": dataHipExtensionNervousSystemForm.hip_eccentric_l,
    "hip_eccentric_l_quad": dataHipExtensionNervousSystemForm.hip_eccentric_l_quad,
    "hip_concentric_r": dataHipExtensionNervousSystemForm.hip_concentric_r,
    "hip_concentric_r_quad": dataHipExtensionNervousSystemForm.hip_concentric_r_quad,
    "hip_concentric_l": dataHipExtensionNervousSystemForm.hip_concentric_l,
    "hip_concentric_l_quad": dataHipExtensionNervousSystemForm.hip_concentric_l_quad,
    "hip_abeccentric_r": dataHipNervousSystemForm.hip_abeccentric_r,
    "hip_abeccentric_r_quad": dataHipNervousSystemForm.hip_abeccentric_r_quad,
    "hip_abeccentric_l": dataHipNervousSystemForm.hip_abeccentric_l,
    "hip_abeccentric_l_quad": dataHipNervousSystemForm.hip_abeccentric_l_quad,
    "hip_abconcentric_r": dataHipNervousSystemForm.hip_abconcentric_r,
    "hip_abconcentric_r_quad": dataHipNervousSystemForm.hip_abconcentric_r_quad,
    "hip_abconcentric_l": dataHipNervousSystemForm.hip_abconcentric_l,
    "hip_abconcentric_l_quad": dataHipNervousSystemForm.hip_abconcentric_l_quad,
    "hip_adeccentric_r": dataHipNervousSystemForm.hip_adeccentric_r,
    "hip_adeccentric_r_quad": dataHipNervousSystemForm.hip_adeccentric_r_quad,
    "hip_adeccentric_l": dataHipNervousSystemForm.hip_adeccentric_l,
    "hip_adeccentric_l_quad": dataHipNervousSystemForm.hip_adeccentric_l_quad,
    "hip_adconcentric_r": dataHipNervousSystemForm.hip_adconcentric_r,
    "hip_adconcentric_r_quad": dataHipNervousSystemForm.hip_adconcentric_r_quad,
    "hip_adconcentric_l": dataHipNervousSystemForm.hip_adconcentric_l,
    "hip_adconcentric_l_quad": dataHipNervousSystemForm.hip_adconcentric_l_quad,
    "hip_internalrot_r": dataHipNervousSystemForm.hip_internalrot_r,
    "hip_internalrot_r_quad": dataHipNervousSystemForm.hip_internalrot_r_quad,
    "hip_internalrot_l": dataHipNervousSystemForm.hip_internalrot_l,
    "hip_internalrot_l_quad": dataHipNervousSystemForm.hip_internalrot_l_quad,
    "hip_externalrot_r": dataHipNervousSystemForm.hip_externalrot_r,
    "hip_externalrot_r_quad": dataHipNervousSystemForm.hip_externalrot_r_quad,
    "hip_externalrot_l": dataHipNervousSystemForm.hip_externalrot_l,
    "hip_externalrot_l_quad": dataHipNervousSystemForm.hip_externalrot_l_quad,
    "quad_eccentric_r": resultsHipFlexion.quad_eccentric_r,
    "quad_eccentric_r_quad": resultsHipFlexion.quad_eccentric_r_quad,
    "quad_eccentric_l": resultsHipFlexion.quad_eccentric_l,
    "quad_eccentric_l_quad": resultsHipFlexion.quad_eccentric_l_quad,
    "quad_concentric_r": resultsHipFlexion.quad_concentric_r,
    "quad_concentric_r_quad": resultsHipFlexion.quad_concentric_r_quad,
    "quad_concentric_l": resultsHipFlexion.quad_concentric_l,
    "quad_concentric_l_quad": resultsHipFlexion.quad_concentric_l_quad   
}


  /**
   * The `submitData` function is an asynchronous function that makes a POST request to a specified API
   * endpoint with the provided data and handles the response and errors accordingly.
   * @param allResults - The `allResults` parameter is an object that contains the data to be submitted
   * to the API. It is passed as the request body in the POST request to the API endpoint. The
   * structure and content of the `allResults` object will depend on the requirements of the API and
   * the data you want
   */
  const submitData = async (allResults) => {
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/{athlete.id}/evals/nervsys';
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        // Add any other headers if needed
        'api-key':'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };
  
      // Make the POST request using Axios
      const response = await axios.post(apiUrl, allResults, { headers });
  
      // Handle the response as needed
      console.log("Response code:", response.status);
      console.log('Response:', response.data);
      setDataNervousSystemForms(response.data);
    } catch (error) {
      // Handle errors
      
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.status !== 404){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        setRequestError(true)
        // The request was made but no response was received
        console.error('No response received. Request:', error.request);
      } else {
        setRequestError(true)
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /**
   * The function `getDataFromDb` makes a series of GET requests to retrieve data from a database,
   * paginating through the results and handling any errors that occur.
   * @param athlete_id - The `athlete_id` parameter is used to specify the ID of the athlete for whom
   * you want to retrieve data from the database. This ID is used in the API request to fetch the
   * athlete's evaluation data.
   */
  const getDataFromDb = async (athlete_id) => {
    let currentPage = 0;
    let hasNextPage = true;
    let lastRecord = 0;
    try {
      // Adjust the URL to use the correct protocol and domain
      const apiUrl = 'https://gamereadyperformanceapp.com/api/athletes/evals/nervsys';
      const parameters = `?athlete_id=${athlete_id}`;
  
      // const firstPageUrl = apiUrl + parameters;
  
      // You may need to adjust headers based on your API requirements
      const headers = {
        'Content-Type': 'application/json',
        'api-key': 'e360e80a-cae6-42ac-96a9-d247d2c92d5b',
      };

      // Continue making requests as long as there is a next page
      while (hasNextPage) {
        // Make the GET request using Axios
        const response = await axios.get(`${apiUrl}?athlete_id=${athlete_id}?page=${currentPage}&limit=10`,{headers} );

        // Log the raw response for debugging
        // console.log("Raw Response:", response);

        // Determine content type manually
        const contentType = response.headers['content-type'];
        // console.log("Content-Type:", contentType);

        // Parse data based on content type
        let responseData;
        if (contentType && contentType.includes('application/json')) {
            responseData = response.data;
            // console.log("Response data in JSON:", responseData);
        } else {
            // Handle other content types if needed
            // For now, assume it's JSON
            responseData = JSON.parse(response.data);
            // console.log("Response data in JSON:", responseData);
        }

        const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
        console.log(firstPageResponse.headers);


        // Access hasNextPage from the response
        hasNextPage = response.data.hasNextPage;


        // Handle the response as needed
        console.log(`Response for page ${currentPage}:`, response.data);

        const nervSysEvals = response.data.nervSysEvals;
        lastRecord = nervSysEvals[nervSysEvals.length - 1].id;
        console.log(`${lastRecord}: ${typeof lastRecord}`);

        // Increment the page for the next request
        currentPage++;

        // Introduce some delay if needed to avoid overwhelming the API
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Make the initial GET request to get the total count and last page
      const firstPageResponse = await axios.get(apiUrl, { headers });
      
      
      console.log(firstPageResponse.headers);

  
      // Extract the total count and last page from the response headers
      // const totalCount = parseInt(firstPageResponse.headers['x-total-count'], 10);
      // console.log('Total Count:', totalCount);
      // const lastPage = Math.ceil(totalCount / 10); // Assuming a limit of 10 records per page
      // console.log('Last Page:', lastPage);
  
      // Now fetch the last page

      const lastPageUrl = `${apiUrl}?eval_id=${lastRecord}${parameters}&limit=10`;

      console.log("Page:", currentPage);
      console.log('GET Request:', lastPageUrl);
      // console.log(lastPageResponse.data);
      console.log("Last Record ID:", lastRecord);
      const lastPageResponse = await axios.get(lastPageUrl, { headers });

      const contentType = lastPageResponse.headers.get('Content-Type');

      // Check if it's JSON
      if (contentType && contentType.includes('application/json')) {
        // The response is in JSON format
        const jsonData = lastPageResponse.data;
        console.log('JSON Data:', jsonData);
      } else {
        // The response is not in JSON format
        console.log('Response is not in JSON format:', contentType);
      }
  
      // Handle the response as needed
      console.log("Last Response: ", lastPageResponse);
      console.log("Last Page Response code:", lastPageResponse.status);
      console.log('Last Page Response:', lastPageResponse.data);
      // console.log("Last Page Post R: ", lastPageResponse.data.post_r)

  // Assuming you have a structure in lastPageData for the "Previous Session" values
      const nervSysEvals = lastPageResponse.data?.nervSysEvals;
      console.log("nervSysEvals:", nervSysEvals)

      if (nervSysEvals && nervSysEvals.length > 0) {
        const previousSessionValues = {
          quad_eccentric_r: nervSysEvals[0].quad_eccentric_r,
          quad_eccentric_l: nervSysEvals[0].quad_eccentric_l,
          quad_concentric_r: nervSysEvals[0].quad_concentric_r,
          quad_concentric_l: nervSysEvals[0].quad_concentric_l,
          quad_eccentric_r_quad: nervSysEvals[0].quad_eccentric_r_quad,
          quad_eccentric_l_quad: nervSysEvals[0].quad_eccentric_l_quad,
          quad_concentric_r_quad: nervSysEvals[0].quad_concentric_r_quad,
          quad_concentric_l_quad: nervSysEvals[0].quad_concentric_l_quad,
        };
        setDataHipFlexionNervousSystemForm(previousSessionValues);
        // console.log("Data Lower Ankle:", data);
        console.log("Previous session:",previousSessionValues);
      }
      
    } catch (error) {
      // Handle errors
      // setRequestError(true)
      console.error('Error submitting data:', error);
  
      // You can also check specific error properties, e.g., error.response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.request.status !== 404){
          setRequestError(true)
        }
        console.error('Server responded with:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        setRequestError(true)
        console.error('No response received. Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setRequestError(true)
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  /* The above code is a useEffect hook in JavaScript. It is used to fetch data from a database
  (presumably using the `getDataFromDb` function) when a component mounts. */
  useEffect(() => {
    // Retrieve data from AsyncStorage when the component mounts
    const fetchData = async () => {
      try {
        // You can get athlete_id, trainer_id, and site_id from wherever you need
        const athlete_id = athlete.id;

        // Call your data fetching function here
        await getDataFromDb(athlete_id);
      } catch (error) {
        setRequestError(true)
        console.error('Error fetching data on component mount:', error);
        // Handle errors if needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  /**
   * The function `saveData` saves data to AsyncStorage in JavaScript.
   */
  const saveData = async () => {
    try {
      // Save data to AsyncStorage
      await AsyncStorage.setItem('hip-flexion-form', JSON.stringify(resultsHipFlexion));
      setData(resultsHipFlexion);
    } catch (error) {
      setRequestError(true)
      console.error('Error saving data:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
        <ScrollView>
            <View style={styles.containerView}>
            {/* Athlete Name and Test Title */}
                <Text style={styles.title}>Athlete Name: {athlete.firstname} {athlete.lastname}</Text>
                <Text style={styles.subTitle}>Nervous System Assessment Form - Strength Test</Text>
                <Button style={styles.button} 
                        onPress={() => navigation.navigate('Table Total Body')}
                        title="Go to : Total Body Assessment Parts"
                        color="#989170">Total Body Assessment Parts</Button>
                {/* Forms table */}
                <DataTable style={styles.table}>
                  <DataTable.Row>
                    <DataTable.Cell>Hip Flexion</DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                            Strength{'\n'}Right Side
                        </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                            Strength{'\n'}Left Side
                        </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                          Quad Dominance {'\n'} Right Side(Y/N)
                        </Text>
                    </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                          Quad Dominance {'\n'} Left Side(Y/N)
                        </Text>
                     </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Eccentric</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        <ThemeProvider theme={theme}>
                          <Slider 
                                aria-label="Strength"
                                defaultValue={0}
                                valueLabelDisplay="on"
                                step={1}
                                min={0}   
                                max={4}
                                onChange={(event, value) => handlequad_eccentric_rChange(event, value)}
                                color='primary'
                            />
                        </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                      <ThemeProvider theme={theme}>
                        <Slider 
                              aria-label="Strength"
                              defaultValue={0}
                              valueLabelDisplay="on"
                              step={1}
                              min={0}   
                              max={4}
                              onChange={(event, value) => handlequad_eccentric_lChange(event, value)}
                              color='primary'
                          />
                      </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledquad_eccentric_r} onValueChange={(isEnabled, quad_eccentric_r_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  quad_eccentric_r_quad = 1;
                                }else{
                                  quad_eccentric_r_quad = 0;
                                }
                                toggleSwitchquad_eccentric_r();
                                setquad_eccentric_r_quad(quad_eccentric_r_quad);
                          }}/>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledquad_eccentric_l} onValueChange={(isEnabled, quad_eccentric_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  quad_eccentric_l_quad = 1;
                                }else{
                                  quad_eccentric_l_quad = 0;
                                }
                                toggleSwitchquad_eccentric_l(); 
                                setquad_eccentric_l_quad(quad_eccentric_l_quad);
                          }} />
                    </DataTable.Cell>
                  </DataTable.Row>

                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Concentric</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        <ThemeProvider theme={theme}>
                          <Slider 
                                aria-label="Strength"
                                defaultValue={0}
                                valueLabelDisplay="on"
                                step={1}
                                min={0}   
                                max={4}
                                onChange={(event, value) => handlequad_concentric_rChange(event, value)}
                                color='primary'
                            />
                        </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                      <ThemeProvider theme={theme}>
                        <Slider 
                              aria-label="Strength"
                              defaultValue={0}
                              valueLabelDisplay="on"
                              step={1}
                              min={0}   
                              max={4}
                              onChange={(event, value) => handlequad_concentric_lChange(event, value)}
                              color='primary'
                          />
                      </ThemeProvider>
                        
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledquad_concentric_r} onValueChange={(isEnabled, quad_concentric_r_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  quad_concentric_r_quad = 1;
                                }else{
                                  quad_concentric_r_quad = 0;
                                }
                                toggleSwitchquad_concentric_r(); 
                                setquad_concentric_r_quad(quad_concentric_r_quad);
                          }}/>
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      <SwitchAssistance isEnabled={isEnabledquad_concentric_l} onValueChange={(isEnabled, quad_concentric_l_quad) => {
                                console.log("Enabled value: ", isEnabled )
                                
                                if(isEnabled){
                                  quad_concentric_l_quad = 1;
                                }else{
                                  quad_concentric_l_quad = 0;
                                }
                                toggleSwitchquad_concentric_l();
                                setquad_concentric_l_quad(quad_concentric_l_quad);
                          }} />
                    </DataTable.Cell>
                  </DataTable.Row>

                </DataTable>

                {/* Report table */}
                <DataTable style={styles.table}>
                  <DataTable.Header>
                    <DataTable.Title>Previous Session</DataTable.Title>
                  </DataTable.Header>
                  <DataTable.Row>
                    <DataTable.Cell>Hip Flexion</DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                            Strength{'\n'}Right Side
                        </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                            Strength{'\n'}Left Side
                        </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                          Quad Dominance {'\n'} Right Side(Y/N)
                        </Text>
                    </DataTable.Cell>
                      <DataTable.Cell style={{justifyContent:'center'}}>
                        <Text>
                          Quad Dominance {'\n'} Left Side(Y/N)
                        </Text>
                     </DataTable.Cell>
                  </DataTable.Row>
                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Eccentric</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataHipFlexionNervousSystemForm.quad_eccentric_r}
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataHipFlexionNervousSystemForm.quad_eccentric_l}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      {dataHipFlexionNervousSystemForm.quad_eccentric_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                      {dataHipFlexionNervousSystemForm.quad_eccentric_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>

                  <DataTable.Row style={{marginTop:30}}>
                    <DataTable.Cell>Concentric</DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataHipFlexionNervousSystemForm.quad_concentric_r}
                    </DataTable.Cell>
                    <DataTable.Cell style={{marginRight:20, justifyContent:'center'}}>
                        {dataHipFlexionNervousSystemForm.quad_concentric_l}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        {dataHipFlexionNervousSystemForm.quad_concentric_r_quad}
                    </DataTable.Cell>
                    <DataTable.Cell style={{justifyContent:'center'}}>
                        {dataHipFlexionNervousSystemForm.quad_concentric_l_quad}
                    </DataTable.Cell>
                  </DataTable.Row>

                </DataTable>

               <View style={styles.rowContainerDownButtons}>
               {/* Buttons to navigate among screens */}
                   <Button
                        title="Previous Page"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Hip', NervousSystemFormHip)}
                        style={styles.button}
                    />
                    <Button
                        title="Continue and save"
                        color="#729982"
                        onPress={() => {
                          
                          for (let key in resultsHipFlexion) {
                            if (resultsHipFlexion.hasOwnProperty(key)) {
                              console.log(`${key}: ${typeof resultsHipFlexion[key]}`);
                            }
                          }
                          const flagForNullValues = Object.values(resultsHipFlexion).every(value => value !== '');
                          console.log(flagForNullValues)

                          if(!flagForNullValues){
                            console.log("There are missing values")
                          }
                          // console.log(resultsHipFlexion)
                            setDataHipFlexionNervousSystemForm(resultsHipFlexion)
                            console.log("Results Hip Flexion:", resultsHipFlexion);
                            console.log("Results Data Hip Flexion:", dataHipFlexionNervousSystemForm)
                            saveData();
                            // fetchDataAsyncStorage();
                            setDataNervousSystemForms(finalResults);
                            submitData(finalResults);
                            navigation.navigate('Nervous System Assessment Form - Functional Movements Part 1', NervousSystemFunctionalMovements);
                          
                        }}
                        style={styles.button}
                    />
                    <Button
                        title="Continue without saving"
                        color="#989170"
                        onPress={() => navigation.navigate('Nervous System Assessment Form - Functional Movements Part 1', NervousSystemFunctionalMovements)}
                        style={styles.button}
                    />
              </View>    
                      {/* Custom modals for web */}
                      {Platform.OS === 'web' && (
                        <>
                          <RequestErrorModal visible={requestError} onClose={() => setRequestError(false)} />
                        </>
                      )}
            </View>
            
        </ScrollView>
    </SafeAreaView>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 16,
    width:'100%',
  },
  containerView:{
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: 16,
    // backgroundColor: 'gray',
    width:'100%',
  },
  button: {
    flex: 0.2,
    alignSelf: 'flex-end',
    marginEnd: 50,
    borderBottomEndRadius:10,
  },
  title: {
    marginVertical: 25,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  subTitle: {
    marginVertical: 25,
    fontWeight: 'regular',
    fontFamily: 'Roboto',
    fontSize:30,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 85,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  rowContainerHeader: {
    flexDirection: 'row',
    justifyContent: 'center', 
    marginVertical: 20,
    marginHorizontal: 70,
    alignItems:'center',
    // backgroundColor:'pink',
  },
  rowContainerDownButtons: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end', 
    marginVertical: 20,
    marginHorizontal: 150,
    alignItems:'center',
    // backgroundColor:'dodgerblue',
  },
  columnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    // backgroundColor:'green'
  },
  textStyleFirstRow: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 30,
    marginVertical: 15,
    fontSize:'large',
  },
  textStyle: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginHorizontal: 25,
    marginVertical: 15,
    fontSize:'large',
  },
});

export default NervousSystemFormHipFlexion;
